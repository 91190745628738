export enum COMMUNICATION_TYPES {
  PUSH= 'push',
  SMS = 'sms',
  EMAIL = 'email',
}

export enum COMMUNICATION_STATUS {
  MESSAGE_STATUS_SENT = 'sent',
  MESSAGE_STATUS_NOT_SENT = 'not_sent',
  MESSAGE_STATUS_ERROR = 'error',
}

export interface IUserCommunication {
  status: COMMUNICATION_STATUS;
  id: number;
  user_id: number;
  shipment_id: number;
  type: COMMUNICATION_TYPES;
  to: string;
  content: string;
  created_at: string;
  updated_at: string;
}

