


















































































































import Vue from 'vue';
import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IUser} from "@/model/model/User/IUser";
import VAutoscan from "@/components/Shipments/Widget/VAutoscan.vue";
import {IErrors} from "@/model/IErrors";
import {ResponseHandler} from "@/helpers/ResponseHandler";
import {LoadingMixin} from '@/plugins/mixins';
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IHub} from "@/model/model/Hub/IHub";
import {HubHelper} from "@/helpers/HubHelper";
import {ITranslations} from "@/translation";

const shipmentModule = namespace("shipmentModule");
const userModule = namespace("userModule");
const hubModule = namespace("hubModule");

const emptyCreateData = {
  to_user_id: null,
  note: '',
  cell_size_id: null,
  bag_code: '',
};

@Component({
  components: {VAutoscan}
})
export default class ShipmentsCreateExternal extends mixins(LoadingMixin) {
  @hubModule.Getter('getUserDefaultHub')
  private getUserDefaultHub!: (user: IUser) => IHub|null;

  @shipmentModule.Getter('shipment')
  private shipment!: IShipmentDetails;
  @shipmentModule.Getter('errors')
  private errors!: IErrors;
  @userModule.Getter('user')
  private user!: IUser;
  @userModule.Getter('errors')
  private userErrors!: IErrors;

  protected loading: boolean = false;
  protected ons!: IOnOffHandlers;
  private scanning: boolean = false;

  private userSearch: string = '';

  private createData: any = {
    to_user_id: null,
    bag_code: '',
    note: '',
    cell_size_id: null,
  };

  get userHubInfo(): string {
    const hub = this.getUserDefaultHub(this.user);
    return HubHelper.getUserDefaultHubInfo(hub);
  }

  get userName() {
    return this.user.id
        ? (this.user.first_name + ' ' + this.user.last_name)
        : '';
  }

  get cellSizeItems() {
    // @ts-ignore
    return Object.keys(this.$t('hubs.cell.size.values'))
    // @ts-ignore
        .map(eachKey => ({value: eachKey, text: this.$t('hubs.cell.size.values.' + eachKey),}));
  }

  get valid() {
    return this.createData.to_user_id &&
        this.createData.cell_size_id &&
        (this.createData.bag_code.length > 0);
  }

  get bagCodeErrors(): string[] {
    const errors = this.errors.bag_code;
    return ResponseHandler.translateErrors(
        errors || [],
        [this.$t('shipments.shipment.bag_code.errors') as ITranslations ]
    );
  }

  get searchErrors(): string[] {
    const errors = this.userErrors;
    return errors
        ? ResponseHandler.translateErrors(
            ResponseHandler.flattenErrors(errors) || [],
            []
        )
        : [];
  }

  created() {
    this.ons = this.$ons({
      'backend.call.OK.shipmentLoaded': this.onCreated,
      'backend.call.OK.fetchUser': this.onUserLoaded,
    }, this.ons);
    this.createData = Object.assign({}, emptyCreateData);
    this.$store.dispatch('userModule/resetUser');
    this.$store.dispatch("hubModule/fetchHubs");
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  onSearchDeliveryCode() {
    this.$store.dispatch('userModule/byDeliveryToken', this.userSearch);
  }

  onSubmit() {
    if (!this.valid) {
      return;
    }
    this.$store.dispatch('shipmentModule/createExternal', this.createData);
  }

  onCreated() {
    Vue.set(this, 'userSearch', '');
    Vue.set(this, 'createData', Object.assign({}, emptyCreateData));
    this.$store.dispatch('userModule/resetUser');
    this.$uiBus.emit(
        'success',
        this.$t('shipments.create.external.created'),
        null,
        {name: 'shipmentDetails', params: {shipmentId: this.shipment.id}}
    );
    // we don't need it and shouldn't keep for separation
    this.$store.dispatch('shipmentModule/resetShipment');
  }

  onShowAutoscan() {
    if (!this.scanning) {
      Vue.set(this.createData, 'bag_code', '');
    }
    this.scanning = !this.scanning;
  }

  // this should go into the component mentioned at "private autoscan ..."
  onBarcode(barcode: any) {
      this.createData.bag_code = barcode;
  }

  onUserLoaded() {
    this.createData.to_user_id = this.user.id;
  }

  onBackToSearch() {
    this.createData.to_user_id = null;
    this.$store.dispatch('userModule/resetUser');
  }

}
