import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {EShipmentStatus} from "@/model/model/Shipment/EShipment";

export class ValidForDeliver {

  public static isSatisfiedBy(shipment: IShipmentDetails) {

    return shipment.to_cell && shipment.to_cell.id &&
      shipment.status == EShipmentStatus.STATUS_PROCESSING;

  }

}
