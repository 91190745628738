





























































import Component, {mixins} from "vue-class-component";
import {generateCDUrl} from "@/helpers/DomainHelpers";
import VueHtml2pdf from 'vue-html2pdf'
import {Prop} from "vue-property-decorator";
import {IHub} from "@/model/model/Hub/IHub";
import {LoadingMixin} from "@/plugins/mixins";

@Component({
  name: 'CourierDirectQrCard',
  components: {
    VueHtml2pdf,
  }
})
export default class CourierDirectQrCard extends mixins(LoadingMixin) {
  @Prop()
  private hub!: IHub;

  private get cdUrl(): string {
    return this.hub.cd_url;
  }

  private get existsHubPublicId() {
    return this.hub && this.hub.public_id && this.hub.public_id !== '';
  }


  private onProgress() {

  }

  private hasStartedGeneration() {

  }

  private hasGenerated() {

  }

  private generateCDPdf () {
    // @ts-ignore
    this.$refs.html2Pdf.generatePdf()
  }
}
