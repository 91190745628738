



























import Component, {mixins} from "vue-class-component";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import { icons } from '@/plugins/icons.ts';
import {LoadingMixin} from "@/plugins/mixins";
import VueBarcode from 'vue-barcode';
import {namespace} from "vuex-class";

const shipmentModule = namespace("shipmentModule");

@Component({
  components: {VueBarcode}
})
export default class BarcodePrinter extends mixins(LoadingMixin) {

  @shipmentModule.Getter('barcodes')
  private barCodes?: string[];

  private readonly BARCODE_FORMAT = 'EAN13';
  private readonly BARCODE_LENGTH = 13;
  // private readonly BARCODE_FORMAT = 'EAN8';
  // private readonly BARCODE_LENGTH = 8;

  protected ons!: IOnOffHandlers;
  private icons = icons;

  private barCodeCnt = 10;

  created() {
    this.ons = this.$ons({

    }, this.ons);
    this.generateBarcodes();
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  private generateBarcodes() {

    this.$store.dispatch('shipmentModule/fetchBarcodes', this.barCodeCnt);

  }

  onBack() {
    this.$router.push({ name: "barcodeIndex" });
  }
}
