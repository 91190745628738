const messages = {
    common: {
        address: {
            city: 'Ciudad',
            province: 'Provincia',
            postalCode: 'Código Postal',
            streetAddress: 'Dirección',
            streetAddress2: 'Dirección continuó ',
        },
        menu: {
            login: 'iniciar sesión',
            logout: 'Cerrar sesion',
        },
        cta: {
            back: 'atrás',
            cancel: 'cancel',
            close: 'Cancelar',
            create: 'crear',
            details: 'Detalles',
            download: 'descargar',
            ok: 'OK',
            showAll: {
                label: 'todo',
                hintOn: 'Rojo: mostrar todo, incluida la prueba',
                hintOff: 'Gris: omitir los de prueba',
            },
            showMore: 'mostrar más',
        },
        history: 'Historia:',
        id: 'id',
        loading: 'cargando...',
        noLuck: 'sin suerte',
        ok: 'OK',
        recipient: 'Destinatario',
    },
    userCommunications: {
        message: 'Mensaje #',
        emailText: 'haga clic aquí para ver el correo electrónico',
        type: 'Tipo:',
        to: 'A:',
        sendAt: 'enviar a:',
        sendTo: 'Enviar a:',
        content: 'Contenido:',
    },
    hubs: {
        hub: {
            label: 'Centro',
            labelFromHub: 'centro de origen',
            labelToHub: 'centro de destino',
            id: {
                label: 'Id',
                hint: 'Auto generado',
            },
            public_id: {
                label: 'identificación pública',
                hint: 'Auto generado',
            },
            name: {
                label: 'Nombre',
            },
            description: {
                label: 'Descripción',
            },
            address: {
                label: 'DIRECCIÓN',
            },
            geo_lat: {
                label: 'Lat.',
            },
            geo_long: {
                label: 'Long.',
            },
            status: {
                label: 'Estado',
                values: {
                    10: 'instalar',
                    20: 'en línea',
                    30: 'desconectado',
                }
            },
        },
        qr: {
            title: 'Mensajero directo',
            cdLink: 'Enlace directo de mensajería',
            no_public_id: 'No hay identificación pública en este centro'
        },
        cell: {
            title: 'Células',
            connector: 'conector',
            shipment: 'Envío',
            disable: 'Desactivar celda',
            enable: 'Habilitar celda',
            cellOpenOK: 'La celda ha sido abierta.',
            cellDisabledOK: 'La celda ha sido deshabilitada',
            cellOpenKO: 'Error al abrir la celda',
            createdColumnOK: '¡Columna creada con éxito!',
            createdColumnKO: 'Hubo un error al crear la columna.',
            changedConnectorOK: 'Conector cambiado con éxito',
            changedConnectorKO: 'Hubo un error al cambiar el conector.',
            size: {
                label: 'Celúla',
                values: {
                    100: 'Pequeño',
                    200: 'Medio',
                    300: 'Grande',
                }
            },
            dialogCreateCellColumn: {
                title: 'Agregar otra columna',
                text: `¿Desea crear una nueva columna de 6 celdas para este centro? Esto no se puede deshacer.`,
                error: `Hubo un error al intentar crear la columna.`,
            },
            dialogOpenCell: {
                title: 'celda abierta',
                text: '¿Quieres abrir esta celda?',
            },
            dialogDisableCell: {
                title: 'Desactivar celda',
                text: "¿Quieres deshabilitar esta celda? Después de eso, los usuarios no podrán abrirlo.",
            },
            dialogEnableCell: {
                title: 'Habilitar celda',
                text: "¿Quieres habilitar esta celda? Después de eso, los usuarios podrán reservarlo y abrirlo.",
            },
            dialogChangeConnector: {
                title: 'Cambiar conector',
                textExistShipment: "No se pueden cambiar celdas que ya tienen envíos.",
                textEditConnector: 'Nuevo conector',
                errorExistConnector: 'Hay otra celda con este conector.',
                errorInvalidValue: 'valor no válido para un conector',
            },
        },
        common: {
            create: 'Nuevo centro:',
            where: 'Dónde',
        },
    },
    shipments: {
        autoscan: {
            inputHint: 'solo tipo i2of5 o ean',
            submit: 'Prueba el código',
        },
        create: {
            external: {
                title: 'Crear externo',
                searchField: {
                    label: 'Buscar por código de envío',
                    placeholder: 'Escribe aquí y presiona btn',
                    description: 'Escriba el código de entrega y presione el botón para buscar un usuario',
                },
                created: 'Se ha creado el pedido',
            },
        },
        dialog: {
            title: 'Verificación',
            text: '¿Se abrió el casillero?',
            textRoute: 'Este centro de destino de envío está en la ruta actual. ¿Quieres añadirlo a la ruta?',
            option: 'Acción:',
            addToTheRoute: 'Añadirlo a la ruta',
            sendToWarehouse: 'Enviar a almacén',
            ok: 'Finalizar',
            ko: 'Abrir de nuevo',
            waiting: 'cargando, espera..'
        },
        finalizeDialog: {
            title: 'Finalizar envío',
            text: '¿Realmente quieres finalizar este envío?',
            warning: 'ALERTA, esto no se puede deshacer',
        },
        general: {
            title: 'Envío',
            finalize: 'Finalizar',
            idle: {
                label: 'Inactivo',
                values: {
                    0: 'ahora mismo',
                    1: 'dentro de una hora',
                    2: 'en un día',
                    3: 'dentro de una semana',
                    4: 'En una semana',
                }
            },
            noCell: 'No asignado',
            errors: {
                shipment: {
                    notInCell: 'el envio no esta en el celular',
                }
            }
        },
        labelEditor: {
            title: {
                viewShipment: 'Envío #',
                createShipment: 'Nuevo envío',
                editShipment: 'Actualizar envío #',
            },
            fields: {
                secondaryCode: {
                    label: 'Código secundario',
                },
                deliveryTypes: {
                    electricDelivery: {
                        label: 'Entrega eléctrica',
                    },
                },
                numberOfPackages: {
                    label: 'Paquetes',
                },
                weight: {
                    label: 'Kg',
                },
                created_at: {
                    label: 'Fecha',
                },
                reference: {
                    label: 'Referencia',
                },
                bag_code: {
                    label: 'déjelo en blanco para generar, escriba aquí o escanee con el icono >',
                },
            },
        },
        shipment: {
            id: {
                label: 'Id',
                hint: 'Auto generado',
            },
            type: {
                label: 'Tipo',
                values: {
                    100: 'cruce de la ciudad',
                    200: 'Externo',
                    300: 'Mensajero directo',
                    400: 'Devoluciones de Amazon',
                    500: 'Entrega Pública',
                    600: 'Logística de OI',
                },
            },
            status: {
                label: 'Estado',
                values: {
                    0: 'Discarded',
                    10: 'Nuevo',
                    20: 'en devolución',
                    30: 'Coleccionismo',
                    50: 'Procesando',
                    70: 'Entregando',
                    80: 'En recogida',
                    90: 'Terminado',
                    DELETED: 'Eliminado',
                },
            },
            from_user: {
                label: 'De',
            },
            to_user: {
                label: 'A',
            },
            from_hub: {
                label: 'Origen',
            },
            to_hub: {
                label: 'Destino',
            },
            note: {
                label: 'Nota',
            },
            bag_code: {
                label: 'Código de barras',
                errors: {
                    unique: 'Este código de barras está en uso',
                },
            },
            created_at: {
                label: 'Creado',
            },
            deleted_at: {
                label: 'Eliminado',
            },
            updated_at: {
                label: 'Actualizado',
            },
        },
        shipments: {
            title: 'Envíos',
        },
        manifest: {
            title: 'Cierre del dia',
            fields: {
                id: {
                    label: 'Id',
                    hint: 'Auto generated',
                },
                created_at: {
                    label: 'Creado',
                },
                total_shipments: {
                    label: 'Total envíos',
                },
                total_in_deliver: {
                    label: 'Total en envío',
                },
                total_completed: {
                    label: 'Total entregados',
                },
                deleted_at: {
                    label: 'Borrado',
                },
                updated_at: {
                    label: 'Actualizado',
                },
            },
            past: {
                title: 'Cierre #',
            },
            closeManifestDialog: {
                title: 'Cierre del dia',
                text: '¿Realmente quieres realizar el cierre del día?',
                warning: 'ALERTA, esto no se puede deshacer',
            },
        },
        manifests: {
            title: 'Cierres pasados',
        },
        shipmentsImport: {
            title: 'Importación de envíos',
            exampleLink: 'Descarga el CSV de ejemplo',
            upload: 'Empezar',
            resultTitle: 'Resultados',
            resultImported: 'Resultado',
            csv: {
                title: 'Archivo CSV',
                placeholder: 'Selecciona un archivo para continuar',
            },
            line_number: 'Linea',
            results: {
                cntImported: 'envío(s) OK',
                cntErrors: 'error(es) encontrados',
            },
            resultCode: {
                0: 'Encabezado OK',
                10: 'Error indeterminado',
                11: 'Formato de archivo no reconocido',
                12: 'El archivo no contiene datos',
                13: 'Error en el encabezado - por favor utiliza el CSV de ejmplo como plantilla. El encabezado y las columnas deben ser las mismas',
                100: 'Datos incompletos (columna) en la linea',
                101: 'Procesado OK',
                130: 'Procesado',
                199: 'No procesado',
                201: 'Nota no válida',
                202: 'Código Bag no válida',
                203: 'Núm. Piezas no válido',
                204: 'Peso no válido',
                205: 'Código Secundario no válido',
                206: 'Referencia no válida',
                207: 'Teléfono del destinatario no válido',
                208: 'Email del destinatario no válido',
                209: 'Nombre del destinatario no válido',
                210: 'Apellidos del destinatario no válidos',
                211: 'Dirección del destinatario no válida',
                212: 'Dirección 2 del destinatario no válida',
                213: 'Código postal del destinatario no válido',
                214: 'Ciudad del destinatario no válida',
                215: 'Provincia del destinatario no válida',
            },
        },
    },
    users: {
        user: {
            first_name: 'Nombre de pila',
            last_name: 'Apellido',
            email: 'Correo electrónico',
            role: 'Role',
            delivery_token: 'código de entrega',
            tos_accepted: 'Aceptado TOS',
            advertising_consent: 'Publicidad aceptada',
            nif: 'NIF',
            phone_number: 'Número de teléfono',
            default_hub: 'Centro de destino predeterminado',
            no_default_hub: `este usuario no ha configurado su centro de destino predeterminado`,
            one_eur_earnings: 'Ganancias',
            created_at: 'Registrado',
            updated_at: 'Actualizado en',
            sms_verified: 'SMS verificado',
            sms_not_verified: "Este usuario no ha sido verificado",
            webhook_url: "Webhook URL",
        },
        search: {
            title: 'Buscar usuarios',
            hint: 'nombre, correo electrónico, teléfono o código de entrega',
            tooShort: 'sigue escribiendo...',
        },
        roles: {
            "0": 'Sin acceso',
            "10": 'Cliente',
            "20": 'Administrador',
            "30": 'API P1',
            "40": 'Partner',
            "50": 'Interno',
        },
        common: {
            communications: 'Comunicaciones',
            shipments: 'Envíos de usuarios',
            fullName: 'Nombre',
        },
        label: 'Usuarios',
    },
    courier: {
        addToRoute: {
            cta: 'Empieza a prepararte',
            title: 'Agregar envíos a la ruta',
        },
        routeHubs: {
            title: 'En ruta',
            allDone: 'Ruta completa!',
        },
        atHub: {
            cta: 'siguiente centro',
            addedToRoute: 'Agregado a la ruta!',
            collect: {
                title: 'para recoger',
                noMore: 'no más envíos aquí para recoger',
                success: 'Abre la celda, pega el código de barras y cierra',
            },
            delivery: {
                title: 'para entregar',
                success: 'Celda abierta, ponlo en',
            },
            nextHub: 'Ir al siguiente centro',
            allDone: 'Todo hecho aquí',
            errors: {
                unique: 'Este código ya se usa',
                cellWontOpen: 'La celda no se abre, inténtalo de nuevo',
                notInCell: 'No hay celda reservada para este envío',
                status: 'Este envío no es apto para entrega/recogida en este momento',
            },
        },
        label: 'Rutas',
    },
    barcode: {
        label: 'Administrador de código de barras',
        howMany: '',
    },
    auth: {
        login: {
            title: 'Acceso',
            email: 'Correo electrónico',
            password: 'Contraseña',
            cta: 'Acceso',
        },
    },

    errors: {
        general: {
            404: 'Extraviado :(',
            401: 'Tú no tienes poder aquí',
            wtf: 'WTF', // use in really wtf cases
            unknown: 'Algo salió mal.', // use this normally
            email: 'No es un correo electrónico',
            password: '???',
            required: 'Obligatorio',
            unique: 'No único',
            // @todo we should apply dynamic translation for these and not put one by one
            'string|16': 'Máximo 16 dígitos',
            'connector_used': 'Este conector ya se usa en este concentrador',
            'cell_has_shipment': 'El conector no se puede cambiar si hay un envío en la celda',
            'numeric|90': 'Máximo 90 dígitos',
            'numeric|180': 'Máximo 180 dígitos'
        }
    },

};

export default messages;
