import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {ICell} from "@/model/model/Hub/ICell";
import {IHub} from "@/model/model/Hub/IHub";
import {EShipmentStatus, EShipmentType} from "@/model/model/Shipment/EShipment";

export class ShipmentHelper {

  static hubIds(shipments: IShipmentDetails[]): number[] {
    let ids: number[] = [];
    shipments.forEach(eachShipment => {
      const cell = this.shipmentsCell(eachShipment);
      if (cell && ids.indexOf(cell.hub_id) === -1) {
        ids.push(cell.hub_id);
      }
    });
    return ids;
  }

  static shipmentsToCollect(shipments: IShipmentDetails[]): IShipmentDetails[] {
    return shipments
      .filter((eachShipment) => eachShipment.status == EShipmentStatus.STATUS_AWAITING_COLLECT);
  }

  static shipmentsToDeliver(shipments: IShipmentDetails[]): IShipmentDetails[] {
    return shipments
      .filter((eachShipment) => eachShipment.status == EShipmentStatus.STATUS_IN_DELIVER);
  }

  static shipmentsByHub(shipments: IShipmentDetails[], hubId: number): IShipmentDetails[] {
    return shipments.filter(eachShipment => {
      const cell = this.shipmentsCell(eachShipment);
      return cell && cell.hub_id == hubId;
    });
  }

  /**
   * @param items
   * @param hubById - hubModule.getters.hubById() should be sent from usage place
   * I'm sending this as a param to remain decoupled from the store here
   */
  static shipmentsSorter(items: IShipmentDetails[], hubById: (id: number) => IHub): IShipmentDetails[] {
    return items.sort((a: IShipmentDetails, b: IShipmentDetails) => {
      const cellA = this.shipmentsCell(a);
      const cellB = this.shipmentsCell(b);
      // if no cell, return the other. no further sorting if neither has cell
      if (!cellA) {
        return 1;
      }
      if (!cellB) {
        return -1;
      }
      // sort by hub name if not same.
      const nameA = ((hubById(cellA.hub_id) || {}).name || '').toUpperCase();
      const nameB = ((hubById(cellB.hub_id) || {}).name || '').toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      else if (nameA > nameB) {
        return 1;
      }
      // If no hub names available, nameA=nameB='' will let code continue below

      // sort by status, higher status first
      if (a.status < b.status) {
        return 1;
      }
      else if (a.status > b.status) {
        return -1;
      }
      // if status is the same also, don't sort further
      return 1;
    });
  }

  static shipmentsCell(a: IShipmentDetails): ICell|any {
    return a.status <= 30 ? a.from_cell : a.to_cell;
  }

  static shipmentIsDeleted(a: IShipmentDetails): boolean {
    return !!a.deleted_at;
  }

  static shipmentListWhitoutDeleted(shipments: IShipmentDetails[]): IShipmentDetails[] {
    return shipments.filter((shipment: IShipmentDetails) => !ShipmentHelper.shipmentIsDeleted(shipment));
  }
}
