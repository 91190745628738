





























































































import Component from "vue-class-component";
import {namespace} from "vuex-class";
import {DateHelper} from "@/helpers/DateHelper";
import {BoListPanel} from "bonomo";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {icons} from "@/plugins/icons";
import {ICell} from "@/model/model/Hub/ICell";
import {IHub} from "@/model/model/Hub/IHub";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {EShipmentType} from "@/model/model/Shipment/EShipment";

const hubModule = namespace("hubModule");
const usersModule = namespace("usersModule");

@Component({
  components: {
    BoListPanel,
  }
})
export default class UserShipments extends Vue {
  @Prop()
  private userId!: number;

  @usersModule.Getter('shipments')
  private shipments!: IShipmentDetails[];

  @usersModule.Action('fetchUserShipments')
  protected fetchUserShipments!: (params: any) => Promise<any>;

  @usersModule.Action('clearUserShipments')
  protected clearUserShipments!: () => Promise<any>;

  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  protected icons = icons;

  get headers(): IDataTableHeader[] {
    return [
      { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
      { text: this.$t('shipments.shipment.type.label') as string, value: "type", },
      { text: this.$t('shipments.shipment.status.label') as string, value: "status", },
      { text: this.$t('hubs.common.where') as string, value: "hub", },
      { text: this.$t('shipments.shipment.from_user.label') as string, value: "from_user_id", },
      { text: this.$t('shipments.shipment.to_user.label') as string, value: "to_user_id", },
      { text: this.$t('shipments.shipment.created_at.label') as string, value: "created_at", },
      { text: "", sortable: false, value: "controls", align: "center"},
    ];
  }

  protected shipmentIsPublicDelivery(shipment: IShipmentDetails): boolean {
    return shipment.type == EShipmentType.PUBLIC_DELIVERY;
  }

  private formatDate: (date: string) => string = DateHelper.formatDate;

  private hubName(cell: ICell) {
    const hub = cell ? this.hubById(cell.hub_id) : null;
    return hub ? hub.name : '??';
  }

  protected fetchShipments(filters: any) {
    return this.fetchUserShipments({userId: this.userId, filters})
  }

}
