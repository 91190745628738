const messages = {
  common: {
    address: {
      city: 'City',
      province: 'Province',
      postalCode: 'Postal code',
      streetAddress: 'Address',
      streetAddress2: 'address continued',
    },
    menu: {
      login: 'login',
      logout: 'logout',
    },
    cta: {
      back: 'back',
      cancel: 'cancel',
      close: 'close',
      create: 'create',
      details: 'Details',
      download: 'download',
      ok: 'OK',
      showAll: {
        label: 'all',
        hintOn: 'Red: show all, including test',
        hintOff: 'Gray: omit test ones',
      },
      showMore: 'show more',
    },
    history: 'History:',
    id: 'id',
    loading: 'loading...',
    noLuck: 'no luck',
    ok: 'OK',
    recipient: 'Recipient',
  },
  userCommunications: {
    message: 'Message #',
    emailText: 'click here to see the email',
    type: 'Type:',
    to: 'To:',
    sendAt: 'Send at:',
    sendTo: 'Send to:',
    content: 'Content:',
  },
  hubs: {
    hub: {
      label: 'Hub',
      labelFromHub: 'origin hub',
      labelToHub: 'destination hub',
      id: {
        label: 'Id',
        hint: 'Auto generated',
      },
      public_id: {
        label: 'public id',
        hint: 'Auto generated',
      },
      name: {
        label: 'Name',
      },
      description: {
        label: 'Description',
      },
      address: {
        label: 'Address',
      },
      geo_lat: {
        label: 'Lat.',
      },
      geo_long: {
        label: 'Long.',
      },
      status: {
        label: 'Status',
        values: {
          10: 'install',
          20: 'online',
          30: 'offline',
        }
      },
    },
    qr: {
      title: 'Courier Direct',
      cdLink: 'Courier Direct link',
      no_public_id: 'There is no public id in this hub'
    },
    cell: {
      title: 'Cells',
      connector: 'Connector',
      shipment: 'Shipment',
      disable: 'Disable Cell',
      enable: 'Enable Cell',
      cellOpenOK: 'The cell has been opened',
      cellDisabledOK: 'The cell has been disabled',
      cellOpenKO: 'Error when opening the cell',
      createdColumnOK: 'Column successfully created!',
      createdColumnKO: 'There was an error creating the column',
      changedConnectorOK: 'Connector successfully changed',
      changedConnectorKO: 'There was an error changing the connector',
      size: {
        label: 'Cell',
        values: {
          100: 'Small',
          200: 'Medium',
          300: 'Large',
        }
      },
      dialogCreateCellColumn: {
        title: 'Add another column',
        text: `Do you want to create a new column of 6 cells to this hub? This can't be undone.`,
        error: `There was an error trying to create the column.`,
      },
      dialogOpenCell: {
        title: 'Open cell',
        text: 'Do you want to open this cell? ',
      },
      dialogDisableCell: {
        title: 'Disable Cell ',
        text: "Do you want to disable this cell? After that users won't be able to open it",
      },
      dialogEnableCell: {
        title: 'Enable Cell ',
        text: "Do you want to enable this cell? After that users will be able to reserve and open it",
      },
      dialogChangeConnector: {
        title: 'Change connector',
        textExistShipment: "You can't change cells who already have shipments.",
        textEditConnector: 'New connector',
        errorExistConnector: 'There is another cell with this connector',
        errorInvalidValue: 'invalid value for a connector',
      },
    },
    common: {
      create: 'New hub:',
      where: 'Where',
    },
  },
  shipments: {
    autoscan: {
      inputHint: 'only i2of5 or ean type',
      submit: 'Try code',
    },
    create: {
      external: {
        title: 'Create external',
        searchField: {
          label: 'Search by delivery code',
          placeholder: 'Type here and press btn',
          description: 'Type delivery code and press button to search for a user',
        },
        created: 'Order has been created',
      },
    },
    dialog: {
      title: 'Verification',
      text: 'Did the locker open?',
      textRoute: 'This shipment target hub is in the current route. Do you want to add it to the route?',
      option: 'Action:',
      addToTheRoute: 'Add it to the route',
      sendToWarehouse: 'Send to warehouse',
      ok: 'Finish',
      ko: 'Open Again',
      waiting: 'loading, wait..'
    },
    finalizeDialog: {
      title: 'Finalize Shipment',
      text: 'Do you really want to finalize this shipment?',
      warning: 'ALERT, this can\'t be undone',
    },
    general: {
      title: 'Shipment',
      finalize: 'Finalize',
      idle: {
        label: 'Idle',
        values: {
          0: 'right now',
          1: 'within an hour',
          2: 'within a day',
          3: 'within a week',
          4: 'over a week',
        }
      },
      noCell: 'Not assigned',
      errors: {
        shipment: {
          notInCell: 'shipment is not in the cell',
        }
      }
    },
    labelEditor: {
      title: {
        viewShipment: 'Shipment #',
        createShipment: 'New shipment',
        editShipment: 'Update shipment #',
      },
      fields: {
        secondaryCode: {
          label: 'Secondary code',
        },
        deliveryTypes: {
          electricDelivery: {
            label: 'Electric delivery',
          },
        },
        numberOfPackages: {
          label: 'Packs',
        },
        weight: {
          label: 'Kg',
        },
        created_at: {
          label: 'Date',
        },
        reference: {
          label: 'Reference',
        },
        bag_code: {
          label: 'leave empty to generate, type here, or scan with the icon >',
          labelNoEdit: 'not editable, already set',
        },
      },
    },
    shipment: {
      id: {
        label: 'Id',
        hint: 'Auto generated',
      },
      type: {
        label: 'Type',
        values: {
          100: 'Crosstown',
          200: 'External',
          300: 'Courier Direct',
          400: 'Amazon Returns',
          500: 'Public Delivery',
          600: 'IO Logistic',
        },
      },
      status: {
        label: 'Status',
        values: {
          0: 'Discarded',
          10: 'New',
          20: 'In Dropoff',
          30: 'Collecting',
          50: 'Processing',
          70: 'Delivering',
          80: 'In Pickup',
          90: 'Completed',
          DELETED: 'Deleted',
        },
      },
      from_user: {
        label: 'From',
      },
      to_user: {
        label: 'To',
      },
      from_hub: {
        label: 'Origin',
      },
      to_hub: {
        label: 'Destination',
      },
      note: {
        label: 'Note',
      },
      bag_code: {
        label: 'Barcode',
        errors: {
          unique: 'This barcode is in use',
        },
      },
      created_at: {
        label: 'Created',
      },
      deleted_at: {
        label: 'Deleted',
      },
      updated_at: {
        label: 'Updated',
      },
    },
    shipments: {
      title: 'Shipments',
    },
    manifest: {
      title: 'Today\'s Manifest',
      fields: {
        id: {
          label: 'Id',
          hint: 'Auto generated',
        },
        created_at: {
          label: 'Created',
        },
        total_shipments: {
          label: 'Total shipments',
        },
        total_in_deliver: {
          label: 'Total in deliver',
        },
        total_completed: {
          label: 'Total delivered',
        },
        deleted_at: {
          label: 'Deleted',
        },
        updated_at: {
          label: 'Updated',
        },
      },
      past: {
        title: 'Manifest #',
      },
      closeManifestDialog: {
        title: 'Close today\'s manifest',
        text: 'Do you really want to close today\'s manifest?',
        warning: 'ALERT, this can\'t be undone',
      },
    },
    manifests: {
      title: 'Past manifests',
    },
    shipmentsImport: {
      title: 'Shipments Import',
      exampleLink: 'Download example CSV',
      upload: 'Let`s do it!',
      resultTitle: 'Results',
      resultImported: 'Result',
      csv: {
        title: 'CSV file',
        placeholder: 'Select file to continue',
      },
      line_number: 'Line',
      results: {
        cntImported: 'shipment(s) OK',
        cntErrors: 'error(s) found',
      },
      resultCode: {
        0: 'Header OK',
        10: 'Unknown error',
        11: 'File format not recognized',
        12: 'No data in the file',
        13: 'Error in header - please use the example file as template. Headers and columns must be the same.',
        100: 'Missing data (column) in this line',
        101: 'Processed OK',
        130: 'Processed',
        199: 'Not processed',
        201: 'Note not valid',
        202: 'Bag code not valid',
        203: 'Pieces not valid',
        204: 'Weight not valid',
        205: 'Secondary Code not valid',
        206: 'Reference not valid',
        207: 'Recipient Phone Number not valid',
        208: 'Recipient Email not valid',
        209: 'Recipient First Name not valid',
        210: 'Recipient Last Name not valid',
        211: 'Recipient Address not valid',
        212: 'Recipient Address 2 not valid',
        213: 'Recipient Postal Code not valid',
        214: 'Recipient City not valid',
        215: 'Recipient Province not valid',
      },
    },    
  },
  users: {
    user: {
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      role: 'Role',
      delivery_token: 'Delivery code',
      tos_accepted: 'Accepted TOS',
      advertising_consent: 'Accepted advertising',
      nif: 'NIF',
      phone_number: 'Phone number',
      default_hub: 'Default destination hub',
      no_default_hub: `this user hasn't set his default destination hub`,
      one_eur_earnings: 'Earnings',
      created_at: 'Registered',
      updated_at: 'Updated at',
      sms_verified: 'Sms verified',
      sms_not_verified: "This user hasn't been verified",
      webhook_url: "Webhook URL",
    },
    search: {
      title: 'Search users',
      hint: 'name, email, phone, or delivery code',
      tooShort: 'keep typing...',
    },
    roles: {
      "0": 'No access',
      "10": 'Client',
      "20": 'Admin',
      "30": 'API P1',
      "40": 'Partner',
      "50": 'Internal',
    },
    common: {
      communications: 'Communications',
      shipments: 'Users shipments',
      fullName: 'Name',
    },
    label: 'Users',
  },
  courier: {
    addToRoute: {
      cta: 'Start preparing',
      title: 'Add Shipments To route',
    },
    routeHubs: {
      title: 'In route',
      allDone: 'Route complete!',
    },
    atHub: {
      cta: 'Next hub',
      addedToRoute: 'Added to route!',
      collect: {
        title: 'to collect',
        noMore: 'no more shipments here to collect',
        success: 'Cell open, stick barcode and close',
      },
      delivery: {
        title: 'to deliver',
        success: 'Cell open, put it in',
      },
      nextHub: 'Go to next hub',
      allDone: 'All done here',
      errors: {
        unique: 'This code is already used',
        cellWontOpen: 'The cell won\'t open, please try again',
        notInCell: 'There is no cell reserved for this shipment',
        status: 'This shipment is not apt for deliver/collect right now',
      },
    },
    label: 'Routes',
  },
  barcode: {
    label: 'Barcode manager',
    howMany: '',
  },
  auth: {
    login: {
      title: 'Login',
      email: 'Email',
      password: 'Password',
      cta: 'Login',
    },
  },

  errors: {
    general: {
      404: 'Not found :(',
      401: 'You have no power here',
      wtf: 'WTF', // use in really wtf cases
      unknown: 'Something went wrong.', // use this normally
      email: 'Not an email',
      password: '???',
      required: 'Mandatory',
      unique: 'Not unique',
      // @todo we should apply dynamic translation for these and not put one by one
      'string|16': 'Maximum 16 digits',
      'connector_used': 'This connector is already used in this hub',
      'cell_has_shipment': 'Connector cant be change if there is a shipment in the cell',
      'numeric|90': 'Maximum 90 digits',
      'numeric|180': 'Maximum 180 digits'
    }
  },

};

export default messages;
