


















































import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Vue from "vue";
import {IErrors} from "@/model/IErrors";
import ErrorMessages from "@/components/ErrorMessages.vue"
import {IOnOffHandlers} from "@/model/IOnOffHandlers";

/**
 *  events:
 *  @cancel
 *  @close
 *  @input, false (for v-model)
 *  @submit
 *
 *  examples:
 *  CellConnectorDialog is a very complete live example
 *  A very simple example of using bo-dialog without wrapping it:
 *
 *      <bo-dialog
 *        v-model="showOpenDialog"
 *        title="hubs.cell.dialogOpenCell.title"
 *        @submit="onSubmit"
 *      >
 *        {{ $t('hubs.cell.dialogOpenCell.text') }} (connector: {{ currentCell.connector }})
 *      </bo-dialog>
 *
 */
@Component({
  name: 'BoDialog',
  components: {
    ErrorMessages,
  }
})
export default class BoDialog extends Vue {

  /**
   * REQUIRED controls showing dialog. Use it with v-model=""
   * with v-model binding the outer variable gets updated automatically
   * bind value and use v-on="$listeners" binding when wrapping bo-dialog,
   * @see ChangeConnectorDialog
   */
  @Prop()
  private value!: boolean;

  /**
   * OPTIONAL set submit (OK) button disabled. Disable eg. when an invalid value
   * is entered in an input of the dialog. Won't show buttons anyway while loading.
   * @see ChangeConnectorDialog
   */
  @Prop({default: false})
  private disabled!: boolean;

  /**
   * OPTIONAL show errors between dialog content and buttons.
   * Typical use: :errors="errors._"
   */
  @Prop()
  private errors!: IErrors;

  /**
   * OPTIONAL show only a confirmation dialog with one "OK" button.
   * Only @close is emitted in this case (ie no @submit)
   * (shall we call this confirmOnly ?)
   */
  @Prop({default: false})
  private notifyOnly!: boolean;

  /**
   * OPTIONAL bind this to clear errors automatically on close
   * @see errors
   */
  @Prop()
  private resetErrors!: () => any;

  /**
   * OPTIONAL change label of "OK" button.
   */
  @Prop({default: 'common.cta.ok'})
  private submitLabel!: string;

  /**
   * OPTIONAL dialog title. Won't show title bar if empty
   */
  @Prop()
  private title!: string;

  /**
   * OPTIONAL will emit "OK" on success by default. Can customize here, or:
   * NOTE send toastOK="" to disable emitting success toast
   */
  @Prop({default: 'common.ok'})
  private toastOK!: string;

  /**
   * NOTE loading is private internal state, not prop. We also don't emit events.
   * If needed, can subscribe to original events directly (ie 'backend.call.begin')
   */
  private loading: boolean = false;
  protected subscribes: IOnOffHandlers = {};

  private created() {
    this.subscribes = this.$ons({
      'backend.call.begin': this.backendBegin,
      'backend.call.OK': this.backendOK,
      'backend.call.KO': this.backendKO,
    });
  }

  private beforeDestroy() {
    this.$offs(this.subscribes);
  }

  private cancel(...args: any) {
    this.$emit('cancel', ...arguments);
    this.close();
  }

  private submit(...args: any) {
    this.$emit('submit', ...arguments);
  }

  private close(...args: any) {
    this.loading = false;
    if (this.resetErrors) {
      this.resetErrors();
    }
    this.$emit('close', ...arguments);
    /**
     * emitting this is what makes v-model working. Has to be proxied in wrapping
     * components, ie use v-on="$listeners"
     * @see ChangeConnectorDialog
     */
    this.$emit('input', false);
  }

  private backendBegin() {
    if (!this.value) {
      return;
    }
    this.loading = true;
  }

  private backendKO() {
    if (!this.value) {
      return;
    }
    this.loading = false;
  }

  private backendOK() {
    if (!this.value) {
      return;
    }
    if (this.toastOK && (this.toastOK !== "")) {
      this.$uiBus.emit('success', this.$t(this.toastOK));
    }
    this.close();
  }

}

