import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import {uiBus} from "./plugins/uiBus";
import {EventEmitter} from "events";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import VueQuagga from 'vue-quaggajs';
import {icons, IIcons} from "@/plugins/icons";
import {i18n, initLanguage} from "@/helpers/translations";
import Notifications from 'vue-notification';
import '@/plugins/responseHandling'
import {isVersion, WEB_VERSIONS} from "@/helpers/ToggleFeatureHelper";

Vue.use(VueQuagga);
Vue.use(Notifications);
Vue.component('qr-code', VueQRCodeComponent)

require("./plugins/axios");
require("./plugins/filters");

Vue.config.productionTip = false;

declare module 'vue/types/vue' {
  interface Vue {
    $uiBus: EventEmitter;
    $ons: (o: IOnOffHandlers, currentOns?: IOnOffHandlers) => IOnOffHandlers;
    $offs: (o: IOnOffHandlers) => void;
    $icons: IIcons;
    $isVersion: (city: WEB_VERSIONS) => boolean;
  }
}
Vue.prototype.$uiBus = uiBus;
Vue.prototype.$ons = function(ons: IOnOffHandlers, current: IOnOffHandlers = {}): IOnOffHandlers {
  Object.keys(ons)
    .forEach(eachEvent => uiBus.on(eachEvent, ons[eachEvent]));
  return Object.assign(current, ons);
};
Vue.prototype.$offs = function(offs: IOnOffHandlers) {
  Object.keys(offs)
    .forEach(eachEvent => uiBus.off(eachEvent, offs[eachEvent]));
};
Vue.prototype.$icons = icons;
Vue.prototype.$isVersion = isVersion

initLanguage();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
