

































import Vue from 'vue';
import {debounce, forEach} from 'lodash';
import Component from "vue-class-component";
import LoginDialog from "@/components/auth/LoginDialog.vue";
import LeftNav from "@/components/LeftNav.vue";
import NotificationTemplate from "@/components/NotificationTemplate.vue";
import {isVersion, WEB_VERSIONS} from "@/helpers/ToggleFeatureHelper";
import {namespace} from "vuex-class";

const packageJson = require('../package.json');
const authStore = namespace("authModule");
const hubStore = namespace('hubModule');

@Component({
  components: {
    LoginDialog,
    LeftNav,
    NotificationTemplate,
  },
})
export default class App extends Vue {
  @authStore.Getter('userIsLogged')
  userIsLogged!: boolean;

  @hubStore.Action('fetchHubs')
  private anyApiCall!: () => void;

  get webVersion(): string {
    let result = 'unknown';
    Object.values(WEB_VERSIONS).every((version) => {
      if (isVersion(version)) {
        result = version;
        return false;
      }
    });

    return result;
  }
  get drawerMiniVariant() {
    return window.innerWidth < 667;
  }

  get packageVersion() {
    return packageJson.version;
  }

  get packageDescription() {
    return packageJson.description;
  }

  created(): void {
    const DEBOUNCE_TIME = 2000;
    this.anyApiCall();

    this.$uiBus.on(
      "alert",
      debounce(
        this.onAlert,
        DEBOUNCE_TIME,
        { leading: true, trailing: false }
      ));
    this.$uiBus.on(
      "success",
      debounce(
          this.onSuccess,
          DEBOUNCE_TIME,
          { leading: true, trailing: false }
      )
    );
  }

  private onAlert(title: string, message: string) {
    this.$notify({
      title,
      text: message,
      type: 'error',
      duration: 5000,
      data: {
        to: null,
      }
    });
  }

  private onSuccess(title: string, message?: string, to?: any) {
    this.$notify({
      title,
      text:  message || '',
      duration: 5000,
      data: {
        to
      }
    });
  }
}
