export enum EShipmentStatus {
  STATUS_DISCARDED = 0,
  STATUS_NEW = 10,
  STATUS_AWAITING_DROPOFF = 20,
  STATUS_AWAITING_COLLECT = 30,
  STATUS_PROCESSING = 50,
  STATUS_IN_DELIVER = 70,
  STATUS_AWAITING_PICKUP = 80,
  STATUS_COMPLETED = 90,
}

export enum EShipmentType {
  CROSSTOWN = 100,
  EXTERNAL = 200,
  COURIER_DIRECT = 300,
  AMAZON_RETURNS = 400,
  PUBLIC_DELIVERY = 500,
  ALMO = 600,
}

export enum EShipmentCollectOptions {
  SEND_TO_WAREHOUSE = 0,
  ADD_TO_ROUTE = 1,
}

