


















































































































































































import Component, {mixins} from "vue-class-component";

import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {ICell} from "@/model/model/Hub/ICell";
import {IHub} from "@/model/model/Hub/IHub";
import {IShipmentSearchFilter} from "@/model/model/Shipment/IShipmentSearchFilter";
import { icons } from '@/plugins/icons.ts';
import {LoadingMixin} from "@/plugins/mixins";
import {ORDER_DIRECTION} from "@/model/model/Database/DatabaseElements";

import { BoListPanel } from "bonomo";
import { ShipmentHelper } from '@/helpers/ShipmentHelper';
import { EShipmentStatus, EShipmentType } from "@/model/model/Shipment/EShipment";
import CloseManifestDialog from "@/views/Shipments/CloseManifestDialog.vue";

const hubModule = namespace("hubModule");
const manifestModule = namespace("manifestModule");
const authModule = namespace("authModule");

@Component({
  components: {
    CloseManifestDialog,
    BoListPanel
  }
})
export default class AlmoManifestShipments extends mixins(LoadingMixin) {
  @manifestModule.Action("fetchAlmoShipments")
  private fetchAlmoShipments!: (filters: IShipmentSearchFilter) => Promise<any>
  @manifestModule.Action("clearAlmoShipments")
  private clearAlmoShipments!: () => Promise<any>
  
  @manifestModule.Getter('almoShipments')
  private almoShipments!: IShipmentDetails[];

  @manifestModule.Getter('almoShipmentsFilters')
  private almoShipmentsFilters!: IShipmentSearchFilter;
  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;
  @authModule.Getter('userData')
  private userData!: any;

  protected ons!: IOnOffHandlers;
  private icons = icons;
  private testShipments = false;
  private showCloseManifestDialog: boolean = false;

  private headers: IDataTableHeader[] = [
    { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
    { text: this.$t('shipments.shipment.type.label') as string, value: "type", },
    { text: this.$t('shipments.shipment.status.label') as string, value: "status", },
    { text: this.$t('hubs.common.where') as string, value: "hub", },
    { text: this.$t('shipments.shipment.from_user.label') as string, value: "from_user_id", },
    { text: this.$t('shipments.shipment.to_user.label') as string, value: "to_user_id", },
    { text: this.$t('shipments.shipment.created_at.label') as string, value: "created_at", },
    { text: "", sortable: false, value: "controls", align: "center"},
  ];

  private almoFilters: IShipmentSearchFilter = {
    hub_id: null,
    status: [EShipmentStatus.STATUS_NEW,],
    type: [EShipmentType.ALMO,],
    user_id: null,
    orderDirection: ORDER_DIRECTION.DESC,
  };

  private shipmentIsDeleted: (shipment: IShipmentDetails) => boolean = ShipmentHelper.shipmentIsDeleted;

  protected shipmentIsNewAlmo(shipment: IShipmentDetails): boolean {
    return shipment.type == EShipmentType.ALMO;
  }

  protected shipmentIsInAwaitingCollectOrBefore(shipment: IShipmentDetails): boolean {
    return shipment.status <= EShipmentStatus.STATUS_AWAITING_COLLECT;
  }

  protected shipmentIsAfterAwaitingCollect(shipment: IShipmentDetails): boolean {
    return shipment.status > EShipmentStatus.STATUS_AWAITING_COLLECT;
  }

  private get getAlmoShipments(): IShipmentDetails[] {
    return this.almoShipments;
  }

  private get shipmentIds(): number[] {
    return this.getAlmoShipments.map((shipment:IShipmentDetails) => shipment.id);
  }

  public created() {
    Object.assign(this.almoFilters, {user_id: this.userData.id});
  }

  protected shipmentUrl(item: IShipmentDetails) {
    return item.type == EShipmentType.ALMO
      ? {name: 'shipmentsLabelEditor', params: { entityId: item.id }}
      : {name: 'shipmentDetails', params: { shipmentId: item.id }};
  }

  private hubName(cell: ICell) {
    const hub = cell ? this.hubById(cell.hub_id) : null;
    return hub ? hub.name : '??';
  }

  private goBack(): void {
    this.$router.push({name: "home"});
  }

  private goToLabelCreator(): void {
    this.$router.push({name: "shipmentsCreateLabel"});
  }

  private goToImport(): void {
    this.$router.push({name: "manifestShipmentsImport"});
  }

  private closeManifest(): void {
    this.showCloseManifestDialog = true;
  }
  private closeDialogs() {
    this.showCloseManifestDialog = false;
  }

}
