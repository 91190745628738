











import Vue from 'vue';
import Component from "vue-class-component";

@Component({
  name: "VAutoscan"
})
export default class VAutoscan extends Vue {

  private autoscan = {
    show: true,
    size: {
      width: 200,
      height: 200,
    },
    types: [
      "ean_reader",
      "ean_8_reader",
      // "code_128_reader",
    ],
    lastRead: '',
    readTimes: 0,
  };

  private onBarcode(barcode: any) {
    if (!this.autoscan.show) {
      return;
    }
    const code = barcode.codeResult.code;
    if (this.autoscan.lastRead === code) {
      this.autoscan.readTimes++;
    } else {
      this.autoscan.readTimes = 0;
      this.autoscan.lastRead = code;
    }
    if (this.autoscan.readTimes > 5) {
      this.autoscan.show = false;
      this.$emit('code', code);
    }
  }

}
