













import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import {namespace} from "vuex-class";

import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IHub} from "@/model/model/Hub/IHub";
import {ShipmentHelper} from "@/helpers/ShipmentHelper";

import DialogVerifyOpen from "@/views/Courier/DialogVerifyOpen.vue";
import {IAddToRoute} from "@/model/specification/Courier/PopUps";

const hubModule = namespace("hubModule");
const courierModule = namespace("courierModule");

@Component({
  name: 'CollectLogic',
  components: {
    DialogVerifyOpen,
  }
})
export default class CollectLogic extends Vue {
  @Prop()
  private hubId!: string; // in the end, because it comes from a parameter in the url, it is a string

  @courierModule.Getter('shipmentsInRoute')
  private shipments!: IShipmentDetails[];

  @hubModule.Getter('hubs')
  private hubsPath!: IHub[]; // show the path of hubs the courier has to go

  protected ons!: IOnOffHandlers;

  // private showAddToRouteDialog = false;
  private showVerifyDialog = false;
  private currentShipment: IShipmentDetails|null = null;
  private currentBagCode: string|null = null;
  private addToRoute: boolean = false;
  private askAddToRoute: boolean = false;

  /**
   * Gets the list of the current shipments located at the current hub
   */
  get shipmentsAtHub(): IShipmentDetails[] {
    const shipments = this.shipments;
    const hubId = this.getHubId;
    return ShipmentHelper.shipmentsByHub(shipments || [], hubId);
  }

  get getHubId(): number {
    return parseInt(this.hubId, 10);
  }

  get remainingHubsInPath(): IHub[] {
    const res: IHub[] = [];
    let found: boolean = false;

    this.hubsPath.map((currHub: IHub) => {
      if (!found){
        found = currHub.id === this.getHubId;
      } else {
        res.push(currHub);
      }
    });

    return res;
  }

  created() {
    this.ons = this.$ons({
      'atHub.courierLogic.collect': this.start,
      'backend.call.begin.collectShipment': this.onApiBegin,
      'backend.call.begin.verifyCollectShipment': this.onApiBegin,
      'backend.call.OK.collectShipment': this.onCollectOK,
      'backend.call.OK.verifyCollectShipment': this.onVerifyCollectOK,
      'backend.call.KO.collectShipment': this.onApiKO,
      'backend.call.KO.verifyCollectShipment': this.onApiKO,
    }, this.ons);
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  private start(bagCode: string) {
    const shipment: IShipmentDetails | undefined = this.getAShipmentToCollect();

    if (!shipment) {
      this.$uiBus.emit('alert', this.$t('translations.courier.atHub.collect.noMore'));
      return;
    }

    this.currentShipment = shipment;
    this.currentBagCode = bagCode;

    this.addToRoute = false;
    this.dispatchCollectShipment();
  }


  /**
   * dispatch the collect, aka "open the cell"
   */
  private dispatchCollectShipment() {
    if (this.currentShipment) {
      this.$store.dispatch('courierModule/collectShipment', {
        shipmentId: this.currentShipment.id,
        bagCode: this.currentBagCode,
      });
    }
  }

  /**
   * after we dispatch the collect, we need to validate the proper cell has been opened
   */
  private onCollectOK() {
    this.openVerifyDialog();
  }

  /**
   * after we dispatch the collect, we need to validate the proper cell has been opened
   */
  private onVerifyCollectOK() {
    if (this.currentShipment) {
      this.$store.dispatch('courierModule/removeShipmentInRoute', this.currentShipment.id);
    }

    this.resetLogicAndClosePopUps();

    this.onAllOK();
  }

  private resetLogicAndClosePopUps() {
    this.closeVerifyDialog();
    this.currentShipment = null;
    this.currentBagCode = null;
    this.addToRoute = false;
  }


  private verifyCollectShipment({ addToRoute }: IAddToRoute) {
    if (this.currentShipment) {
      this.$store.dispatch('courierModule/verifyCollectShipment', {
        shipmentId: this.currentShipment.id,
        bagCode: this.currentBagCode,
        addToRoute,
      });
    }
  }

  get shipmentGoesToHubInTheRoute() {
    const shipment = this.currentShipment;

    if (shipment && shipment.to_cell) {
      return !!this.remainingHubsInPath.find((hub: IHub) => shipment.to_cell && shipment.to_cell.hub_id === hub.id);
    }

    return false;
  }

  /**
   * Gets one shipment that we need to collect in the current hub
   * */
  private getAShipmentToCollect(): IShipmentDetails | undefined {
    return ShipmentHelper
        .shipmentsToCollect(this.shipmentsAtHub || [])
        .shift();
  }

  private openVerifyDialog() {
    this.showVerifyDialog = true;
  }

  private closeVerifyDialog() {
    this.showVerifyDialog = false;
  }

  private onAllOK() {
    this.$emit('api:OK');
  }

  private onApiKO() {
    this.$emit('api:KO');
  }

  private onApiBegin() {
    this.$emit('api:begin');
  }
}


