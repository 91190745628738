





























































import Component, {mixins} from "vue-class-component";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import { icons } from '@/plugins/icons.ts';
import {LoadingMixin} from "@/plugins/mixins";
import VAutoscan from "@/components/Shipments/Widget/VAutoscan.vue";
import {namespace} from "vuex-class";
import ShipmentData from "@/components/Shipments/ShipmentData.vue";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IErrors} from "@/model/IErrors";
import {ResponseHandler} from "@/helpers/ResponseHandler";

const shipmentModule = namespace("shipmentModule");
const hubModule = namespace("hubModule");

@Component({
  components: {VAutoscan, ShipmentData,}
})
export default class BarcodeIndex extends mixins(LoadingMixin) {

  @shipmentModule.Getter('shipment')
  private shipment!: IShipmentDetails;
  @shipmentModule.Getter('errors')
  private rawErrors!: IErrors;

  protected ons!: IOnOffHandlers;
  private icons = icons;
  private bagCodeErrors: string[] = [];

  private bagCode: string = '';
  private showAutoScan: boolean = false;

  private get errors(): string[] {
    return this.rawErrors && this.rawErrors._
      ? ResponseHandler.translateErrors(this.rawErrors._)
      : [];
  }

  created() {
    this.ons = this.$ons({

    }, this.ons);
    this.$store.dispatch("hubModule/getHubs");
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  private onShowAutoscan() {
    if (!this.showAutoScan) {
      this.bagCode = '';
    }
    this.showAutoScan = !this.showAutoScan;
  }

  private onBarcode(barCode: string) {
    this.bagCode = barCode;
    this.showAutoScan = false;
    this.$store.dispatch("shipmentModule/resetShipment");
    this.$store.dispatch("shipmentModule/fetchByBagCode", this.bagCode);
  }

  private goBackToMain(): void {
    this.$router.push({name: "home"});
  }

}
