import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RootState} from '../RootState';
import axios from "axios";
import {settings} from "@/plugins/settings";
import {IShipmentSearchFilter} from "@/model/model/Shipment/IShipmentSearchFilter";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IErrors} from "@/model/IErrors";
import responseHandler from "@/plugins/responseHandling";
import {ORDER_DIRECTION} from "@/model/model/Database/DatabaseElements";
import {IUserCommunication} from "@/model/model/UserCommunications/IUserCommunications";
import Vue from "vue";

const defaultShipmentFiltersState = () => ({
    hub_id: null,
    status: [],
    type: [],
    user_id: null,
  });

interface ShipmentState {
  shipments: IShipmentDetails[];
  shipmentsFilters: IShipmentSearchFilter;
  shipment?: IShipmentDetails;
  communications: IUserCommunication[];
  barcodes: string[];
  errors: IErrors;
}

const state: ShipmentState = {
  shipments: [],
  shipmentsFilters: defaultShipmentFiltersState(),
  shipment: undefined,
  communications: [],
  barcodes: [],
  errors: {},
};

const getters: GetterTree<ShipmentState, RootState> = {
  shipments: (state):  IShipmentDetails[] => state.shipments,
  shipmentsFilters: (state): IShipmentSearchFilter => state.shipmentsFilters,
  shipment: (state): IShipmentDetails => state.shipment || {} as IShipmentDetails,
  errors: (state): IErrors => state.errors,
  communications: (state): IUserCommunication[] => state.communications,
  barcodes: (state): string[] => state.barcodes,
};

const actions: ActionTree<ShipmentState, RootState> = {
  clearShipmentCommunications: ({commit}): any => {
    return commit('clearCommunications');
  },

  clearShipments: ({ commit, state }, filters: IShipmentSearchFilter): any => {
    commit("clearShipments")
  },

  createExternal: ({ commit }, data): any => {
    // @todo @ramon why a resetshipment here?
    commit('resetShipment');
    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/shipments/external',
        method: 'PUT',
        data
      }),
      commit,
      'shipmentLoaded',
    );
  },
  createAlmo: ({commit}, IShipment): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/almo',
      method: 'POST',
      data: IShipment,
    }),
    commit,
    'shipmentLoaded'
  ),

  downloadCsv: ({ commit, state }): any => {
    return responseHandler.handleDownload(
      () => axios({
        url: settings.endpoint + 'bo/shipments/csv',
        method: "GET",
        responseType: 'blob',
      }),
      () => {},
      'shipmentsCsvLoaded',
      'bo-shipments.csv'
    )
  },
  fetchBarcodes: ({commit}, cnt): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/barcodes',
      params: {cnt}
    }),
    commit,
    'barcodesLoaded'
  ),
  fetchByBagCode: ({ commit }, bagCode): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/bag_code/' + bagCode,
    }),
    commit,
    'shipmentLoaded'
  ),
  fetchShipment: ({ commit }, shipmentId): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId
    }),
    commit,
    'shipmentLoaded'
  ),
  fetchShipments: ({ commit, state }, filters: IShipmentSearchFilter): any => {
    // I check if some hubs were already been loaded before by checking length. Theoretically bad, but  works as
    // edge cases don't matter (when we previously loaded an empty set, it doesn't have to be reloaded neither)
    if (state.shipments.length &&
      JSON.stringify(filters) === JSON.stringify(state.shipmentsFilters)
    ) {
      return;
    }
    commit('shipmentsFilters', filters);

    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/shipments/search',
        method: "GET",
        params: {
          ...filters,
          orderDirection: ORDER_DIRECTION.DESC
        },
      }),
      commit,
      'shipmentsLoaded'
    );
  },
  fetchShipmentsCommunications:  ({ commit }, filters): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + filters.shipmentId + '/communications',
      method: "GET",
      params: {
        ...filters,
        orderDirection: ORDER_DIRECTION.DESC
      }
    }),
    commit,
    'communicationsLoaded'
  ),

  finalizeShipment: ({ commit, dispatch, state }, shipmentId: any): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId + '/finalize',
      method: 'POST',
    }),
    commit,
    'shipmentLoaded'
  ),

  resetShipment: ({ commit }): any => commit('resetShipment'),

  updateShipment: ({ commit }, shipment: IShipmentDetails): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipment.id,
      method: 'PATCH',
      data: shipment,
    }),
    commit,
    'shipmentLoaded'
  ),

  clearError: ({commit}, ref): any => {
    commit('clearError', ref);
  },

};

const mutations: MutationTree<ShipmentState> = {
  resetShipment() {
    state.shipment = undefined;
  },
  clearShipments(state) {
    state.shipments = [];
  },
  shipmentLoaded(state, payload: IShipmentDetails) {
    state.shipment = payload;
  },
  shipmentsLoaded(state, payload: IShipmentDetails[]) {
    state.shipments = state.shipments.concat(payload)
  },
  communicationsLoaded(state, payload: IUserCommunication[]) {
    state.communications = state.communications.concat(payload);
  },
  clearCommunications(state) {
    state.communications = [];
  },
  shipmentsFilters(state, payload: IShipmentSearchFilter) {
    // I have to make a copy to detect changes later
    state.shipmentsFilters = Object.assign({}, payload);
  },
  barcodesLoaded(state, payload: string[]) {
    state.barcodes = payload;
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  },
  clearError(state, payload: string) {
    Vue.delete(state.errors, payload);
  }
};

const namespaced: boolean = true;

export const shipmentModule: Module<ShipmentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
