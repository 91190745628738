



































import Vue from 'vue';
import Component from "vue-class-component";
import { WEB_VERSIONS } from "@/helpers/ToggleFeatureHelper";

@Component({
})
export default class Home extends Vue {
  private get showCourierAndBarcode(): boolean {
    return !this.$isVersion(WEB_VERSIONS.BARCELONA_PUERTO);
  }

  get isLoggedIn(): boolean {
    return !!this.$store.getters['authModule/token'];
  }
}
