























import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {IErrors} from "@/model/IErrors";
import BoDialog from "@/components/Bo/BoDialog.vue";
import {ICell} from "@/model/model/Hub/ICell";
import Vue from "vue";

const hubModule = namespace("hubModule");

@Component({
  name: 'ChangeConnectorDialog2',
  components: {
    BoDialog,
  }
})
export default class ChangeConnectorDialog2 extends Vue {
  @hubModule.Getter('errors')
  private errors!: IErrors;

  @hubModule.Action('changeCellConnector')
  private changeCellConnector!: (data: object) => void;

  @hubModule.Action('resetErrors')
  private resetErrors!: () => any;

  @Prop()
  private value!: boolean;

  @Prop()
  private cell!: ICell;

  private get disabled() {
    return !this.newConnector || this.newConnector == this.cell.connector;
  }

  private newConnector: number = 0;

  @Watch('cell')
  private updateConnector() {
    this.newConnector = this.cell.connector;
  }

  private onSubmit() {
    this.changeCellConnector({ cellId: this.cell.id, connector: this.newConnector});
  }

}


