






















































































import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import CollectDeliverCounter from "@/components/Courier/Widget/CollectDeliverCounter.vue";
import ShipmentsToRoute from "@/components/Courier/Screens/ShipmentsToRoute.vue";
import {IHub} from "@/model/model/Hub/IHub";
import {ShipmentHelper} from "@/helpers/ShipmentHelper";
import { LoadingMixin } from '@/plugins/mixins';
import { icons } from '@/plugins/icons.ts';

const courierModule = namespace("courierModule");
const hubModule = namespace("hubModule");

@Component({
  components: {
    CollectDeliverCounter,
    ShipmentsToRoute,
  }
})
// export default class RouteHubs extends Vue {
export default class RouteHubs extends mixins(LoadingMixin) {

  @courierModule.Getter('shipmentsInRoute')
  private shipmentsInRoute!: IShipmentDetails[];
  @hubModule.Getter('hubById')
  private hubById: any;
  @hubModule.Getter('hubsLoaded')
  private hubsLoaded!: boolean;
  private icons = icons;

  get routeHubs(): IHub[] {
    const shipments = this.shipmentsInRoute;
    return ShipmentHelper.hubIds(shipments)
      .map(eachHubId => this.hubById(eachHubId));
    // return [];
  }

  created() {
    // this.ons = this.$ons({}, this.ons);
    this.$store.dispatch("hubModule/getHubs");
    this.$store.dispatch("courierModule/fetchShipmentsInRoute");
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  private onGoNext(hub?: IHub) {
    if (!hub || !hub.id) {
      return;
    }
    this.$router.push({name: 'courierAtHub', params: { hubId: hub.id.toString() }});
  }

  private onGo() {
    this.onGoNext(this.routeHubs[0]);
  }

  private shipmentsInHubToCollectCnt(hubId: number) {
    return ShipmentHelper.shipmentsToCollect(
      ShipmentHelper.shipmentsByHub(this.shipmentsInRoute, hubId)
    ).length;
  }

  private shipmentsInHubToDeliverCnt(hubId: number) {
    return ShipmentHelper.shipmentsToDeliver(
      ShipmentHelper.shipmentsByHub(this.shipmentsInRoute, hubId)
    ).length;
  }

  private shipmentsInHubCnt(hubId: number) {
    return ShipmentHelper.shipmentsByHub(this.shipmentsInRoute, hubId).length;
  }

  onBack() {
    this.$router.push({name: "courier"});
  }

}
