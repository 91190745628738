import Vue from 'vue';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import { RootState } from '../RootState';
import axios from "axios";
import {settings} from "@/plugins/settings";
import {IHub} from "@/model/model/Hub/IHub";
import {IErrors} from "@/model/IErrors";
import {IUser} from "@/model/model/User/IUser";
import {UserHelper} from "@/helpers/UserHelper";
import responseHandler from "@/plugins/responseHandling";

interface HubState {
  hubs: IHub[];
  hub?: IHub;
  errors: IErrors;
}

const state: HubState = {
  hubs: [],
  hub: undefined,
  errors: {},
};


const getters: GetterTree<HubState, RootState> = {
  hubs: (state): IHub[] => state.hubs,
  hub: (state): IHub => state.hub || {} as IHub,
  hubById: (state) => (id: any) => state.hubs.find(hub => hub.id == id),
  hubsLoaded: (state) => state.hubs.length > 0,
  errors: (state) => state.errors,
  getUserDefaultHub: (state: any, getters: { [key: string]: any}): (user: IUser) => IHub|null => {
    return (user: IUser): IHub|null => {
      if (user && UserHelper.userHasDefaultHubId(user)) {
        return getters['hubById'](user.default_hub_id);
      }
      return null;
    }
  },
};

const actions: ActionTree<HubState, RootState> = {
  resetHub: ({ commit }, hub?: IHub): any => {
    commit('hubLoaded', hub);
  },
  getHub: ({ commit, state, dispatch }, hubId): any => {
    if (!state.hub || !state.hub.id || state.hub.id != hubId) {
      dispatch('resetHub');
      dispatch('fetchHub', hubId);
    }
  },
  openCell: ({ commit }, cellId): any => {
    responseHandler.handle(
      () => axios({
        url: `${ settings.endpoint }bo/cells/${ cellId }/open`,
        method: 'POST',
      }),
      commit,
      'openCell'
    )
  },

  toggleDisableCell: ({ commit }, cellId): any => {
    responseHandler.handle(
      () => axios({
        url: `${ settings.endpoint }bo/cells/${ cellId }/toggleActiveCell`,
        method: 'POST',
      }),
      commit,
      'hubLoaded'
    )
  },

  fetchHub: ({ commit }, hubId): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/hubs/' + hubId
    }),
    commit,
    'hubLoaded'
  ),
  // use this when hub background data is needed. Will not reload if already loaded
  getHubs: ({ commit, state, dispatch }): any => {
    if (state.hubs.length) {
      return;
    }
    return dispatch('fetchHubs');
  },
  fetchHubs: ({ commit }): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/hubs/'
    }),
    commit,
    'hubsLoaded'
  ),

  clearHubs: ({ commit }): any => {
    return commit('clearHubs');
  },

  createHub: ({ commit }, hubData): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/hubs/',
      method: 'PUT',
      data: hubData
    }),
    commit,
    'hubCreated'
  ),

  addColumn: ({ commit }, hubId): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/hubs/' + hubId + '/columns',
      method: 'POST',
    }),
    commit,
    'hubLoaded'
  ),

  changeCellConnector: ({ commit }, { cellId, connector }): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/cells/' + cellId + '/connector',
      method: 'POST',
      data: {
        connector,
      }
    }),
    commit,
    'hubLoaded'
  ),


  clearError: ({ commit }, field): any => commit('clearError', field),
  resetErrors: ({ commit }): any => commit('resetErrors'),
  // @todo update
  // updateHub: ({ commit }, {hubId, pathPart, data}): any => responseHandler.handle(
  //   () => axios({
  //     url: settings.endpoint + 'lockers/' + lockerId + pathPart,
  //     method: 'PUT',
  //     data
  //   }),
  //   commit,
  //   'hubLoaded'
  // ),
};

const mutations: MutationTree<HubState> = {
  resetHub(state, payload?: IHub) {
    state.hub = payload || undefined;
  },
  hubLoaded(state, payload: IHub) {
    state.hub = payload;
  },
  hubCreated(state, payload: IHub) {
    // with this I can switch to 'edit' mode after create without having to realod
    state.hub = payload;
  },
  hubsLoaded(state, payload: IHub[]) {
    state.hubs = payload;
  },
  clearHubs(state) {
    state.hubs = [];
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  },
  clearError(state, field: string) {
    if (state.errors.field) {
      Vue.delete(state.errors, field);
    }
  },
  openCell(state) {

  },
  resetErrors(state) {
    state.errors = {};
  }

};

const namespaced: boolean = true;

export const hubModule: Module<HubState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
