
















import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IHub} from "@/model/model/Hub/IHub";
import {namespace} from "vuex-class";

const hubModule = namespace("hubModule");

@Component({})
export default class HubSelector extends Vue {

  @Prop()
  value!: any;

  @hubModule.Getter('hubs')
  private hubs!: IHub[];


  get hubSelectorItems() {
    const hubs = this.hubs;
    return hubs.map(eachHub => ({
      text: eachHub.name,
      value: eachHub.id,
    }));
  }

  private onClearFilterHub() {
    this.$emit('change', null);
  }

  private onChange(value: number) {
    this.$emit('change', value);
  }

}
