






















































































































































import Component, {mixins} from "vue-class-component";

import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {ICell} from "@/model/model/Hub/ICell";
import {IHub} from "@/model/model/Hub/IHub";
import { icons } from '@/plugins/icons.ts';
import {LoadingMixin} from "@/plugins/mixins";

import { BoListPanel } from "bonomo";
import { ShipmentHelper } from '@/helpers/ShipmentHelper';
import { EShipmentStatus, EShipmentType } from "@/model/model/Shipment/EShipment";
import {Watch} from "vue-property-decorator";

const hubModule = namespace("hubModule");
const manifestModule = namespace("manifestModule");

@Component({
  components: {
    BoListPanel
  }
})
export default class AlmoPastManifestShipments extends mixins(LoadingMixin) {
  @manifestModule.Action('fetchPastManifestShipments')
  private fetchPastManifestShipments!: (manifestId: number) => Promise<any>
  @manifestModule.Action('fetchPastManifestAllShipments')
  private fetchPastManifestAllShipments!: (manifestId: number) => Promise<any>

  @manifestModule.Getter('pastManifestShipments')
  private pastManifestShipments!: IShipmentDetails[];

  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  protected ons!: IOnOffHandlers;
  private icons = icons;
  private manifestId = '';
  private fetchStoreAction = this.fetchPastManifestShipments;

  private headers: IDataTableHeader[] = [
    { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
    { text: this.$t('shipments.shipment.type.label') as string, value: "type", },
    { text: this.$t('shipments.shipment.status.label') as string, value: "status", },
    { text: this.$t('hubs.common.where') as string, value: "hub", },
    { text: this.$t('shipments.shipment.from_user.label') as string, value: "from_user_id", },
    { text: this.$t('shipments.shipment.to_user.label') as string, value: "to_user_id", },
    { text: this.$t('shipments.shipment.created_at.label') as string, value: "created_at", },
    { text: "", sortable: false, value: "controls", align: "center"},
  ];

  @Watch("$route")
  onRouteChanged() {
    this.manifestId = this.$route.params.entityId;
    this.fetchStoreAction = this.$route.params.paged ?
      this.fetchPastManifestShipments:
      this.fetchPastManifestAllShipments;
  }

  private shipmentIsDeleted: (shipment: IShipmentDetails) => boolean = ShipmentHelper.shipmentIsDeleted;

  protected shipmentIsNewAlmo(shipment: IShipmentDetails): boolean {
    return shipment.type == EShipmentType.ALMO;
  }

  protected shipmentIsInAwaitingCollectOrBefore(shipment: IShipmentDetails): boolean {
    return shipment.status <= EShipmentStatus.STATUS_AWAITING_COLLECT;
  }

  protected shipmentIsAfterAwaitingCollect(shipment: IShipmentDetails): boolean {
    return shipment.status > EShipmentStatus.STATUS_AWAITING_COLLECT;
  }

  private get getPastManifestShipments(): IShipmentDetails[] {
     return this.pastManifestShipments;
  }

  public created() {
    this.onRouteChanged();
  }

  protected shipmentUrl(item: IShipmentDetails) {
    return item.type == EShipmentType.ALMO
      ? {name: 'shipmentsLabelEditor', params: { entityId: item.id }}
      : {name: 'shipmentDetails', params: { shipmentId: item.id }};
  }

  private hubName(cell: ICell) {
    const hub = cell ? this.hubById(cell.hub_id) : null;
    return hub ? hub.name : '??';
  }

  private goBack(): void {
    this.$router.push({name: "home"});
  }

  private goToPrint(): void {
    this.$router.push({name: "almoManifestShipmentsPrinter", params: { entityId:  this.manifestId }});

  }
}
