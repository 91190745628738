var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipments-to-route"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.shipments,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","custom-sort":_vm.shipmentsSorter,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.type.values.' + item.type))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.status.values.' + item.status))+" ")]}},{key:"item.from_hub",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.from_cell && item.from_cell.hub_id ? _vm.hubById(item.from_cell.hub_id).name : '-')+" ")]}},{key:"item.to_hub",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.to_cell && item.to_cell.hub_id ? _vm.hubById(item.to_cell.hub_id).name : '-')+" ")]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [(_vm.validForDeliver(item))?_c('v-btn',{attrs:{"color":"primary","disabled":!item.bag_code || !item.bag_code.length,"x-small":"","fab":""},on:{"click":function($event){return _vm.onAddToRoute(item)}}},[_c('v-icon',[_vm._v("mdi-truck-check")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }