import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import { cookies} from "@/plugins/cookies";

import { RootState } from '../RootState';
import axios from "axios";
import { uiBus } from '@/plugins/uiBus';
import {settings} from "@/plugins/settings";
import {IErrors} from "@/model/IErrors";
import responseHandler from "@/plugins/responseHandling";
var jwtDecode = require('jwt-decode');

const namespaced: boolean = true;
const KEY_TOKEN = 'auth/token';
const KEY_USER_DATA = 'auth/userData';



export interface IAuthUserPermission {
  permission: number;
  target_id: number;
}

export interface IAuthUserData {
  id?: number;
  email?: string;
  role?: string;
  permissions?: IAuthUserPermission[];
}

interface AuthState {
  token?: string;
  userData: IAuthUserData;
  errors: IErrors;
}

const token = cookies.get(KEY_TOKEN);
const userData = JSON.parse(localStorage.getItem(KEY_USER_DATA) || '{}');

const state: AuthState = {
  token,
  // error: false,
  userData,
  errors: {},
};

axios.defaults.headers.common['Authorization'] = 'bearer ' + state.token;

const getters: GetterTree<AuthState, RootState> = {
  token: (state): string|undefined => state.token,
  userData: (state): IAuthUserData => state.userData,
  errors: (state): IErrors => state.errors,
  userIsLogged: (state: AuthState): boolean => !!state.token,
};

const actions: ActionTree<AuthState, RootState> = {
  login({ commit }, {email, password}): any {
    commit('loginRequest');
    axios({
      url: settings.endpoint + 'login',
      method: 'POST',
      data: { email, password, },
    })
    .then((response: any) => {
      const token: string = response.data.token;
      commit('loginOk', token);
    })
    .catch((e: any) => {
      const errors = responseHandler.transformErrors(e);
      commit('errors', errors);
    });
  },
  logout({ commit }): any {
    commit('logout');
  },
};

const mutations: MutationTree<AuthState> = {
  loginRequest(state) {
    state.token = undefined;
    state.errors = {};
  },
  loginOk(state, token: string) {
    const data: any = jwtDecode(token);
    const userData = {
      id: data.sub,
      email: data.uem,
      role: data.uro,
      permissions: data.upe,
    };
    state.token = token;
    state.errors = {};
    state.userData = userData;
    cookies.set(KEY_TOKEN, token);
    localStorage.setItem(KEY_USER_DATA, JSON.stringify(userData));
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
    uiBus.emit('login.OK');
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  },
  logout(state) {
    state.token = undefined;
    // no need to clean errors on logout - they are cleaned on login and that's the only place where it can be not empty
    // state.errors = {};
    state.userData = {};
    cookies.remove(KEY_TOKEN);
    localStorage.removeItem(KEY_USER_DATA);
    axios.defaults.headers.common['Authorization'] = '';
  },
};

export const authModule: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
