








































































































































































































































import Vue from 'vue'
import Component from "vue-class-component";
import {namespace} from "vuex-class";
import {IErrors} from "@/model/IErrors";
import {IAlmoShipmentImported} from "@/store/modules/manifestModule";
import {IOnOffHandlers} from "bonomo/dist";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";

const manifestModule = namespace("manifestModule");

@Component({
  name: 'AlmoManifestShipmentsImport',
})

export default class AlmoManifestShipmentsImport extends Vue {

  @manifestModule.Getter('errors')
  private errors!: IErrors;

  @manifestModule.Getter('importResults')
  private importResults!: IAlmoShipmentImported[];

  @manifestModule.Action('almoManifestShipmentsImport')
  private almoManifestShipmentsImport!: (payload:{file: string}) => void;

  private readonly OKStatuses: number[] = [0, 101, 102, 103];

  protected get exampleLink(): string {
    return '/assets/resources/importExample-' + this.$i18n.locale + '.csv'
  }
  protected get hasImportResults(): boolean {
    return this.importResults && !!this.importResults.length;
  }
  protected get hasErrors(): boolean {
    return this.errors && !!Object.keys(this.errors).length;
  }
  protected get showResults(): boolean {
    return this.hasImportResults || this.hasErrors;
  }
  protected get resultsImportedCnt(): number {
    return this.hasImportResults
      ? this.importResults.filter(each => each.code_import == 101).length
      : 0;
  }
  protected get resultsUpdatedCnt(): number {
    return this.hasImportResults
      ? this.importResults.filter(each => each.code_import == 102).length
      : 0;
  }
  protected get resultsNotModifiedCnt(): number {
    return this.hasImportResults
      ? this.importResults.filter(each => each.code_import == 103).length
      : 0;
  }
  protected get resultsErrorsCnt(): number {
    return this.hasImportResults
      ? this.importResults.filter(each => this.isErrorResult(each)).length
      : 0;
  }
  protected get importResultsOrdered(): IAlmoShipmentImported[] {
    return this.importResults && this.importResults.length
      ? this.importResults.filter(each => this.isErrorResult(each))
          .concat(
              this.importResults.filter(each => !this.isErrorResult(each))
          )
      : [];
  }
  private isErrorResult(result: IAlmoShipmentImported): boolean {
    return !this.OKStatuses.includes(result.code_import);
  }

  private headers: IDataTableHeader[] = [
    { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
    { text: this.$t('shipments.shipment.bag_code.label') as string, value: "bag_code", },
    { text: this.$t('shipments.labelEditor.fields.numberOfPackages.label') as string, value: "pieces", },
    { text: this.$t('shipments.labelEditor.fields.weight.label') as string, value: "weight", },
    { text: this.$t('shipments.labelEditor.fields.secondaryCode.label') as string, value: "secondary_code", },
    { text: this.$t('shipments.labelEditor.fields.reference.label') as string, value: "reference", },
    { text: this.$t('users.user.phone_number') as string, value: "recipient_phone_number", },
    { text: this.$t('users.user.email') as string, value: "recipient_email", },
    { text: this.$t('users.user.first_name') as string, value: "recipient_first_name", },
    { text: this.$t('users.user.last_name') as string, value: "recipient_last_name", },
    { text: this.$t('common.address.streetAddress') as string, value: "recipient_address", },
    { text: this.$t('common.address.streetAddress2') as string, value: "recipient_address_2", },
    { text: this.$t('common.address.postalCode') as string, value: "recipient_postal_code", },
    { text: this.$t('common.address.city') as string, value: "recipient_city", },
    { text: this.$t('common.address.province') as string, value: "recipient_province", },
    { text: this.$t('shipments.shipment.note.label') as string, value: "note", },
  ];


  protected rules: any[] = [];
  protected file: any = null;
  protected submitting: boolean = false;

  private subscribes: IOnOffHandlers = {};

  private errorFieldMap: any = {
    10: 'code_import', // unknown
    100: 'phone_number', // invalid line
    101: 'code_import', // OK,
    130: 'code_import', // imported with error
    201: 'note',
    202: 'bag_code',
    203: 'pieces',
    204: 'weight',
    205: 'secondary_code',
    206: 'reference',
    207: 'recipient_phone_number',
    208: 'recipient_email',
    209: 'recipient_first_name',
    210: 'recipient_last_name',
    211: 'recipient_address',
    212: 'recipient_address_2',
    213: 'recipient_postal_code',
    214: 'recipient_city',
    215: 'recipient_province',
  };

  protected fieldClass(item: IAlmoShipmentImported, currentField: string = ''): string[] {

    const result = item.code_import;

    const ret: string[] = ['shipment-import--result-cell pl-3 pr-3'];

    // consider success both cases
    if ((item.code_import === 101) || (item.code_import === 130) || (item.code_import === 0)) {
      ret.push('green');
    }
    // not updated
    else if (item.code_import === 102) {
      ret.push('blue');
    }
    else if (item.code_import === 103) {
      ret.push('teal');
    }
    // not processed
    else if (item.code_import === 199) {
      ret.push('yellow');
    }
    else {
      ret.push('red');
    }

    const isErrorTargetField = this.errorFieldMap[result] === currentField;

    // keep the field that caused the error bright, lighten the rest of the line
    ret.push(
        isErrorTargetField ||
        (result === 100 && ((item as any)[currentField]) === '')
          ? 'white--text'
          : 'lighten-4'
    );

    // extra padding if not locker field
    if (currentField !== 'locker_id') {
      ret.push('pt-1 pb-1');
    }

    return ret;

  }

  created() {
    this.rules.push(
      (v: any) => !!v || this.$t('errors.general.required'),
    );

    this.subscribes = this.$ons({
      'backend.call.OK.almoManifestShipmentsImported': this.imported,
      'backend.call.KO.almoManifestShipmentsImported': this.imported,
    });
  }

  // have to enable button, and clean previous file
  private imported() {
    this.submitting = false;
    // clean file, and reset validation so it won't turn red by being null
    this.file = null;
    (this.$refs.form as any).resetValidation();
  }

  protected onSubmit(){
    // @ts-ignore (another reason why we don't like v-form :) )
    if (this.$refs.form.validate()) {
      this.submitting = true;
      this.almoManifestShipmentsImport({
        file: this.file
      });
    }

  }
}

