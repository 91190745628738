














import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({})
export default class IdleSlider extends Vue {

  @Prop()
  updatedAt!: string;

  get tickLabels() {
    const value = this.value;
    const labels = Object.values(this.$t('shipments.general.idle.values'));
    for (let i in labels) {
      if (parseInt(i) !== value) {
        labels[i] = '';
      }
    }
    return labels;
  }
  get value(): number {
    const updated = this.updatedAt;
    const diff = (new Date()).getTime() - (new Date(updated)).getTime();
    const oneHour = 1000*60*60;
    const oneDay = oneHour*24;
    // @todo here we could implement considering only workdays if that matters.
    if (diff < oneHour) {
      return 1;
    }
    if (diff < oneDay) {
      return 2;
    }
    else if (diff < 7 * oneDay) {
      return 3;
    }
    return 4;
  }
  get color(): string {
    const value = this.value;
    const colors: any = {
      0: 'blue',
      1: 'blue',
      2: 'green',
      3: 'orange',
      4: 'red',
    };
    return colors[value] || 'grey';
  }

}
