import Vue from 'vue';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import { RootState } from '../RootState';
import axios from "axios";
import {settings} from "@/plugins/settings";
import {IUser} from "@/model/model/User/IUser";
import {IErrors} from "@/model/IErrors";
import responseHandler from "@/plugins/responseHandling";

interface UserState {
  user?: IUser,
  errors: IErrors,
}

const state: UserState = {
  user: undefined,
  errors: {},
};

const getters: GetterTree<UserState, RootState> = {
  user: (state): IUser => state.user || {} as IUser,
  errors: (state): IErrors => state.errors,
};

const actions: ActionTree<UserState, RootState> = {
  byDeliveryToken: ({ commit }, deliveryCode): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/users/deliveryToken/' + deliveryCode
    }),
    commit,
    'fetchUser'
  ),
  resetUser: ({ commit }): any => commit('resetUser'),
  errors: ({ commit }, errors: IErrors): any => {
    commit('errors', errors);
  }
};

const mutations: MutationTree<UserState> = {
  fetchUser(state, payload: IUser) {
    Vue.set(state, 'user', payload);
  },
  resetUser(state) {
    state.user = undefined;
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  }
};

const namespaced: boolean = true;

export const userModule: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
