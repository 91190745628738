





























import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IUser} from "@/model/model/User/IUser";

@Component({})
export default class UserCardInner extends Vue {

  @Prop()
  title!: string;
  @Prop()
  user!: IUser;

  get name() {
    if (!this.user) return '??';
    return (this.user.first_name || '') +
      (this.user.first_name || this.user.last_name ? ' ' : '') +
      (this.user.last_name || '');
  }

}
