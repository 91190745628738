import Vue from 'vue'
import VueRouter from 'vue-router'
// import ModuloSwagger from "@/views/swaggerui/ModuloSwagger.vue";
import AlmoManifests from "@/views/Shipments/AlmoManifests.vue";
import AlmoManifestShipments from "@/views/Shipments/AlmoManifestShipments.vue";
import HubsIndex from "@/views/Hubs/HubsIndex.vue";
import Home from "@/views/Home.vue";
import HubDetails from "@/views/Hubs/HubDetails.vue";
import ShipmentsIndex from "@/views/Shipments/ShipmentsIndex.vue";
import ShipmentDetails from "@/views/Shipments/ShipmentDetails.vue";
import Courier from "@/views/Courier/Courier.vue";
import AddToRoute from "@/views/Courier/AddToRoute.vue";
import RouteHubs from "@/views/Courier/RouteHubs.vue";
import AtHub from "@/views/Courier/AtHub.vue";
import ShipmentsCreateExternal from "@/views/Shipments/ShipmentsCreateExternal.vue";
import BarcodeIndex from "@/views/Barcode/BarcodeIndex.vue";
import BarcodePrinter from "@/views/Barcode/BarcodePrinter.vue";
import UsersIndex from "@/views/Users/UsersIndex.vue";
import UserDetails from "@/views/Users/UserDetails.vue";
import ShipmentsLabelEditor from "@/views/Shipments/ShipmentsLabelEditor.vue";
import AlmoPastManifestShipments from "@/views/Shipments/AlmoPastManifestShipments.vue";
import AlmoManifestShipmentsImport from "@/views/Shipments/AlmoManifestShipmentsImport.vue";
import AlmoManifestShipmentsPrinter from "@/views/Shipments/AlmoManifestShipmentsPrinter.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   path: '/modulo/swagger/modulo',
  //   name: 'swaggerUi',
  //   component: ModuloSwagger,
  // },
  {
    path: '/courier',
    name: 'courier',
    component: Courier,
  },
  {
    path: '/courier/addToRoute',
    name: 'courierAddToRoute',
    component: AddToRoute,
  },
  {
    path: '/courier/routeHubs',
    name: 'courierRouteHubs',
    component: RouteHubs,
  },
  {
    path: '/courier/atHub/:hubId',
    name: 'courierAtHub',
    component: AtHub,
    props: true,
  },
  {
    path: '/hubs',
    name: 'hubs',
    component: HubsIndex,
  },
  {
    path: '/hubs/create',
    name: 'hubCreate',
    component: HubDetails,
  },
  {
    path: '/hubs/:hubId',
    name: 'hubDetails',
    component: HubDetails,
    props: true,
  },
  {
    path: '/users',
    name: 'users',
    component: UsersIndex,
  },
  {
    path: '/users/:userId',
    name: 'userDetails',
    component: UserDetails,
    props: true,
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: ShipmentsIndex,
  },

  {
    path: '/shipments/createLabel',
    name: 'shipmentsCreateLabel',
    component: ShipmentsLabelEditor,
  },
  {
    path: '/shipments/create/external',
    name: 'shipmentsCreateExternal',
    component: ShipmentsCreateExternal,
  },
  {
    path: '/shipments/:entityId/label',
    name: 'shipmentsLabelEditor',
    component: ShipmentsLabelEditor,
    props: true,
  },
  {
    path: '/shipments/:shipmentId',
    name: 'shipmentDetails',
    component: ShipmentDetails,
    props: true,
  },
  {
    path: '/manifests',
    name: 'manifest',
    component: AlmoManifests,
  },
  {
    path: '/manifests/current',
    name: 'almoManifestShipments',
    component: AlmoManifestShipments,
  },
  {
    path: '/manifests/shipmentsImport',
    name: 'manifestShipmentsImport',
    component: AlmoManifestShipmentsImport,
  },
  {
    path: '/manifests/:entityId',
    name: 'almoPastManifestShipments',
    component: AlmoPastManifestShipments,
  },
  {
    path: '/manifests/:entityId/all',
    name: 'almoManifestAllShipments',
    component: AlmoPastManifestShipments,
  },
  {
    path: '/manifests/:entityId/print',
    name: 'almoManifestShipmentsPrinter',
    component: AlmoManifestShipmentsPrinter,
  },
  {
    path: '/barcode',
    name: 'barcodeIndex',
    component: BarcodeIndex,
  },
  {
    path: '/barcode/print',
    name: 'barcodePrinter',
    component: BarcodePrinter,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router
