export interface IIcons {
  [key: string]: string|IIcons,

}
export const icons: IIcons = {
  barcode: 'mdi-barcode',
  deleted: 'mdi-delete-empty',
  // hub: 'mdi-locker-multiple',
  hub: 'mdi-ballot-outline',
  shipment: 'mdi-cube-send',
  manifest: 'mdi-list-box-outline',
  user: 'mdi-account',

  awaitingDropoff: 'mdi-account-clock',
  collect: 'mdi-cube-send',
  processing: 'mdi-clock',
  deliver: 'mdi-truck-fast',
  awaitingPickup: 'mdi-account-clock',
  completed: 'mdi-check-circle',
  gotoNextHub: 'mdi-truck-delivery',
  allDone: 'mdi-emoticon-happy',
  alert: 'mdi-alert-circle',

  // @todo @ramon fix these
  // shipmentType100: 'mdi-map',
  // shipmentType200: 'mdi-dolly',
  // shipmentType300: 'mdi-store',
  // shipmentType400: 'mdi-keyboard-return',
  // shipmentType500: 'mdi-alpha-p-circle',
  shipments: {
    types: {
      600: 'mdi-barcode',
    },
  },
  manifests: {
    actions: {
      close: 'mdi-calendar-today',
      import: 'mdi-file-excel-outline',
      upload: 'mdi-account-multiple-plus-outline',
      print: 'mdi-printer-outline',
    },
  },

  unknown: 'mdi-help-circle',

  communications: {
    types: {
      sms: 'mdi-cellphone-text',
      email: 'mdi-email',
      push: 'mdi-alarm-light',
    }
  }

};
