















































































/**
 * notes on html markup:
 *   <v-text-field
 *       v-model="data.name" - bind to model field directly
 *       dense
 *       :disabled="disabled" - disable when loading
 *       :rules="[ruleMandatory]" - manual rules, define just required or other formal validation. Rest should come from backend
 *       :label="$t('hubs.hub.name.label')" - label from translations
 *       :error-messages="errors.name" - maps error messages from this.errors
 *       @change="onChange('name')" - with this we remove any existing errors for this field from this.errors
 *   ></v-text-field>
 */

import Vue from 'vue';
import Component from "vue-class-component";
import {IHub} from "@/model/model/Hub/IHub";
import {Prop} from "vue-property-decorator";
import {IErrors} from "@/model/IErrors";

@Component({})
export default class HubForm extends Vue {

  @Prop()
  data!: IHub;
  @Prop()
  errors!: IErrors;
  @Prop()
  private disabled!: boolean;
  private valid: boolean = false;

  get statusItems() {
    // @ts-ignore
    return Object.keys(this.$t('hubs.hub.status.values'))
    // @ts-ignore
      .map(eachKey => ({ value: eachKey, text: this.$t('hubs.hub.status.values.' + eachKey), }));
  }

  ruleMandatory(value: any) {
    return '' + value !== '';
  }

  ruleNumber(value: any) {
    return '' + value == '' + (+value);
  }

  onInput() {
    if (this.$refs.form) {
      (this.$refs.form as any).validate();
    }
    this.$emit("valid", this.valid);
  }

  onChange(fieldName: string) {
    this.$emit("change", fieldName);
  }

}
