














































import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import {LoadingMixin} from "@/plugins/mixins";
import {IUserCommunication} from "@/model/model/UserCommunications/IUserCommunications";
import {COMMUNICATION_TYPES} from '@/model/model/UserCommunications/IUserCommunications';
import {DateHelper} from "@/helpers/DateHelper";
import CommunicationsDialog from "@/components/Communications/Dialog/CommunicationsDialog.vue";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {BoListPanel} from "bonomo";
import {Prop} from "vue-property-decorator";
import {messageStatusIsError} from "@/helpers/errorsHelper";
import { COMMUNICATION_STATUS } from '@/model/model/UserCommunications/IUserCommunications';

const shipmentModule = namespace("shipmentModule");

@Component({
  name: 'ShipmentCommunications',
  components: {
    CommunicationsDialog,
    BoListPanel,
  }
})
export default class ShipmentCommunications extends mixins(LoadingMixin) {

  @Prop()
  private shipmentId!: number;
  @shipmentModule.Getter('communications')
  private communications!: IUserCommunication[];

  @shipmentModule.Action('fetchShipmentsCommunications')
  private fetchShipmentsCommunications!: (params: any) => void

  @shipmentModule.Action('clearShipmentCommunications')
  private clearShipmentCommunications!: () => void

  private formatDate: (date: string) => string = DateHelper.formatDate;

  private isError: ((x: COMMUNICATION_STATUS) => boolean ) = messageStatusIsError;

  get headers(): IDataTableHeader[] {
    return [
      { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
    ];
  }

  private showDialogWithCommunication(dialogCommunication: IUserCommunication) {
    this.$uiBus.emit('communicationsDialog:show', dialogCommunication);
  }

  private fetchMoreCommunications(filters: any) {
    this.fetchShipmentsCommunications({shipmentId: this.shipmentId, ...filters})
  }

  private isEmail(message: IUserCommunication) {
    return message.type === COMMUNICATION_TYPES.EMAIL;
  }

}
