import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './RootState';
import {authModule} from "@/store/modules/authModule";
import {hubModule} from "@/store/modules/hubModule";
import {shipmentModule} from "@/store/modules/shipmentModule";
import {courierModule} from "@/store/modules/courierModule";
import {userModule} from "@/store/modules/userModule";
import {usersModule} from "@/store/modules/usersModule";
import {manifestModule} from "@/store/modules/manifestModule";

Vue.use(Vuex);


const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0' // a simple property
  },
  modules: {
    authModule,
    hubModule,
    shipmentModule,
    courierModule,
    userModule,
    usersModule,
    manifestModule,
  }
};

export default new Vuex.Store<RootState>(store);
