



















import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IHub} from "@/model/model/Hub/IHub";
import {ICell} from "@/model/model/Hub/ICell";
import {namespace} from "vuex-class";

const hubModule = namespace("hubModule");

@Component({})
export default class HubCardInner extends Vue {

  @Prop()
  title!: string;
  @Prop()
  cell!: ICell;

  @hubModule.Getter('hubById')
  private hubById: any;

  get hub(): IHub {
    return this.cell ? this.hubById(this.cell.hub_id) : {};
  }

}
