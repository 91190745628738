

























import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {icons} from "@/plugins/icons.ts";
import {IShipmentDetails} from '@/model/model/Shipment/IShipmentDetails';
import {ShipmentHelper} from "@/helpers/ShipmentHelper";

@Component({})
export default class CollectDeliverCounter extends Vue {

  @Prop()
  shipments!: IShipmentDetails[];

  icons = icons;

  get hubCnt() {
    const shipments = this.shipments;
    return ShipmentHelper.hubIds(shipments).length;
  }

  get shipmentsToCollectCnt() {
    const shipments = this.shipments;
    return ShipmentHelper.shipmentsToCollect(shipments).length;
  }

  get shipmentsToDeliverCnt() {
    const shipments = this.shipments;
    return ShipmentHelper.shipmentsToDeliver(shipments).length;
  }

}
