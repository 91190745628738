

































import Vue from 'vue'
import Component from "vue-class-component";
import {namespace} from "vuex-class";
import {Watch} from "vue-property-decorator";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IUserEssentials} from "@/model/model/User/IUser";

const usersModule = namespace("usersModule");

@Component({
  name: 'UserSearch',
  components: {}
})
export default class UserSearch extends Vue {

  @usersModule.Getter('userSearch')
  protected items!: IUserEssentials[];

  @usersModule.Action('clearUserSearch')
  protected clearUserSearch!: () => void;

  @usersModule.Action('fetchUserSearch')
  protected fetchUserSearch!: (el: string) => void;

  protected model: any = null;
  protected loading: boolean = false;
  protected search: any = null;
  protected subscribes: IOnOffHandlers = {};
  private timeout: any;

  @Watch('search')
  protected onSearch(val: string) {

    if (!this.validSearch(val)) {
      this.clear();
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.loading = true;
      this.fetchUserSearch(val);
    }, 500);

  }

  created() {
    this.subscribes = this.$ons({
      'backend.call.OK.userSearchLoaded': this.onOK,
      'backend.call.KO.userSearchLoaded': this.onOK,
    });
  }

  protected validSearch(search: string|null) {
    return !(search == null || search.length < 3);
  }

  protected onFocus() {
    this.clear();
  }

  protected onBlur() {
    this.clear();
  }

  protected onKeyDown(keyEvent: KeyboardEvent) {
    if (this.search &&
        (
            keyEvent.charCode === 13
            || keyEvent.keyCode === 13
            || keyEvent.which === 13
            || keyEvent.code === 'Enter'
            || keyEvent.key === 'Enter'
        )
    ) {
      this.$emit('pressEnter', this.search);
    }
  }

  protected onChange(val: IUserEssentials) {
    this.$emit('change', val);
  }

  private onOK() {
    this.loading = false;
  }

  private clear() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.clearUserSearch();
  }

}

