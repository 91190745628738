












import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {IHub} from "@/model/model/Hub/IHub";
import BoDialog from "@/components/Bo/BoDialog.vue";
import Vue from "vue";

const hubModule = namespace("hubModule");

@Component({
  name: 'CreateCellColumnDialog',
  components: {
    BoDialog
  }
})
export default class CreateCellColumnDialog extends Vue {

  @Prop()
  private hub!: IHub;

  @hubModule.Action('addColumn')
  private addColumn!: (hubId: number) => void;

  @Prop()
  private value!: boolean;

  private onSubmit() {
    this.addColumn(this.hub.id);
  }

}

