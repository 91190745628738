




















































































































































import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {IErrors} from "@/model/IErrors";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";

import ShipmentData from "@/components/Shipments/ShipmentData.vue";
import FinalizeShipmentDialog from "@/views/Shipments/FinalizeShimpentDialog.vue";
import ShipmentCommunications from "@/components/Communications/ShipmentCommunications.vue";

import {LoadingMixin} from "@/plugins/mixins";
import { icons } from '@/plugins/icons.ts';
import {EShipmentStatus} from "@/model/model/Shipment/EShipment";
import {ShipmentHelper} from "@/helpers/ShipmentHelper";

const shipmentModule = namespace("shipmentModule");
const hubModule = namespace("hubModule");

const MODES = {
  view: 0,
};

@Component({
  components: {
    ShipmentData,
    FinalizeShipmentDialog,
    ShipmentCommunications
  }
})
export default class ShipmentDetails extends mixins(LoadingMixin) {

  @shipmentModule.Getter('shipment')
  private shipment!: IShipmentDetails;
  @shipmentModule.Getter('errors')
  private errors!: IErrors;
  // @hubModule.Getter('hubs')
  // private hubs!: IHub[];

  private showFinalizeDialog: boolean = false;

  get generalErrors() {
    return this.errors._ || [];
  }

  get shipmentIsComplete() {
    return this.shipment.status === EShipmentStatus.STATUS_COMPLETED;
  }

  private get shipmentIsDeleted (): boolean {
    return ShipmentHelper.shipmentIsDeleted(this.shipment);
  };

  private MODES: any = MODES;
  private mode: number = MODES.view;

  private icons = icons;
  private shipmentId!: any;

  @Watch("$route")
  onRouteChanged() {
    this.shipmentId = this.$route.params.shipmentId;
    if (!this.shipmentId) {
      this.onBack();
    }

    this.resetAndFetchShipment();
  }

  resetAndFetchShipment() {
    this.$store.dispatch("shipmentModule/resetShipment");
    this.$store.dispatch("shipmentModule/fetchShipment", this.shipmentId);
  }

  created() {
    this.$store.dispatch('hubModule/getHubs');
    this.onRouteChanged();
  }

  onBack() {
    this.$router.push({name: 'shipments'});
  }

  finalizeShipment() {
    this.showFinalizeDialog = true;
  }

  private closeDialogs() {
    this.showFinalizeDialog = false;
  }
}
