import Vue from 'vue';
import VueI18n from "vue-i18n";
import {ELanguages, messages} from '@/locale';
import browserLang from 'browser-lang';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: ELanguages.en, // set locale
  messages,
});

export const setLocale = (lang: ELanguages) => {
  if (i18n !== null) {
    i18n.locale = lang;
    localStorage.setItem('lang', lang);
  }
};

export const initLanguage = () => {
  let browserLanguage = browserLang({
    languages: [ELanguages.es, ELanguages.ca, ELanguages.en, ELanguages.pt],
    fallback: ELanguages.en,
  });

  let locale: ELanguages = localStorage.getItem("lang") as ELanguages || browserLanguage;

  // we need to remove this when we have ca and pt. Also we might have other languages in the future (de?)
  if (locale === ELanguages.ca || locale === ELanguages.pt) {
    locale = ELanguages.es;
  }

  setLocale(locale as ELanguages);
};

export const getLocale = () => {
  if (i18n !== null) {
    return i18n.locale;
  } else {
    return ELanguages.en;
  }
};
