


















import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({})
export default class ShipmentStatusFilter extends Vue {

  // I receive actual value through this prop. I will emit events on changes, and
  // parent component will update its variable, then pass it down into this prop
  @Prop()
  value!: any;

  get statusItems() {
    return Object
      .keys(this.$t('shipments.shipment.status.values'))
      .map(eachKey => ({
        text: this.$t('shipments.shipment.status.values.' + eachKey),
        value: eachKey,
      }));
  }

  private onClear() {
    this.$emit('change', []);
    this.$emit('blur');
  }

  private onChange(value: number[]) {
    this.$emit('change', value);
  }

  private onBlur() {
    this.$emit('blur');
  }

}
