var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bo-list-panel',{attrs:{"title":"shipments.shipments.title","fetch-mutation-name":"shipmentsLoaded","fetch-store-action":_vm.fetchShipments,"clear-store-action":_vm.clearShipments,"data-table-headers":_vm.headers,"data-table-items":_vm.getShipments,"remoteFilterFields":[ 'testShipments', 'hub_id', 'status'],"extraFilters":{ 'testShipments': _vm.testShipments, hub_id: _vm.filters.hub_id, status: _vm.filters.status },"hub_id":_vm.filters.hub_id,"status":_vm.filters.status,"showTestItems":_vm.testShipments,"createButtonTo":_vm.createButtonTo,"showMoreButton":"","download-button":_vm.downloadCsv,"back-button":_vm.goBack,"showDeletedItems":_vm.deletedItems,"item-count":""},on:{"update:hub_id":function($event){return _vm.$set(_vm.filters, "hub_id", $event)},"update:status":function($event){return _vm.$set(_vm.filters, "status", $event)},"update:showTestItems":function($event){_vm.testShipments=$event},"update:show-test-items":function($event){_vm.testShipments=$event},"update:showDeletedItems":function($event){_vm.deletedItems=$event},"update:show-deleted-items":function($event){_vm.deletedItems=$event}},scopedSlots:_vm._u([{key:"slot-toolbar-append",fn:function(){return [_c('v-btn',{staticClass:"ml-5",attrs:{"small":"","fab":"","color":"green"},on:{"click":_vm.goToLabelCreator}},[_c('v-icon',{attrs:{"default":"","color":"#fff"}},[_vm._v(_vm._s(_vm.$icons.shipments.types[600]))])],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.type.values.' + item.type),"color":_vm.shipmentIsDeleted(item) ? 'red lighten-4' : ''}},[_vm._v(" "+_vm._s(_vm.icons['shipmentType' + item.type])+" ")]),_c('span',{staticClass:"hidden-sm-and-down",class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.type.values.' + item.type))+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.shipmentIsDeleted(item))?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.20'),"color":"red lighten-4"}},[_vm._v(_vm._s(_vm.icons.deleted))]):(item.status <= 30)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.20'),"color":"orange"}},[_vm._v(_vm._s(_vm.icons.awaitingDropoff))]):(item.status <= 40)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.30'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.collect))]):(item.status < 70)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.50'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.processing))]):(item.status < 80)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.70'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.deliver))]):(item.status < 90)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.80'),"color":"green"}},[_vm._v(_vm._s(_vm.icons.awaitingPickup))]):_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.90'),"color":"green"}},[_vm._v("mdi-check-circle")]),(_vm.shipmentIsDeleted(item))?_c('span',{class:[
        'hidden-sm-and-down',
        {'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}
    ]},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.status.values.' + (_vm.shipmentIsDeleted(item) ? 'DELETED' : item.status )))+" ")]):_c('span',{},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.status.values.' + item.status))+" ")])]}},{key:"item.hub",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[(_vm.shipmentIsPublicDelivery(item))?_c('span',[_vm._v(" "+_vm._s(item.to_cell ? _vm.hubName(item.to_cell) : '-')+" ")]):(item.from_cell && _vm.shipmentIsInAwaitingCollectOrBefore(item))?_c('span',{attrs:{"title":'From: ' + _vm.hubName(item.from_cell) + '\nTo: ' + (item.to_cell?_vm.hubName(item.to_cell):'-')}},[_vm._v(" "+_vm._s(_vm.hubName(item.from_cell))+" ")]):(_vm.shipmentIsNewAlmo(item))?_c('span',[_c('strong',[_vm._v("-")])]):(_vm.shipmentIsInAwaitingCollectOrBefore(item) && !_vm.shipmentIsDeleted(item))?_c('span',[_c('strong',{staticStyle:{"color":"white","background-color":"red","padding":"0 10px"}},[_vm._v("!!!ERR!!!")])]):(item.to_cell && _vm.shipmentIsAfterAwaitingCollect(item))?_c('span',{attrs:{"title":'From: ' + _vm.hubName(item.to_cell) + '\nTo: ' + (item.to_cell?_vm.hubName(item.to_cell):'-')}},[_vm._v(" "+_vm._s(_vm.hubName(item.to_cell))+" ")]):_c('span',[_vm._v(" - ")])])]}},{key:"item.from_user_id",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(_vm._f("userNameOrPhone")(item.from_user))+" ")])]}},{key:"item.to_user_id",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(_vm._f("userNameOrPhone")(item.to_user))+" ")])]}},{key:"item.controls",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.shipmentUrl(item),"x-small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}}])},[[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('shipment-status-selector',{attrs:{"value":_vm.filters.status},on:{"change":_vm.onStatusChange}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('hub-selector',{attrs:{"value":_vm.filters.hub_id},on:{"change":_vm.onHubSelectorChange}})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }