import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import { RootState } from '../RootState';
import axios from "axios";
import {settings} from "@/plugins/settings";

import {IErrors} from "@/model/IErrors";
import {IUserSearchFilter} from "@/model/model/User/IUserSearchFilter";
import {IUser, IUserEssentials} from "@/model/model/User/IUser";
import {ORDER_DIRECTION} from "@/model/model/Database/DatabaseElements";
import responseHandler from "@/plugins/responseHandling";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IUserCommunication} from "@/model/model/UserCommunications/IUserCommunications";

const defaultShipmentFiltersState = (): IUserSearchFilter => ({
    status: [],
  });

interface UsersState {
  nextPageToLoad: number;
  users: IUserEssentials[];
  userSearch: IUserEssentials[];
  usersFilters: IUserSearchFilter;
  user?: IUser;
  communications: IUserCommunication[];
  shipments: IShipmentDetails[];
  errors: IErrors;
}

const state: UsersState = {
  nextPageToLoad: -1,
  users: [],
  userSearch: [],
  usersFilters: defaultShipmentFiltersState(),
  user: undefined,
  communications: [],
  shipments: [],
  errors: {},
};

const userIsAdmin = (user: IUserEssentials) => {
  return user.role === 20 || user.email.includes("@mayordomo.io");
}

const getters: GetterTree<UsersState, RootState> = {
  users: (state): IUserEssentials[] => state.users,
  userSearch: (state): IUserEssentials[] => state.userSearch,
  realUsers: (state): IUserEssentials[] => state.users.filter((user: IUserEssentials) => !userIsAdmin(user)),
  usersFilters: (state): IUserSearchFilter => state.usersFilters,
  user: (state): IUser => state.user || {} as IUser,
  communications: (state): IUserCommunication[] => state.communications,
  shipments: (state): IShipmentDetails[] => state.shipments,
  errors: (state): IErrors => state.errors,
  nextPageToLoad: (state): number => state.nextPageToLoad,
  canLoadNextPage: (state): boolean => state.nextPageToLoad >= 0,
};

const actions: ActionTree<UsersState, RootState> = {
  resetUser: ({ commit }, user: IUser): any => commit('resetUser', user),

  fetchUser: ({ commit }, userId): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/users/' + userId,
    }),
    commit,
    'userLoaded'
  ),

  saveUser: ({ commit }, user: IUser): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/users/' + user.id,
      method: "POST",
      data: {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        role: user.role,
        nif: user.nif,
        // tos_accepted: user.tos_accepted,
        // advertising_consent: user.advertising_consent,
        webhook_url: user.webhook_url,
      }
    }),
    commit,
    'userLoaded'
  ),

  downloadCsv: ({ commit, state }): any => {
    return responseHandler.handleDownload(
      () => axios({
        url: settings.endpoint + 'bo/users/csv',
        method: "GET",
        responseType: 'blob',
        params: state.usersFilters
      }),
      () => {},
      'usersCsvLoaded',
      'bo-users.csv'
    )
  },

  fetchUserCommunications:  ({ commit }, filters): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/users/' + filters.userId + '/communications',
      method: "GET",
      params: {
        ...filters,
        orderDirection: ORDER_DIRECTION.DESC
      }
    }),
    commit,
    'communicationsLoaded'
  ),

  clearUserCommunications: ({commit}): any => {
    return commit('clearUserCommunications');
  },

  fetchUserShipments: ({commit}, {userId, filters}): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/users/' + userId + '/shipments',
      method: 'GET',
      params: {
        ...filters,
        orderDirection: ORDER_DIRECTION.DESC,
        orderBy: 'id',
      }
    }),
    commit,
    'usersShipmentsLoaded'
  ),

  clearUserShipments: ({commit}): any => {
    return commit('clearUserShipments');
  },

  fetchUsers: ({ commit, state }, filters: IUserSearchFilter): any => {
    // I check if some hubs were already been loaded before by checking length. Theoretically bad, but  works as
    // edge cases don't matter (when we previously loaded an empty set, it doesn't have to be reloaded neither)
    if (state.users.length &&
      JSON.stringify(filters) === JSON.stringify(state.usersFilters)
    ) {
      return;
    }
    commit('usersFilters', filters);

    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/users/search',
        method: "GET",
        params: {
          ...filters,
          orderDirection: ORDER_DIRECTION.DESC
        },
      }),
      commit,
      'usersLoaded'
    );
  },

  clearUsers: ({ commit, state }): any => {
    return commit('clearUsers');
  },

  fetchUserSearch: ({commit}, search): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/users/search',
      method: 'GET',
      params: {search}
    }),
    commit,
    'userSearchLoaded'
  ),

  clearUserSearch: ({commit}): any => commit('userSearchLoaded', []),
};

const mutations: MutationTree<UsersState> = {
  resetUser(state, user: IUser) {
    state.user = user;
    state.communications = [];
  },
  clearUsers(state) {
    state.users = [];
  },
  userLoaded(state, payload: IUser) {
    state.user = payload;
  },
  communicationsLoaded(state, payload: IUserCommunication[]) {
    state.communications = state.communications.concat(payload);
  },

  usersShipmentsLoaded(state, payload: IShipmentDetails[]) {
    state.shipments = state.shipments.concat(payload);
  },
  clearUserShipments(state) {
    state.shipments = [];
  },

  clearUserCommunications(state) {
    state.communications = [];
  },

  usersLoaded(state, payload: IUserEssentials[]) {
    state.users = state.users.concat(payload)
  },
  usersNextPageLoaded(state, payload: IUserEssentials[]) {
    if (payload.length > 0) {
      state.nextPageToLoad = state.nextPageToLoad + 1;
    } else {
      state.nextPageToLoad = -1;
    }
  },
  usersFilters(state, payload: IUserSearchFilter) {
    // I have to make a copy to detect changes later
    state.usersFilters = Object.assign({}, payload);
  },
  userSearchLoaded(state, payload) {
    state.userSearch = payload;
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  },


};

const namespaced: boolean = true;

export const usersModule: Module<UsersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
