


















































































































import Vue from 'vue';
import Component from "vue-class-component";
import {WEB_VERSIONS} from "@/helpers/ToggleFeatureHelper";
import {setLocale} from "@/helpers/translations";
import {ELanguages} from "@/locale";
import {namespace} from "vuex-class";



interface INavItem {
  title: string;
  icon: string;
  to?: string;
  items?: INavItem[];
}
const authStore = namespace("authModule");

@Component({
  props: {
    miniVariant: Boolean
  },
})
export default class LeftNav extends Vue {
  @authStore.Getter('userData')
  private userData!: any;

  @authStore.Getter('userIsLogged')
  private userIsLogged!: boolean;

  private onLogin() {
    this.$uiBus.emit('login:show')
  }

  get navItems(): INavItem[] {
    if (this.userIsLogged) {
      // I only include these constants so they trigger a refresh
      const userData: any = this.userData;
      // @ts-ignore
      return this.transformNavItems(this.allNavItems, userData);
    }
    return [];
  }

  drawer: boolean|null = null;
  allNavItems: INavItem[] = [
    { title: 'Panel', icon: 'mdi-speedometer', to: '/', },
    { title: 'Users', icon: this.$icons.user.toString(), to: '/users' },
    { title: 'Hubs', icon: this.$icons.hub.toString(), to: '/hubs' },
    { title: 'Shipments', icon: this.$icons.shipment.toString(), to: '/shipments' },
    // { title: 'shipments.manifests.title', icon: this.$icons.manifest.toString(), to: '/manifests' },
    // { title: 'shipments.manifest.title', icon: this.$icons.manifest.toString(), to: '/manifests/current' },
    ...(this.$isVersion(WEB_VERSIONS.BARCELONA_PUERTO) ?
        [] :
        [
          { title: 'Courier', icon: 'mdi-truck-fast-outline', to: '/courier' },
          { title: 'Barcode', icon: this.$icons.barcode.toString(), to: '/barcode' },
        ]),
    { title: 'About', icon: 'mdi-progress-question', to: '/about' },
  ];

  onLogout() {
    this.$store.dispatch('authModule/logout');
    this.$uiBus.emit('login:show');
  }
  transformNavItems(allNavItems: INavItem[]) {
    let navItems: INavItem[] = [];
    allNavItems.forEach((eachNavItem: INavItem) => {
      navItems.push({
        icon: eachNavItem.icon,
        title: eachNavItem.title,
        to: eachNavItem.to,
        items: eachNavItem.items ? this.transformNavItems(eachNavItem.items) : undefined,
      });
    });
    return navItems;
  }

  protected setLanguage(language: string) {
    setLocale(language as ELanguages);
  }

  private isLang(language: string) {
    return this.$i18n.locale === language;
  }
}

