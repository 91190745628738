import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RootState} from '../RootState';
import axios from "axios";
import {settings} from "@/plugins/settings";
import {IErrors} from "@/model/IErrors";
import responseHandler from "@/plugins/responseHandling";
import Vue from "vue";
import {IManifestDetails} from "@/model/model/Shipment/IManifestDetails";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IShipmentSearchFilter} from "@/model/model/Shipment/IShipmentSearchFilter";
import {ORDER_DIRECTION} from "@/model/model/Database/DatabaseElements";

export interface IAlmoShipmentImported{
  line: number,
  code_import: number,
  id: number,
  note: string,
  bag_code: string,
  pieces: number,
  weight: number,
  secondary_code: string,
  reference: string,
  recipient_phone_number: string,
  recipient_email: string,
  recipient_first_name: string,
  recipient_last_name: string,
  recipient_address: string,
  recipient_address_2: string,
  recipient_postal_code: string,
  recipient_city: string,
  recipient_province: string,
}

const defaultShipmentFiltersState = () => ({
  hub_id: null,
  status: [],
  type: [],
  user_id: null,
});

interface ManifestState {
  manifests: IManifestDetails[];
  almoShipments: IShipmentDetails[];
  almoShipmentsFilters: IShipmentSearchFilter;
  pastManifestShipments: IShipmentDetails[];
  importResults: IAlmoShipmentImported[]
  errors: IErrors;
}

const state: ManifestState = {
  manifests: [],
  almoShipments: [],
  almoShipmentsFilters: defaultShipmentFiltersState(),
  pastManifestShipments: [],
  importResults: [],
  errors: {},
};

const getters: GetterTree<ManifestState, RootState> = {
  manifests: (state): IManifestDetails[] => state.manifests,
  almoShipments: (state): IShipmentDetails[] => state.almoShipments,
  almoShipmentsFilters: (state): IShipmentSearchFilter => state.almoShipmentsFilters,
  pastManifestShipments: (state): IShipmentDetails[] => state.pastManifestShipments,
  importResults: (state): IAlmoShipmentImported[] => state.importResults,
  errors: (state): IErrors => state.errors,
};

const actions: ActionTree<ManifestState, RootState> = {
  fetchPastManifestShipments: ({commit}, params): any => {
    return responseHandler.handle(
        () => axios({
          url: settings.endpoint + 'bo/manifests/'+params.manifestId,
          method: "GET",
          params: {
            'page': params.page,
          },
        }),
        commit,
        'pastManifestShipmentsLoaded'
    )
  },
  fetchPastManifestAllShipments: ({commit}, params): any => {
    return responseHandler.handle(
        () => axios({
          url: settings.endpoint + 'bo/manifests/'+params.manifestId+'/all',
          method: "GET",
        }),
        commit,
        'pastManifestShipmentsLoaded'
    )
  },
  fetchManifests: ({commit}): any => {
    return responseHandler.handle(
        () => axios({
          url: settings.endpoint + 'bo/manifests/',
        }),
        commit,
        'manifestsLoaded'
    )
  },
  clearManifests: ({ commit }): any => {
    commit("clearManifests")
  },
  fetchAlmoShipments: ({ commit, state }, filters: IShipmentSearchFilter): any => {
    if (state.almoShipments.length &&
      JSON.stringify(filters) === JSON.stringify(state.almoShipmentsFilters)
    ) {
      return;
    }
    commit('almoShipmentsFilters', filters);

    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/shipments/search',
        method: "GET",
        params: {
          ...filters,
          orderDirection: ORDER_DIRECTION.DESC
        },
      }),
      commit,
      'almoShipmentsLoaded'
    );
  },
  clearAlmoShipments: ({ commit }): any => {
    commit("clearAlmoShipments")
  },
  clearError: ({commit}, ref): any => {
    commit('clearError', ref);
  },

  closeManifest: ({ commit }, shipmentIds: number[]): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/manifests/close',
      method: 'POST',
      data: {shipmentIds: shipmentIds}
    }),
    commit,
    'manifestsLoaded'
  ),
  clearPastManifestShipments: ({ commit }): any => {
    commit("clearPastManifestShipments")
  },  
  almoManifestShipmentsImport: ({ commit }, {file}): any => {
    const formData = new FormData();
    formData.append('csv', file);
    commit('almoManifestShipmentsImported', {});

    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/manifests/importShipments',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }),
      commit,
      'almoManifestShipmentsImported'
    )
  },
};

const mutations: MutationTree<ManifestState> = {
  manifestsLoaded(state, payload: IManifestDetails[]) {
    state.manifests = payload
  },
  clearManifests(state) {
    state.manifests = [];
  },
  almoShipmentsLoaded(state, payload: IShipmentDetails[]) {
    state.almoShipments = payload;
  },
  clearAlmoShipments(state) {
    state.pastManifestShipments = [];
  },
  almoShipmentsFilters(state, payload: IShipmentSearchFilter) {
    // I have to make a copy to detect changes later
    state.almoShipmentsFilters = Object.assign({}, payload);
  },
  pastManifestShipmentsLoaded(state, payload: IShipmentDetails[]) {
    state.pastManifestShipments = payload;
  },
  clearPastManifestShipments(state) {
    state.pastManifestShipments = [];
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  },
  clearError(state, payload: string) {
    Vue.delete(state.errors, payload);
  },
  almoManifestShipmentsImported(state, payload) {
    state.importResults = payload;
  },

};

const namespaced: boolean = true;

export const manifestModule: Module<ManifestState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
