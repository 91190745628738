
































import Component, {mixins} from "vue-class-component";
import { LoadingMixin } from '@/plugins/mixins';
import {namespace} from "vuex-class";
import { IUserEssentials } from '@/model/model/User/IUser';
import {IUserSearchFilter} from "@/model/model/User/IUserSearchFilter";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import UserSearch from "@/components/User/UserSearch.vue";
import {BoListPanel} from "bonomo";

const usersModule = namespace("usersModule");

@Component({
  name: 'UsersIndex',
  components: {
    BoListPanel,
    UserSearch,
  }
})
export default class UsersIndex extends mixins(LoadingMixin) {

  @usersModule.Getter('users')
  private users!: IUserEssentials[]

  @usersModule.Getter('realUsers')
  private realUsers!: IUserEssentials[]

  @usersModule.Action('fetchUsers')
  private fetchUsers!: (filters: IUserSearchFilter) => Promise<any>

  @usersModule.Action('clearUsers')
  private clearUsers!: (filters: IUserSearchFilter) => Promise<any>

  private adminVisible: boolean = false;

  private headers: IDataTableHeader[] = [
    { text: this.$t('common.id') as string, value: "id", },
    { text: this.$t('users.user.first_name') as string, value: "first_name", },
    { text: this.$t('users.user.last_name') as string, value: "last_name", },
    { text: this.$t('users.user.email') as string, value: "email", },
    { text: this.$t('users.user.phone_number') as string, value: "phone_number", },
    { text: this.$t('users.user.one_eur_earnings') as string, value: "one_eur_earnings", },
    { text: this.$t('users.user.created_at') as string, value: "created_at", },
    { text: "", sortable: false, value: "controls", align: "center"},
  ];

  private get getUsers(): IUserEssentials[] {
    return this.adminVisible ? this.users : this.realUsers;
  }

  protected downloadCsv() {
    this.$store.dispatch('usersModule/downloadCsv');
  }

  private goToUser(user: IUserEssentials): void {
    this.$router.push({ name: 'userDetails', params: { userId: `${user.id}` }});
  }

  private goBack(): void {
    this.$router.push({name: "home"});
  }
}
