





































import Vue from 'vue';
import Component from "vue-class-component";
import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IErrors} from "@/model/IErrors";
import {ResponseHandler} from "@/helpers/ResponseHandler";

const authStore = namespace("authModule");

@Component({
})
export default class LoginDialog extends Vue {

  @authStore.Action('login')
  private login!: (loginInfo: { email: string, password: string}) => void;

  @authStore.Action('logout')
  private logout!: () => void;

  @authStore.Getter('errors')
  private errors!: IErrors;

  private showing: boolean = false;
  private email: string = "";
  private password: string = "";
  private loading: boolean = false;
  private ons: IOnOffHandlers = {};

  public created() {
    this.ons = this.$ons({
      'login.KO': this.onShow,
      'login.OK': this.onHide,
      'login:show': this.onShow,
    });
    // todo: we start an arbitrary api call, if we have an unauthorized, we will trigger
    // a login KO, that will emit a show for the login

  }

  public beforeDestroy() {
    this.$offs(this.ons);
  }

  private translateErrors(errors?: string[]) {
    return ResponseHandler.translateErrors(
      errors || []
    );
  }

  private onSubmit() {
    this.loading = true;
    this.login({ email: this.email, password: this.password });
  }

  private onShow() {
    this.loading = false;
    this.showing = true;
    // this will clean auth data
    this.logout();
  }

  private onHide() {
    this.$router.go(0);
  }

}
