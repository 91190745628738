var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bo-list-panel',{attrs:{"title":"shipments.manifests.title","fetch-mutation-name":"manifestsLoaded","fetch-store-action":_vm.fetchManifests,"data-table-headers":_vm.headers,"data-table-items":this.manifests,"showMoreButton":"","back-button":_vm.goBack,"item-count":""},scopedSlots:_vm._u([{key:"slot-toolbar-append",fn:function(){return [_c('v-btn',{staticClass:"ml-5",attrs:{"small":"","fab":"","color":"green"},on:{"click":_vm.goToCurrentManifest}},[_c('v-icon',{attrs:{"default":"","color":"#fff"}},[_vm._v(_vm._s(_vm.icons.manifest))])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"item.total_shipments",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.total_shipments)+" ")])]}},{key:"item.total_in_deliver",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.total_in_deliver)+" ")])]}},{key:"item.total_completed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.total_completed)+" ")])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.manifestUrl(item),"x-small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.print",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.printUrl(item),"x-small":"","fab":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.manifests.actions.print))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }