import Vue from 'vue'
import {EventEmitter} from "events";
import {IErrors} from "@/model/IErrors";
import {AxiosError, AxiosResponse} from "axios";
import {IApiError, IApiResponse} from "@/model/IApiModels";
import {translateErrors} from "@/helpers/errorsHelper";
import {downloadFileFromAxiosCall} from "@/helpers/downloadHelper";
import {i18n} from "@/helpers/translations";
import {ITranslations} from "@/translation";

export interface IErrorTranslator {
  (errors: IErrors, prefixes: string[]): IErrors;
}

export class ResponseHandler {

  private lastLoaded: {
    [key: string]: number;
  } = {};
  private readonly DEFAULT_DEBOUNCE_TIME = 120;

  constructor(
    private uiBus: EventEmitter,
    private errorTranslator: IErrorTranslator = translateErrors
  ) {}

  /**
   * @param axiosCaller this is a callback so I can launch backend call before doing axios request
   * @param commit
   * @param mutationName will perform this mutation with payload
   * @param customTranslationPrefix
   * @param callMutation - with false it will only emit the event but won't commit in store (so it's possible to use calls without return values, but still have the OK/KO events)
   */
  public handle<T>(
    axiosCaller: () => Promise<AxiosResponse<IApiResponse<T>>>,
    commit: (label: string, payload: T|IErrors) => void,
    mutationName: string,
    customTranslationPrefix: string|null = null,
    callMutation = true
  ): void {

    Vue.nextTick(() => {
      this.emit('backend.call.begin', mutationName);
    });

    commit('errors', {});

    axiosCaller()
      .then((response: AxiosResponse) => {
        if (!response.data || !response.data.data) {
          throw null;
        }
        Vue.nextTick(() => {
          this.emit('backend.call.OK', mutationName, response.data.data);
        });
        if (callMutation) {
          commit(mutationName, response.data.data);
        }
      })
      .catch((e: AxiosError|null) => {
        // always include errors.mutationName in lookups
        const customTranslationPrefixes: string[] = customTranslationPrefix
          ? [customTranslationPrefix, 'errors.' + mutationName]
          : ['errors.' + mutationName];
        const errors = this.errorTranslator(
          this.transformErrors(e),
          customTranslationPrefixes
        );
        commit('errors', errors);
        Vue.nextTick(() => {
          this.emit('backend.call.KO', mutationName, errors);
        });
      });
  }

  /**
   * DANGER: this is basically a cached handle() BUT cache keying only respects
   *  mutation name, not module name!! So cannot use same mutation name in different
   *  modules, or in other words: debounced mutation names must be unique
   */
  public debounce<T>(
    currentData: T,
    debounceSeconds: number|null,
    axiosCaller: () => Promise<AxiosResponse<IApiResponse<T>>>,
    commit: (label: string, payload: T|IErrors) => void,
    mutationName: string,
    customTranslationPrefix?: string,
    callMutation = true
  ) {

    if (currentData &&
      (this.lastLoaded[mutationName]) &&
      !this.expired(mutationName, debounceSeconds)
    ) {
      // emit OK events to trigger listeners transparently
      this.emit('backend.call.OK', mutationName);
      return;
    }

    this.lastLoaded[mutationName] = new Date().getTime();

    return this.handle(
      axiosCaller,
      commit,
      mutationName,
      customTranslationPrefix,
      callMutation
    );

  }

  /**
   * this can be used to force reloading cached (debounced) data
   * BUT normally it should not be needed as mutating actions should
   *  receive updated data (eg. Zones does not need it, as creating
   *  a zone will update zone list)
   */
  public clearDebounce(mutationName: string|null = null) {
    if (mutationName === null) {
      this.lastLoaded = {};
    }
    else if (this.lastLoaded[mutationName]) {
      delete this.lastLoaded[mutationName];
    }
  }

  public handleDownload<T>(
    // axiosCaller: () => Promise<any>,
    axiosCaller: () => Promise<AxiosResponse<IApiResponse<T>>>,
    commit: (label: string, payload: any)=>any,
    mutationName: string,
    defaultFname: string = 'download.csv'
  ) {
    this.handle(
      () => axiosCaller()
        .then((res: AxiosResponse<any>): any => {
          const header = res.headers['content-disposition'];
          const match = header.match(/filename="(.+)"$/);
          const fname = match ? match[1] : defaultFname;
          downloadFileFromAxiosCall(res, fname);
          return ({
            data: {
              data: 'successful_download',
            }
          })
        })
      ,
      commit,
      mutationName
    )
  }

  private emit<T>(label: string, mutationName: string, data?: T) {
    // first emit: USE ONLY for general handling of BEGIN, OK, KO events of any mutation
    this.uiBus.emit(label, mutationName, data);
    // use these events in components to listen to specific event, eg. data loaded
    // data and errors must be accessed from store so it is not emitted
    this.uiBus.emit(label + '.' + mutationName, data);
  }

  private expired(index: string, debounceSeconds: number|null) {
    const elapsed = new Date().getTime() - this.lastLoaded[index];
    return elapsed >= (debounceSeconds || this.DEFAULT_DEBOUNCE_TIME) * 1000;
  }

  /**
   * transforms error messages from plain array to object eg
   * [{title: 'error title', ref: 'fieldName'}, {title: 'error title 2', ref: 'fieldName'}]
   * to
   * {fieldName: ['error title 1', 'error title 2']}
   */
  // private transformErrors(response: any): IErrors {
  // public for now as authModule uses it. @todo remove direct use
  public transformErrors(response: any): IErrors {
    const errors: IErrors = {};
    const axiosResponse: AxiosResponse = response.response;
    try {
      axiosResponse.data.errors.forEach((eachError: IApiError) => {
        const key = eachError.ref ? eachError.ref : '_';
        errors[key] = errors[key] || [];
        errors[key].push(eachError.title);
      });
    } catch (e) {
      if (!errors['_']) {
        errors['_'] = [];
      }
      errors['_'].push(axiosResponse ? axiosResponse.status.toString() : '');
    }
    return errors;
  }

  /**
   * @deprecated only here until we clean up usages
   * @param errors
   */
  public static flattenErrors(errors: any): string[] {
    let ret: string[] = [];
    Object.keys(errors).forEach(eachKey => {
      ret = ret.concat(errors[eachKey]);
    });
    return ret;
  }

  /**
   * @deprecated only here until we clean up usages
   * @param errors
   * @param contexts - send parts of translations, lookup will happen in order for first match
   */
  public static translateErrors(errors: string[], contexts: ITranslations[] = []) {
    contexts.push(i18n.t('errors.general') as ITranslations);
    return errors.map(eachError => {
      const context = contexts.find(eachContext => !!(eachContext[eachError]));
      return context ? context[eachError] : i18n.t('errors.general.unknown');
    });
  }

}
