import axios, {AxiosError} from "axios";
import {uiBus} from "@/plugins/uiBus";

function httpErrorHandler(error: AxiosError) {
  if (error.response && error.response.status === 401) {
    uiBus.emit('login.KO');
  }
  throw error;
}

axios.interceptors.response.use(
  response => response,
  httpErrorHandler
);
