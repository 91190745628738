import Vue from 'vue';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import { RootState } from '../RootState';
import axios, {AxiosError} from "axios";
import {settings} from "@/plugins/settings";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IErrors} from "@/model/IErrors";
import responseHandler from "@/plugins/responseHandling";

interface CourierState {
  shipments: IShipmentDetails[];
  shipmentsInRoute: IShipmentDetails[],
  errors: IErrors,
}

const state: CourierState = {
  shipments: [],
  shipmentsInRoute: [],
  errors: {},
};

const getters: GetterTree<CourierState, RootState> = {
  shipments: (state): IShipmentDetails[] => state.shipments,
  shipmentsInRoute: (state): IShipmentDetails[] => state.shipmentsInRoute,
  errors: (state): IErrors => state.errors,
};

const actions: ActionTree<CourierState, RootState> = {
  fetchShipmentsValidForDeliver({commit}) {
    commit("courierShipments", []);
    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/shipments/validForDeliver',
        method: 'GET',
      }),
      commit,
      'courierShipments',
    );
  },
  fetchShipmentsInRoute({commit}) {
    // @todo do caching here, a 15-20sec would be fine to eliminate eg many requests
    //  to server when user just clicks around, not finding something
    commit('courierShipmentsInRoute', []);
    return responseHandler.handle(
      () => axios({
        url: settings.endpoint + 'bo/shipments/inRoute',
      }),
      commit,
      'courierShipmentsInRoute',
    );
  },
  addToRoute: ({commit}, shipmentId: number): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId + '/addToRoute',
      method: 'POST',
    }),
    commit,
    'courierAddToRoute',
  ),

  collectShipment: ({ commit }, {shipmentId, bagCode}): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId + '/collect',
      method: "POST",
      data: {
        bag_code: bagCode,
      }
    }),
    commit,
    'collectShipment'
  ),

  verifyCollectShipment:  ({ commit }, {shipmentId, bagCode, addToRoute}): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId + '/verifyCollect',
      method: "POST",
      data: {
        bag_code: bagCode,
        add_to_route: addToRoute,
      }
    }),
    commit,
    'verifyCollectShipment'
  ),

  deliverShipment: ({ commit }, shipmentId): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId + '/deliver',
      method: 'POST',
    }),
    commit,
    'deliverShipment',
  ),

  verifyDeliverShipment: ({ commit }, shipmentId): any => responseHandler.handle(
    () => axios({
      url: settings.endpoint + 'bo/shipments/' + shipmentId + '/verifyDeliver',
      method: 'POST',
    }),
    commit,
    'verifyDeliverShipment',
  ),

  removeShipment: ({ commit }, shipmentId): any => {
    commit('removeCourierShipment', shipmentId);
  },
  removeShipmentInRoute: ({ commit }, shipmentId): any => {
    commit('removeCourierShipmentInRoute', shipmentId);
  },
  currentHub: ({commit}, currentHub: number): any => {
    commit('currentHub', currentHub);
  },
  errors: ({commit}, errors: IErrors): any => {
    commit('errors', errors);
  }
};

const mutations: MutationTree<CourierState> = {
  courierShipments(state, payload: IShipmentDetails[]) {
    state.shipments = payload;
  },
  courierShipmentsInRoute(state, payload: IShipmentDetails[]) {
    state.shipmentsInRoute = payload;
  },
  courierAddToRoute(state, payload: IShipmentDetails) {
    state.shipmentsInRoute.push(payload);
  },
  collectShipment(state, payload: IShipmentDetails[]) {
    state.shipmentsInRoute = payload;
  },
  verifyCollectShipment(state, payload: IShipmentDetails[]) {
    state.shipmentsInRoute = payload;
  },
  deliverShipment() {},
  verifyDeliverShipment() {},
  removeCourierShipment(state, payload: number) {
    state.shipments = state.shipments.filter(
      (eachShipment: IShipmentDetails) => eachShipment.id != payload
    );
  },
  removeCourierShipmentInRoute(state, payload: number) {
    state.shipmentsInRoute = state.shipmentsInRoute.filter(
      (eachShipment: IShipmentDetails) => eachShipment.id != payload
    );
  },
  errors(state, payload: IErrors) {
    state.errors = payload;
  }
};

const namespaced: boolean = true;

export const courierModule: Module<CourierState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
