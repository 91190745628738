








































import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {namespace} from "vuex-class";
import {IHub} from "@/model/model/Hub/IHub";
import {ValidForDeliver} from "@/model/specification/Shipment/ValidForDeliver";
import {ShipmentHelper} from "@/helpers/ShipmentHelper";

const hubModule = namespace("hubModule");

@Component({})
export default class ShipmentsToRoute extends Vue {

  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  @Prop()
  shipments!: IShipmentDetails[];
  @Prop()
  loading!: boolean;

  private headers: IDataTableHeader[] = [
    { text: this.$t('shipments.shipment.id.label') as string, value: "id", sortable: false, },
    // { text: this.$t('shipments.shipment.type.label')  as string, value: "type", },
    { text: this.$t('shipments.shipment.status.label')  as string, value: "status", sortable: false, },
    { text: this.$t('shipments.shipment.bag_code.label')  as string, value: "bag_code", sortable: false, },
    { text: this.$t('hubs.hub.label')  as string, value: "hub", sortable: false, },
    { text: this.$t('hubs.hub.labelFromHub')  as string, value: "from_hub", sortable: false, },
    { text: this.$t('hubs.hub.labelToHub')  as string, value: "to_hub", sortable: false, },
    // { text: this.$t('shipments.shipment.from_user.label')  as string, value: "from_user_id", },
    // { text: this.$t('shipments.shipment.to_user.label')  as string, value: "to_user_id", },
    { text: "", sortable: false, value: "controls", align: "center",},
  ];

  private shipmentsSorter(items: IShipmentDetails[]): IShipmentDetails[] {
    return ShipmentHelper.shipmentsSorter(items, this.hubById);
  }

  private validForDeliver = ValidForDeliver.isSatisfiedBy;

  private onAddToRoute(shipment: IShipmentDetails) {
    this.$emit('addToRoute', shipment);
    this.$emit('action', shipment);
  }

  private onDeliver(shipment: IShipmentDetails) {
    this.$emit('deliver', shipment);
    this.$emit('action', shipment);
  }

}
