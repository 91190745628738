var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bo-list-panel',{attrs:{"title":_vm.$t('shipments.manifest.past.title') + _vm.manifestId,"fetch-mutation-name":"pastManifestShipmentsLoaded","fetch-store-action":_vm.fetchStoreAction,"data-table-headers":_vm.headers,"data-table-items":_vm.getPastManifestShipments,"remoteFilterFields":['manifestId'],"extraFilters":{'manifestId': _vm.manifestId},"manifestId":_vm.manifestId,"back-button":_vm.goBack,"showMoreButton":"","item-count":""},on:{"update:manifestId":function($event){_vm.manifestId=$event},"update:manifest-id":function($event){_vm.manifestId=$event}},scopedSlots:_vm._u([{key:"slot-toolbar-append",fn:function(){return [_c('v-btn',{staticClass:"ml-5",attrs:{"small":"","fab":"","color":"green"},on:{"click":_vm.goToPrint}},[_c('v-icon',{attrs:{"default":"","color":"#fff"}},[_vm._v(_vm._s(_vm.icons.manifests.actions.print))])],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.type.values.' + item.type),"color":_vm.shipmentIsDeleted(item) ? 'red lighten-4' : ''}},[_vm._v(" "+_vm._s(_vm.icons['shipmentType' + item.type])+" ")]),_c('span',{staticClass:"hidden-sm-and-down",class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.type.values.' + item.type))+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.shipmentIsDeleted(item))?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.20'),"color":"red lighten-4"}},[_vm._v(_vm._s(_vm.icons.deleted))]):(item.status <= 30)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.20'),"color":"orange"}},[_vm._v(_vm._s(_vm.icons.awaitingDropoff))]):(item.status <= 40)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.30'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.collect))]):(item.status < 70)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.50'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.processing))]):(item.status < 80)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.70'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.deliver))]):(item.status < 90)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.80'),"color":"green"}},[_vm._v(_vm._s(_vm.icons.awaitingPickup))]):_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.90'),"color":"green"}},[_vm._v("mdi-check-circle")]),(_vm.shipmentIsDeleted(item))?_c('span',{class:[
        'hidden-sm-and-down',
        {'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}
    ]},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.status.values.' + (_vm.shipmentIsDeleted(item) ? 'DELETED' : item.status )))+" ")]):_c('span',{},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.status.values.' + item.status))+" ")])]}},{key:"item.hub",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[(item.from_cell && _vm.shipmentIsInAwaitingCollectOrBefore(item))?_c('span',{attrs:{"title":'From: ' + _vm.hubName(item.from_cell) + '\nTo: ' + (item.to_cell?_vm.hubName(item.to_cell):'-')}},[_vm._v(" "+_vm._s(_vm.hubName(item.from_cell))+" ")]):(_vm.shipmentIsNewAlmo(item))?_c('span',[_c('strong',[_vm._v("-")])]):(_vm.shipmentIsInAwaitingCollectOrBefore(item) && !_vm.shipmentIsDeleted(item))?_c('span',[_c('strong',{staticStyle:{"color":"white","background-color":"red","padding":"0 10px"}},[_vm._v("!!!ERR!!!")])]):(item.to_cell && _vm.shipmentIsAfterAwaitingCollect(item))?_c('span',{attrs:{"title":'From: ' + _vm.hubName(item.to_cell) + '\nTo: ' + (item.to_cell?_vm.hubName(item.to_cell):'-')}},[_vm._v(" "+_vm._s(_vm.hubName(item.to_cell))+" ")]):_c('span',[_vm._v(" - ")])])]}},{key:"item.from_user_id",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(_vm._f("userNameOrPhone")(item.from_user))+" ")])]}},{key:"item.to_user_id",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(_vm._f("userNameOrPhone")(item.to_user))+" ")])]}},{key:"item.controls",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.shipmentUrl(item),"x-small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{'red--text text--lighten-4': _vm.shipmentIsDeleted(item)}},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }