














































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";

import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IHub} from "@/model/model/Hub/IHub";

const hubModule = namespace("hubModule");

@Component({
  name: 'FinalizeShipmentDialog',
  components: {
  }
})
export default class FinalizeShipmentDialog extends Vue {
  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  @Prop({default: false})
  private showDialog!: boolean;

  @Prop()
  private shipment!: IShipmentDetails;

  private closeDialog() {
    this.$emit("close:dialog");
  }

  private finalizeShipping() {
    this.$store.dispatch("shipmentModule/finalizeShipment", this.shipment.id);
    this.$store.dispatch("shipmentModule/clearShipments");
    this.$emit("close:dialog");
  }

}
