// emit standard 'KO' alert toast on backend KO
// BUT we can skip any mutation by name and handle it manually
import {uiBus} from '@/plugins/uiBus';
import {ResponseHandler} from '@/helpers/ResponseHandler';

const responseHandler = new ResponseHandler(uiBus);

// @todo move this to ResponseHandler
// make sure we don't carry over debounced data as it would prevent reloading data which resulted in a 401
uiBus.on('backend.call.KO', () => {
  responseHandler.clearDebounce();
});

uiBus.on('backend.call.KO', (mutationName: string) => {
  // trying not to use this feature
  // const silencedMutationNames: string[] = [];
  // if (silencedMutationNames.indexOf(mutationName) === -1) {
  //   uiBus.emit('alert', 'KO');
  // }
  uiBus.emit('alert', 'KO');
});
// @todo add handling "OK" here

export default responseHandler;
