














































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  name: 'CloseManifestDialog',
  components: {
  }
})
export default class CloseManifestDialog extends Vue {

  @Prop({default: false})
  private showDialog!: boolean;

  @Prop()
  private shipmentIds!: number[];

  private closeDialog() {
    this.$emit("close:dialog");
  }

  private closeManifest() {
    this.$store.dispatch("manifestModule/closeManifest", this.shipmentIds);
    this.$store.dispatch("manifestModule/clearManifests");
    this.$emit("close:dialog");
  }

}
