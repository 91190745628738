import {LocaleMessages} from "vue-i18n";
import en from './en';
import es from './es';

export enum ELanguages {
  en = 'en',
  es = 'es',
  ca = 'ca', // todo
  pt = 'pt', // todo
}

export const messages: LocaleMessages = {
  en,
  es,
  ca: es,
  pt: es,
};
