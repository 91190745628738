













import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: 'GeneralErrorWriter',
  components: {
  }
})
export default class ErrorMessages extends Vue {
  @Prop({default: () => []})
  private errorMessages!: string[];
}

