































































































import Component, {mixins} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IHub} from "@/model/model/Hub/IHub";
import {LoadingMixin} from "@/plugins/mixins";
import {IAddToRoute} from "@/model/specification/Courier/PopUps";

const hubModule = namespace("hubModule");


@Component({
  name: 'DialogVerifyOpen',
  components: {
  }
})
export default class DialogVerifyOpen extends mixins(LoadingMixin)  {
  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  @Prop({default: false})
  private showDialog!: boolean;

  @Prop({default: false})
  private askAddToRoute!: boolean;

  @Prop()
  private shipment!: IShipmentDetails;

  private get shouldWeAddToTheRoute(): boolean {
    return this.addToRoute &&  this.askAddToRoute;
  }

  private addToRoute: boolean = true;

  private closeDialog() {
    this.$emit("close:dialog");
  }

  private openAgain() {
    this.$emit("openAgain:dialog");
  }

  private verify() {
    this.$emit("verify:dialog", { addToRoute: this.shouldWeAddToTheRoute } as IAddToRoute);
  }
}


