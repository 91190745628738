




































































































































































































import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IErrors} from "@/model/IErrors";
import {IUser} from "@/model/model/User/IUser";
import {namespace} from "vuex-class";
import {IHub} from "@/model/model/Hub/IHub";
import {HubHelper} from "@/helpers/HubHelper";

const hubModule = namespace("hubModule");

@Component({
  name: 'UserForm'
})
export default class UserForm extends Vue {
  @hubModule.Getter('getUserDefaultHub')
  private getUserDefaultHub!: (user: IUser) => IHub|null;

  @Prop()
  data!: IUser;
  @Prop()
  errors!: IErrors;
  @Prop()
  private disabled!: boolean;
  private valid: boolean = false;

  get userHubInfo(): string {
    const hub = this.getUserDefaultHub(this.data);
    return HubHelper.getUserDefaultHubInfo(hub);
  }

  get userDefaultHubId(): number {
    const hub: IHub|null = this.getUserDefaultHub(this.data);
    return hub && hub.id ?  hub.id : 0;
  }

  protected get canHaveWebhook(): boolean {
    // @TODO temp solution until we have the new role in backend
    return this.data && this.data.role > 20;
  }

  onInput() {
    if (this.$refs.form) {
      (this.$refs.form as any).validate();
    }
    this.$emit("valid", this.valid);
  }

  onChange(fieldName: string) {
    this.$emit("change", fieldName);
  }

  goToHub() {

  }
}
