import Vue from 'vue';
import {IUser} from "@/model/model/User/IUser";

Vue.filter('userNameOrPhone', (user?: IUser): string => {
  if (!user) {
    return '';
  }
  const name = (user.first_name + ' ' + user.last_name).trim();
  return name.length ? name : user.phone_number;
});
