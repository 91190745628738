








































import Vue from 'vue';
import Component from "vue-class-component";
import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import CollectDeliverCounter from "@/components/Courier/Widget/CollectDeliverCounter.vue";
import ShipmentsToRoute from "@/components/Courier/Screens/ShipmentsToRoute.vue";

const courierModule = namespace("courierModule");

@Component({
  components: {
    CollectDeliverCounter,
    ShipmentsToRoute,
  }
})
export default class Courier extends Vue {

  @courierModule.Getter('shipments')
  private shipments!: IShipmentDetails[];
  @courierModule.Getter('shipmentsInRoute')
  private shipmentsInRoute!: IShipmentDetails[];

  private loading: boolean = false;
  private ons!: IOnOffHandlers;
  private submittedShipment!: IShipmentDetails;

  created() {
    this.ons = this.$ons({
      'backend.call.begin.courierShipments': this.onLoadBegin,
      'backend.call.OK.courierShipments': this.onLoadOK,
      'backend.call.begin.courierAddToRoute': this.onLoadBegin,
      'backend.call.OK.courierAddToRoute': this.onAddToRouteOK,
      'backend.call.KO.courierAddToRoute': this.onAddToRouteKO,
    });
    this.$store.dispatch("hubModule/getHubs");
    this.$store.dispatch("courierModule/fetchShipmentsInRoute");
    this.$store.dispatch("courierModule/fetchShipmentsValidForDeliver");
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  onLoadBegin() {
    this.loading = true;
  }
  onLoadOK() {
    this.loading = false;
  }

  private onNext() {
    this.$router.push({name: 'courierRouteHubs'});
  }

  private onAddToRoute(shipment: IShipmentDetails) {
    if (this.submittedShipment) {
      return;
    }
    this.submittedShipment = shipment;
    this.$store.dispatch('courierModule/addToRoute', shipment.id);
  }

  private onAddToRouteOK() {
    if (!this.submittedShipment) {
      this.$uiBus.emit('alert', this.$t('errors.general.wtf'));
      this.$router.go(0);
    }
    this.$store.dispatch('courierModule/removeShipment', this.submittedShipment.id);
    delete this.submittedShipment;
    this.loading = false;
    this.$uiBus.emit('success', this.$t('courier.atHub.addedToRoute'));
  }

  private onAddToRouteKO() {
    if (!this.submittedShipment) {
      this.$uiBus.emit('alert', this.$t('errors.general.wtf'));
    }
    delete this.submittedShipment;
    this.loading = false;
    this.$uiBus.emit('alert', this.$t('errors.general.unknown'));
  }


  onBack() {
    this.$router.push({name: "courier"});
  }


}
