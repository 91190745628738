import Cookies, {CookieAttributes} from "js-cookie";

export class CookieHandler {
  private readonly domain: string;
  private readonly path: string;
  private readonly expireTimes: number | Date;
  private readonly secure: boolean;


  constructor(expireTimes: number | Date = 58, domain: string = '', path: string = '', secure: boolean = true) {
    this.domain = domain;
    this.path = path;
    this.secure = secure;
    this.expireTimes = expireTimes;
  }

  public set(cookieName: string, cookieValue: string) {
    Cookies.set(cookieName, cookieValue, this.options());
  }

  public get(cookieName: string): string {
    const result = Cookies.get(cookieName);
    return result ? result : '';
  }

  public remove(cookieName: string) {
    Cookies.remove(cookieName, this.options());
  }

  private options(): CookieAttributes {
    return {
      secure: this.secure,
      expires: this.expireTimes,
      domain: this.domain !== '' ? this.domain : undefined,
      path: this.path !== '' ? this.path : undefined,
    };
  }
}

