


















































































import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import StatusSlider from "@/components/Shipments/Widget/StatusSlider.vue";
import {IHub} from "@/model/model/Hub/IHub";
import {namespace} from "vuex-class";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import IdleSlider from "@/components/Shipments/Widget/IdleSlider.vue";
import HubCardInner from "@/components/Shipments/Data/HubCardInner.vue";
import UserCardInner from "@/components/Shipments/Data/UserCardInner.vue";
import {ShipmentHelper} from "@/helpers/ShipmentHelper";

const hubModule = namespace("hubModule");

@Component({
  components: {
    StatusSlider,
    IdleSlider,
    HubCardInner,
    UserCardInner,
  }
})
export default class ShipmentData extends Vue {

  @Prop()
  data!: IShipmentDetails;

  @hubModule.Getter('hubs')
  private hubs!: IHub[];

  private get shipmentIsDeleted (): boolean {
    return ShipmentHelper.shipmentIsDeleted(this.data);
  };
}
