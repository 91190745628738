





























































import Component from "vue-class-component";
import Vue from 'vue';

import UserForm from "@/components/User/UserForm.vue";
import {IUserCommunication} from "@/model/model/UserCommunications/IUserCommunications";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {DateHelper} from "@/helpers/DateHelper";
import {COMMUNICATION_TYPES} from '@/model/model/UserCommunications/IUserCommunications';

@Component({
  name: 'CommunicationsDialog',
  components: {
    UserForm
  }
})
export default class CommunicationsDialog extends Vue {

  private value: IUserCommunication = {} as IUserCommunication;
  private innerShowDialog: boolean = false;
  private subscribes: IOnOffHandlers = {};

  public created(){
    this.subscribes = this.$ons({
      'communicationsDialog:show': this.onShow,
    });
  }

  public beforeDestroy() {
    this.$offs(this.subscribes);
  }

  get formattedDate(): string {
    return DateHelper.formatDate(this.value.created_at);
  }

  protected get isEmail(): boolean {
    return this.value.type == COMMUNICATION_TYPES.EMAIL;
  }

  protected get isPush(): boolean {
    return this.value.type == COMMUNICATION_TYPES.PUSH;
  }

  protected onClose() {
    this.innerShowDialog = false;
  }

  private onShow(communication: IUserCommunication) {
    this.value = communication;
    this.innerShowDialog = true;
  }

}
