const pipe = (...fns: any[]) => (x: any) => fns.reduce((v, f) => f(v), x);
const getFirstElement = (myList: any[]) => myList[0];

const HTTPS = 'https:';

const splitAndCleanUrl = (myUrl: string): string[] => myUrl.split('/')
  .filter(el => el !== '');


const dropHttp = (xs: any[]) => {
  const n: number = 2;
  return xs.slice(Math.max(0, n), Infinity);
}

const splitWhenHash = (list: any[]) => {
  const pred = (el: any) => el === '#';
  let idx = 0;
  let len = list.length;
  let prefix = [];

  while (idx < len && !pred(list[idx])) {
    prefix.push(list[idx]);
    idx += 1;
  }

  return [prefix, Array.prototype.slice.call(list, idx)];
}

const clearUrl = (myUrl: string): string[] => {
  return pipe(
    splitAndCleanUrl,
    dropHttp,
    splitWhenHash,
    getFirstElement
  )(myUrl)
};

export const domainFolder = (): string => {
  const myUrlList: string[] = clearUrl(window.location.href);
  let myFolder: string = '';

  if (myUrlList.length > 0) {
    myUrlList.forEach((value: string) => myFolder = `${myFolder}/${value}`);
  }

  return myFolder;
};

export const isSecure = (): boolean => {
  return window.location.protocol.toLowerCase() === HTTPS;
};

export const generateCDUrl = (cdBaseUrl: string, publicId: string): string => `${cdBaseUrl}/#/hub/${publicId}`;

