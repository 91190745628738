












import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import BoDialog from "@/components/Bo/BoDialog.vue";
import {ICell} from "@/model/model/Hub/ICell";
import Vue from "vue";

const hubModule = namespace("hubModule");

@Component({
  name: 'OpenCellDialog',
  components: {
    BoDialog,
  }
})
export default class OpenCellDialog extends Vue {

  @Prop()
  private value!: boolean;

  @Prop()
  private cell!: ICell;

  @hubModule.Action('openCell')
  private openCell!: (cellId: number) => void;

  private onSubmit() {
    this.openCell(this.cell.id);
  }

}


