






































































import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import {Watch} from "vue-property-decorator";
import {IErrors} from "@/model/IErrors";
import {LoadingMixin} from "@/plugins/mixins";
import {IUser} from "@/model/model/User/IUser";
import UserForm from "@/components/User/UserForm.vue";
import UserCommunications from "@/components/Communications/UserCommunications.vue";
import UserShipments from "@/views/Users/UserShipments.vue";

const MODES = {
  view: 0,
  edit: 1,
  create: 2,
};

const EMPTY_USER: IUser = {
  id: 0,
  first_name: "",
  last_name: "",
  email: "",
  role: 0,
  delivery_token: "",
  co2_saved: 0,
  one_eur_earnings: 0,
  default_hub_id: 0,
  tos_accepted: false,
  advertising_consent: false,
  nif: "",
  phone_number: "",
  created_at: "",
  updated_at: "",
  webhook_url: "",
};

const usersModule = namespace("usersModule");

@Component({
  components: {
    UserShipments,
    UserForm,
    UserCommunications,
  }
})
export default class UserDetails extends mixins(LoadingMixin) {

  @usersModule.Getter('user')
  private user!: IUser;

  @usersModule.Getter('errors')
  private errors!: IErrors;

  @usersModule.Action('fetchUser')
  private fetchUser!: (userId: any) => Promise<any>;

  @usersModule.Action('saveUser')
  private saveUser!: (user: IUser) => Promise<any>;

  @usersModule.Action('resetUser')
  private resetUser!: (user: IUser) => Promise<any>;

  @usersModule.Action('resetErrors')
  private resetErrors!: () => Promise<any>;

  private MODES: any = MODES;
  private formValid: boolean = false;
  private mode: number = MODES.view;

  private userId!: any;

  get formDisabled(): boolean {
    return this.mode !== MODES.create && this.mode !== MODES.edit;
  }

  @Watch("$route")
  onRouteChanged() {
    this.userId = this.$route.params.userId;
    if (this.userId) {
      this.fetchUser(this.userId);
    }
    else {
      // I need an empty hub so I can pre-set status (or any field)
      this.resetUser(Object.assign({}, EMPTY_USER));
      this.resetErrors();
      this.loading = false;
      this.mode = MODES.create;
    }
  }

  created() {
    // this.ons = this.$ons({
    // }, this.ons);
    this.onRouteChanged();
    this.$store.dispatch("hubModule/fetchHubs");
  }

  onBack() {
    this.$router.push({name: "users"});
  }

  saveForm() {
    this.mode = MODES.view;
    this.saveUser(this.user);
  }

}
