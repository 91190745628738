




















































































































import Vue from 'vue';
import Component, {mixins} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";

import {icons} from "@/plugins/icons.ts";
import { LoadingMixin } from '@/plugins/mixins';
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IHub} from "@/model/model/Hub/IHub";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IErrors} from "@/model/IErrors";
import {ShipmentHelper} from "@/helpers/ShipmentHelper";
import {ResponseHandler} from "@/helpers/ResponseHandler";

import CollectDeliverCounter from "@/components/Courier/Widget/CollectDeliverCounter.vue";
import ShipmentsToRoute from "@/components/Courier/Screens/ShipmentsToRoute.vue";
import VAutoscan from "@/components/Shipments/Widget/VAutoscan.vue";
import CollectLogic from "@/views/Courier/CollectLogic.vue"
import DeliverLogic from "@/views/Courier/DeliverLogic.vue"
import {ITranslations} from "@/translation";


const hubModule = namespace("hubModule");
const courierModule = namespace("courierModule");


@Component({
  components: {
    CollectDeliverCounter,
    ShipmentsToRoute,
    VAutoscan,
    CollectLogic,
    DeliverLogic,
  }
})
export default class AtHub extends mixins(LoadingMixin) {

  @Prop()
  private hubId!: number; // in the end, because it comes from a parameter in the url, it is a string

  @courierModule.Getter('shipmentsInRoute')
  private shipments!: IShipmentDetails[];
  @courierModule.Getter('shipmentsInRoute')
  private shipmentsInRoute!: IShipmentDetails[];
  @courierModule.Getter('errors')
  private errors!: IErrors;
  @hubModule.Getter('hubsLoaded')
  private hubsLoaded!: boolean;
  @hubModule.Getter('hubs')
  private hubsPath!: IHub[]; // show the path of hubs the courier has to go
  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  protected ons!: IOnOffHandlers;

  private icons = icons;

  private showAutoscan: boolean = false;
  private autoscanErrors: any = [];
  private autoscanLoading: boolean = false;
  private barcodeInput: string = '';

  get hub(): IHub {
    if (!this.hubsLoaded) {
      return {} as IHub;
    }
    return this.hubById(this.hubId);
  }

  get shipmentsAtHub(): IShipmentDetails[] {
    const shipments = this.shipments;
    const hubId = this.hubId;
    return ShipmentHelper.shipmentsByHub(shipments || [], hubId);
  }
  get shipmentsToCollectCnt(): number {
    const shipments = this.shipmentsAtHub;
    return ShipmentHelper.shipmentsToCollect(shipments || []).length;
  }
  get shipmentsToDeliverCnt(): number {
    const shipments = this.shipmentsAtHub;
    return ShipmentHelper.shipmentsToDeliver(shipments || []).length;
  }

  created() {
    this.$store.dispatch("hubModule/getHubs");
    this.$store.dispatch("courierModule/fetchShipmentsInRoute");
  }

  /**
   * reset the scan state, so we can use it again
   */
  private resetScanState(errors: string[] = []) {
    Vue.set(this, 'autoscanErrors', errors);
    this.autoscanLoading = false;
    this.barcodeInput  = '';
  }

  /**
   * shows or hide the bar code scan
   */
  private toggleAutoscan() {
    this.showAutoscan = !this.showAutoscan;
  }

  /**
   * goes to the root Hub site, belongs to the "next" button
   */
  private onNext() {
    this.$router.replace({name: 'courierRouteHubs'});
  }

  /**
   * Go back to the Route Hubs, use d in "arrow" back button
   */
  private onBack() {
    this.$router.replace({name: 'courierRouteHubs'});
  }

  /**
   * Will be called when we start an endpoint request, deliver or commit
   */
  private onAutoscanLoading() {
    Vue.set(this, 'autoscanErrors', []);
    this.autoscanLoading = true;
  }

  /**
   * actions to do when we have a code data
   */
  private onAutoscanInput(input: string) {
    if (!this.autoscanLoading) {
      // If we are scanning a code, we will wait

      const shipment = this.shipmentsAtHub.find(eachShipment => eachShipment.bag_code == input);

      // if the code belongs to the hub's shipment, we are doing a deliver, while that if it doesnt exist, we are doing a collect
      shipment ? this.$uiBus.emit('atHub.courierLogic.deliver', shipment.id)
               : this.$uiBus.emit('atHub.courierLogic.collect', input)

    }
  }

  /**
   * when the code is written in the input instead of scanned
   * @private
   */
  private onSubmitBarcode() {
    this.onAutoscanInput(this.barcodeInput);
  }

  private onDeliverOk() {
    this.$uiBus.emit('success', this.$t('courier.atHub.delivery.success'));
    this.onActionOk();
  }

  private onCollectOk(){
    this.$uiBus.emit('success', this.$t('courier.atHub.collect.success'));
    this.onActionOk();
  }

  /**
   * finish the operation and shows a success message
   */
  private onActionOk() {
    this.$uiBus.emit('clearAutoscan');

    // the information has been sent to deliver or collect, so we reset the scan to be used. While loading
    // the scan will show the loading bar and will not be used, and after that, it will be blocked by the
    // verify pop up.
    this.resetScanState();
  }

  /**
   * An api call from delivery or collect has failed. This will show the errors. Will be called from collect,
   * verifyCollect, deliver or verifyDeliver
   * @private
   */
  private onActionKO() {
    // scan stuff, we might not need to remove this
    const errorMessages = ResponseHandler.translateErrors(
        ResponseHandler.flattenErrors(this.errors),
        [ this.$t('courier.atHub.errors') as ITranslations ]
    );

    this.$uiBus.emit('alert', this.$t('errors.general.unknown'));

    // the information has been sent to deliver or collect, so we reset the scan to be used. While loading
    // the scan will show the loading bar and will not be used, and after that, it will be blocked by the
    // verify pop up. We will add the error messages too because it was an endpoint KO
    this.resetScanState(errorMessages);
  }

}
