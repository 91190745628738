












































































import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import VueHtml2pdf from 'vue-html2pdf'
import {IHub} from "@/model/model/Hub/IHub";
import {Watch} from "vue-property-decorator";
import HubForm from "@/components/Hubs/HubForm.vue";
import CellsInfo from "@/components/Hubs/Cells/CellsInfo.vue";
import {IErrors} from "@/model/IErrors";
import {LoadingMixin} from "@/plugins/mixins";
import CourierDirectQrCard from "@/components/Hubs/CourierDirectQRCard.vue";

const hubModule = namespace("hubModule");

const MODES = {
  view: 0,
  edit: 1,
  create: 2,
};

const EMPTY_HUB = {
  id: '',
  hash: '',
  name: '',
  description: '',
  address: '',
  geo_lat: '',
  geo_long: '',
  status: '10',
};

@Component({
  name: 'HubDetails',
  components: {
    HubForm,
    CellsInfo,
    VueHtml2pdf,
    CourierDirectQrCard,
  }
})
export default class HubDetails extends mixins(LoadingMixin) {

  @hubModule.Getter('hub')
  private hub!: IHub;
  @hubModule.Getter('errors')
  private errors!: IErrors;

  private MODES: any = MODES;
  private formValid: boolean = false;
  private mode: number = MODES.view;

  private hubId!: any;

  @Watch("$route")
  onRouteChanged() {
    this.hubId = this.$route.params.hubId;
    if (this.hubId) {
      this.$store.dispatch("hubModule/getHub", this.hubId);
    }
    else {
      // I need an empty hub so I can pre-set status (or any field)
      this.$store.dispatch('hubModule/resetHub', Object.assign({}, EMPTY_HUB));
      this.$store.dispatch('hubModule/resetErrors');
      this.loading = false;
      this.mode = MODES.create;
    }
  }

  created() {
    this.ons = this.$ons({
      'backend.call.OK.hubCreated': this.onCreatedOK,
      'backend.call.OK.openCell': this.onOpenCellOk,
      'backend.call.KO.openCell': this.onOpenCellKO,
    }, this.ons);
    this.onRouteChanged();
  }

  onOpenCellOk() {
    this.$uiBus.emit('success', this.$t('hubs.cell.cellOpenOK'));
  }

  onOpenCellKO() {
    this.$uiBus.emit('alert', this.$t('hubs.cell.cellOpenKO'));
  }

  onBack() {
    this.$router.push({name: "hubs"});
  }

  onCancel() {
    switch (this.mode) {
      case MODES.edit:
        // this will trigger reload
        this.onRouteChanged();
        this.mode = MODES.view;
    }
  }

  onSave() {
    if (this.mode === MODES.create) {
      this.$store.dispatch("hubModule/createHub", this.hub);
    }
    else if (this.mode === MODES.edit) {
      console.log("TODO");
    }
  }

  onFormValid(valid: boolean) {
    this.formValid = valid;
  }

  onFieldChange(field: string) {
    this.$store.dispatch('hubModule/clearError', field);
  }

  onCreatedOK() {
    // I already have this.hub.id as commit is synchronous
    this.$router.replace({name: 'hubDetails', params: {hubId: '' + this.hub.id}});
    this.mode = MODES.view;
    this.loading = false;
    this.onRouteChanged();
  }
}
