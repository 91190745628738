

















































































































































































































































































































import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IUser} from "@/model/model/User/IUser";
import VAutoscan from "@/components/Shipments/Widget/VAutoscan.vue";
import {IErrors} from "@/model/IErrors";
import {LoadingMixin} from '@/plugins/mixins';
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {EShipmentStatus} from "@/model/model/Shipment/EShipment";
import VueBarcode from 'vue-barcode';
import {BoPanel} from "bonomo";
import {Prop, Watch} from "vue-property-decorator";
import {Route} from "vue-router";

const shipmentModule = namespace("shipmentModule");
const authModule = namespace("authModule");

// this is a piece of sh*t as I have to import it into the class but then it will be ambiguous
enum EModes {
  INIT = 0,
  CREATE = 1,
  VIEW = 2,
  EDIT = 3,
}

@Component({
  components: {
    BoPanel,
    VAutoscan,
    VueBarcode,
  }
})
export default class ShipmentsLabelEditor extends mixins(LoadingMixin) {

  @Prop()
  protected entityId!: number;

  @shipmentModule.Getter('shipment')
  private shipment!: IShipmentDetails;
  @shipmentModule.Getter('errors')
  private errors!: IErrors;
  @shipmentModule.Action('createAlmo')
  private createAlmo!: (data: IShipmentDetails) => void;
  @shipmentModule.Action('fetchShipment')
  private fetchShipment!: (shipmentId: string) => void;
  @shipmentModule.Action('updateShipment')
  private updateShipment!: (shipment: IShipmentDetails) => void;
  @shipmentModule.Action('clearError')
  private clearError!: (ref: string) => void;

  @authModule.Getter('userData')
  private userData!: IUser;
  private EShipmentStatus: any = EShipmentStatus;
  protected EModes: any = EModes;

  protected loading: boolean = false;
  protected submitting: boolean = false;
  // now it is ambiguous
  protected mode: EModes = EModes.INIT;
  protected formIsValid: boolean = false;

  protected readonly BARCODE_FORMAT: string = 'EAN13';
  protected showAddressSecondLine: boolean = false;
  protected showScan: boolean = false;

  protected ons!: IOnOffHandlers;

  // @todo const ?
  protected emptyData: any = {
    id: null,
    bag_code: null,
    pieces: 1,
    note: '',
  }
  protected emptyLabel: any = {
    id: null,
    recipient_phone_number: '+34',
    recipient_email: null,
    secondary_code: null,
    reference: null,
    piece: null,
    recipient_first_name: null,
    recipient_last_name: null,
    recipient_address: null,
    recipient_address_2: null,
    recipient_postal_code: "080",
    recipient_city: 'Barcelona',
    recipient_province: 'Barcelona',
    weight: null,
  }
  protected data: any = {
    id: null,
    bag_code: null,
    pieces: 1,
    note: '',
  }
  protected label: any = {
    recipient_phone_number: null,
    recipient_email: null,
    secondary_code: null,
    reference: null,
    piece: null,
    recipient_first_name: null,
    recipient_last_name: null,
    recipient_address: null,
    recipient_address_2: null,
    recipient_postal_code: null,
    recipient_city: null,
    recipient_province: null,
    weight: null,
  }

  get editingDisabled(): boolean {
    return (this.mode == EModes.VIEW) || this.loading || this.submitting;
  }

  // @todo @anyone put the shipment's status in the title ("new" when creating)
  get titleHere(): string {
    // we can return a nice title with shipment ID once we implement editing
    switch (this.mode) {
      case this.EModes.CREATE:
        return '' + this.$t('shipments.labelEditor.title.createShipment');
      case this.EModes.VIEW:
        return '' + this.$t('shipments.labelEditor.title.viewShipment') + this.entityId;
      case this.EModes.EDIT:
        return '' + this.$t('shipments.labelEditor.title.editShipment') + this.entityId;
    }
    return '...';
  }
  get partnerName(): string {
    return this.userData.email;
  }
  get showBarCode(): boolean {
    return !!this.data.bag_code;
  }
  get barcodeEditable(): boolean {
    return this.mode == EModes.CREATE ||
        (this.mode == EModes.EDIT && !('' + this.shipment.bag_code).length);
  }
  get shortPostalCode(): string {
    const ret = '' + this.label.recipient_postal_code;
    return ret.substr(0,3);
  }
  get labelErrors(): any {
    if (this.errors && this.errors.labels && this.errors.labels[0]) {
      return this.errors.labels[0];
    }
    return {};
  }

  @Watch('$route')
  private onRouteChanged(newRoute: Route) {
    if (newRoute.params.entityId) {
      this.mode = EModes.VIEW;
      this.loadData();
    }
    else {
      this.mode = EModes.CREATE;
      this.clearData();
      this.isLocal();
    }
  }

  created() {
    this.ons = this.$ons({
      'backend.call.OK.shipmentLoaded': this.onShipmentLoadedOK,
      'backend.call.KO.shipmentLoaded': this.onShipmentLoadedKO,
    }, this.ons);
    this.clearData();
    // this will trigger initial load
    this.onRouteChanged(this.$route);
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  protected loadData() {
    // if (!this.$route.params.entityId) {
    if (!this.entityId) {
      return;
    }
    this.loading = true;
    // this.fetchShipment(this.$route.params.entId);
    this.fetchShipment(''+this.entityId);
  }

  protected isLocal(): boolean {
    return window.location.href.includes('local');
  }

  protected validateNotEmpty(x: any): boolean {
    return (x !== null) && (x !== undefined) && ((('' + x).trim()).length > 0);
  }

  protected onShowAutoscan() {
    if (!this.barcodeEditable) {
      return;
    }
    this.showScan = true;
  }

  protected onAutoscanInput(input: string) {
    this.data.bag_code = input;
    this.showScan = false;
  }

  protected onEdit(): void {
    if (!this.shipment.id) {
      return;
    }
    if (this.shipment.status > EShipmentStatus.STATUS_NEW) {
      return;
    }
    this.mode = EModes.EDIT;
  }

  protected onCancelEdit(): void {
    this.mode = EModes.VIEW;
    Object.assign(this.data, this.shipment);
  }

  protected onSave() {
    if (!this.formIsValid) {
      return;
    }
    // I should return here if data is not dirty but this doesn't seem to work
    // console.log('dirty? ' + _.isEqual(this.data, this.shipment) ? '=' : '!=');

    this.submitting = true;
    // data separation by copy might not be necessary but for the moment it seems a good idea.
    const data = Object.assign({}, this.data);
    data.labels = [Object.assign({}, this.label)];
    this.updateShipment(data);
  }

  protected onSaveNew() {
    if (!this.formIsValid) {
      return;
    }
    this.submitting = true;
    // data separation by copy might not be necessary but for the moment it seems a good idea.
    const data = Object.assign({}, this.data);
    data.labels = [Object.assign({}, this.label)];
    this.createAlmo(data);
  }

  private onShipmentLoadedOK(): void {
    // for now, I will just send back. Later I will show here the newly created shipment
    // Object.assign(this.data, this.shipment);
    // this.clearLoadingAndLikes();
    switch (this.mode) {
      case EModes.CREATE:
        this.$router.push({name: 'shipments'});
        break;
      case EModes.EDIT:
        this.mode = EModes.VIEW;
        // eslint-disable-next-line no-fallthrough because racecar
      case EModes.VIEW:
        Object.assign(this.data, this.shipment);
        if (this.shipment.labels && this.shipment.labels[0]) {
          Object.assign(this.label, this.shipment.labels[0]);
        }
        else {
          // this should never happen here, all almo has a label
          Object.assign(this.label, this.emptyLabel);
        }
        this.loading = false;
        this.submitting = false;
        break;
    }
    if (this.label.recipient_address_2 && (this.label.recipient_address_2.length > 0)) {
      this.showAddressSecondLine = true;
    }
    else {
      this.showAddressSecondLine = false;
    }
  }

  private onShipmentLoadedKO(): void {
    this.clearLoadingAndLikes();
  }

  private clearLoadingAndLikes(): void {
    this.loading = false;
    this.submitting = false;
  }

  private clearData(): void {
    // maybe I should clear errors here too? :D
    Object.assign(this.data, this.emptyData);
    Object.assign(this.label, this.emptyLabel);
  }

}
