

















































































































































































import Component, {mixins} from "vue-class-component";

import {namespace} from "vuex-class";
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {ICell} from "@/model/model/Hub/ICell";
import {IHub} from "@/model/model/Hub/IHub";
import {IShipmentSearchFilter} from "@/model/model/Shipment/IShipmentSearchFilter";
import HubSelector from "@/components/Hubs/Widget/HubSelector.vue";
import ShipmentStatusFilter from "@/components/Shipments/Widget/ShipmentStatusFilter.vue";
import { icons } from '@/plugins/icons.ts';
import {LoadingMixin} from "@/plugins/mixins";
import Vue from 'vue'
import {ORDER_DIRECTION} from "@/model/model/Database/DatabaseElements";

import { BoListPanel } from "bonomo";
import { ShipmentHelper } from '@/helpers/ShipmentHelper';
import { EShipmentStatus, EShipmentType } from "@/model/model/Shipment/EShipment";
import { WEB_VERSIONS } from "@/helpers/ToggleFeatureHelper";

const hubModule = namespace("hubModule");
const shipmentModule = namespace("shipmentModule");

@Component({
  components: {
    HubSelector,
    ShipmentStatusSelector: ShipmentStatusFilter,
    BoListPanel
  }
})
export default class ShipmentsIndex extends mixins(LoadingMixin) {
  @shipmentModule.Action("fetchShipments")
  private fetchShipments!: (filters: IShipmentSearchFilter) => Promise<any>
  @shipmentModule.Action("clearShipments")
  private clearShipments!: () => Promise<any>
  @shipmentModule.Action("downloadCsv")
  private downloadCsv!: () => void;

  @hubModule.Action("fetchHubs")
  private fetchHubs!: () => void;

  @shipmentModule.Getter('shipments')
  private shipments!: IShipmentDetails[];

  @shipmentModule.Getter('shipmentsFilters')
  private shipmentsFilters!: IShipmentSearchFilter;
  @hubModule.Getter('hubById')
  private hubById!: (id: number) => IHub;

  protected ons!: IOnOffHandlers;
  private icons = icons;
  private testShipments = false;
  private deletedItems = false;

  private headers: IDataTableHeader[] = [
    { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
    { text: this.$t('shipments.shipment.type.label') as string, value: "type", },
    { text: this.$t('shipments.shipment.status.label') as string, value: "status", },
    { text: this.$t('hubs.common.where') as string, value: "hub", },
    { text: this.$t('shipments.shipment.from_user.label') as string, value: "from_user_id", },
    { text: this.$t('shipments.shipment.to_user.label') as string, value: "to_user_id", },
    { text: this.$t('shipments.shipment.created_at.label') as string, value: "created_at", },
    { text: "", sortable: false, value: "controls", align: "center"},
  ];

  private filters: IShipmentSearchFilter = {
    hub_id: null,
    status: [],
    type: [],
    user_id: null,
    orderDirection: ORDER_DIRECTION.DESC,
  };

  private shipmentIsDeleted: (shipment: IShipmentDetails) => boolean = ShipmentHelper.shipmentIsDeleted;

  private get createButtonTo(): object|undefined {
    return this.$isVersion(WEB_VERSIONS.BARCELONA_PUERTO) ? undefined : { name: 'shipmentsCreateExternal' };
  }

  protected shipmentIsPublicDelivery(shipment: IShipmentDetails): boolean {
    return shipment.type == EShipmentType.PUBLIC_DELIVERY;
  }

  protected shipmentIsNewAlmo(shipment: IShipmentDetails): boolean {
    return shipment.type == EShipmentType.ALMO;
  }

  protected shipmentIsInAwaitingCollectOrBefore(shipment: IShipmentDetails): boolean {
    return shipment.status <= EShipmentStatus.STATUS_AWAITING_COLLECT;
  }

  protected shipmentIsAfterAwaitingCollect(shipment: IShipmentDetails): boolean {
    return shipment.status > EShipmentStatus.STATUS_AWAITING_COLLECT;
  }

  private get shipmentsFilterDelted(): IShipmentDetails[] {
    return ShipmentHelper.shipmentListWhitoutDeleted(this.shipments);
  }

  private get getShipments(): IShipmentDetails[] {
    return this.deletedItems ? this.shipments : this.shipmentsFilterDelted;
  }

  public created() {
    this.ons = this.$ons({
      'backend.call.OK.csvLoaded': this.onCsvOK,
      'backend.call.KO.csvLoaded': this.onCsvKO,
    });
    this.fetchHubs();
    Object.assign(this.filters, this.shipmentsFilters);
  }

  public beforeDestroy(): void {
    this.$offs(this.ons);
  }

  protected shipmentUrl(item: IShipmentDetails) {
    return item.type == EShipmentType.ALMO
      ? {name: 'shipmentsLabelEditor', params: { entityId: item.id }}
      : {name: 'shipmentDetails', params: { shipmentId: item.id }};
  }

  // todo: show loading or something similar
  private onCsvOK() {}
  private onCsvKO() {}

  private hubName(cell: ICell) {
    const hub = cell ? this.hubById(cell.hub_id) : null;
    return hub ? hub.name : '??';
  }

  private onHubSelectorChange(value: number) {
    this.filters.hub_id = value;
  }

  private onStatusChange(value: number[]) {
    this.filters.status = value;
  }

  private toggleTestShipmemts() {
    // here we need Vue.set and .delete as the values are true and undefined, latter one doesn't play nice with observers without Vue.set/delete
    this.filters.testShipments
      ? Vue.delete(this.filters, 'testShipments')
      : Vue.set(this.filters, 'testShipments', true);
  }

  private goBack(): void {
    this.$router.push({name: "home"});
  }

  private goToLabelCreator(): void {
    this.$router.push({name: "shipmentsCreateLabel"});
  }

}
