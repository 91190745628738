



























import Vue from 'vue';
import Component from "vue-class-component";

@Component({
  components: {
  }
})
export default class Courier extends Vue {

  created() {
    // just to load them early
    this.$store.dispatch("hubModule/getHubs");
  }

  private onPrepareRoute() {
    this.$router.replace({name: 'courierAddToRoute'});
  }

  private goBackToMain(): void {
    this.$router.push({name: "home"});
  }
}
