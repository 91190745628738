export enum WEB_VERSIONS {
  GIRONA = 'd-girona', // bo.w.smartpoint.city(.local) -> and any other URLs that don't fit DEFAULT
  MADRID = 'd-madrid', // bo.m.smartpoint.city(.local)
  BARCELONA_PUERTO = 'd-barcelona-port', // bo.p.smartpoint.city(.local)
  ESPLUGUES = 'd-esplugues', // bo.p.smartpoint.city(.local)
}


export const isVersion = (expectedVer: WEB_VERSIONS): boolean => {
  const hostname = window.location.hostname;

  switch (expectedVer) {
    case WEB_VERSIONS.GIRONA:
      return hostname.startsWith('bo.w') ||
        hostname.startsWith('bo.sd2') ||
        hostname.startsWith('bo.s2');
    case WEB_VERSIONS.MADRID:
      return hostname.startsWith('bo.m');
    case WEB_VERSIONS.BARCELONA_PUERTO:
      return hostname.startsWith('bo.p');
    case WEB_VERSIONS.ESPLUGUES:
      return hostname.startsWith('bo.e');
    default:
      return false;
  }
};
