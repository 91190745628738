






























































































































import Vue from "vue";

import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IHub} from "@/model/model/Hub/IHub";
import OpenCellDialog from "@/components/Hubs/Cells/OpenCellDialog.vue";
import CreateCellColumnDialog from "@/components/Hubs/CreateCellColumnDialog.vue";
import DisableCellDialog from "@/components/Hubs/Cells/DisableCellDialog.vue";
import ChangeConnectorDialog2 from "@/components/Hubs/Cells/ChangeConnectorDialog2.vue";

import {namespace} from "vuex-class";
import {ICell} from "@/model/model/Hub/ICell";

const hubModule = namespace("hubModule");


interface ICellsByConnector {
  [CONNECTOR: number]: ICell[]
}

enum CELL_SIZES {
  SMALL= 100,
  MEDIUM= 200,
  BIG= 300,
}

@Component({
  name: 'CellsInfo',
  components: {
    OpenCellDialog,
    CreateCellColumnDialog,
    DisableCellDialog,
    ChangeConnectorDialog2,
  }
})
export default class CellsInfo extends Vue {
  @Prop()
  private hub!: IHub;

  private mode: number = 0;

  private showConnectorDialog: boolean = false;
  private showOpenDialog: boolean = false;
  private showDisableDialog: boolean = false;
  private showCreateCellColumnDialog: boolean = false;
  private currentCell: ICell = {} as ICell;

  private MODES = {
    VISIBLE: 0,
    TOGGLE_ACTIVE: 1,
    CHANGE_CONNECTOR: 2,
    OPEN: 3
  }

  get cells(): ICell[] {
    return this.hub.cells;
  }

  get organizedCells(): ICellsByConnector {

    let res: ICellsByConnector  = {};

    this.cells.forEach((item) => {
      const currentFile = Math.floor(item.connector / 10);

      if (res[currentFile]) {
        res[currentFile].push(item)
      } else {
        res[currentFile] = [item];
      }
    })

    return res;
  }

  private cellClick(cell: ICell) {
    if (this.mode === this.MODES.TOGGLE_ACTIVE) {
      this.toggleCellActive(cell);
    } else if (this.mode === this.MODES.CHANGE_CONNECTOR) {
      this.changeConnectorProcess(cell);
    } else if (this.mode === this.MODES.OPEN) {
      this.openCellProcess(cell);
    } else if (cell.shipment_id) {
      this.goToShipment(cell.shipment_id);
    }
  }

  private getSizeClass(sizeId: CELL_SIZES): string {
    return sizeId === CELL_SIZES.SMALL ? 'small-size'
         : sizeId === CELL_SIZES.BIG ? 'big-size'
         : 'medium-size';
  }

  private getSizeName(sizeId: CELL_SIZES): string {
    return sizeId === CELL_SIZES.SMALL ? 'small'
         : sizeId === CELL_SIZES.BIG ? 'big'
         : 'medium';
  }

  private openCellProcess(cell: ICell) {
    this.currentCell = cell;
    this.showOpenDialog = true;
  }


  private changeConnectorProcess(cell: ICell) {
    if (cell && cell.shipment_id) {
      this.$uiBus.emit('alert', this.$t('hubs.cell.dialogChangeConnector.textExistShipment'));
      return;
    }
    this.currentCell = cell;
    this.showConnectorDialog = true;
  }

  private toggleCellActive(cell: ICell) {
    this.currentCell = cell;
    this.showDisableDialog = true;
  }


  private getStatusClass(active: number): string {
    return active === 0 ? 'disabled'
         : 'enabled';
  }

  private getModeClass(): string {
    return this.mode !== this.MODES.VISIBLE ? 'edit-mode' : ''
  }

  private goToShipment(shipmentId: number) {
    if (shipmentId) {
      this.$router.push({name: "shipmentDetails", params: { shipmentId: `${ shipmentId }` }});
    }
  }

  private openCellColumnCreation() {
    this.showCreateCellColumnDialog = true;
  }
}
