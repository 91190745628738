import {IErrorTranslator} from '@/helpers/ResponseHandler';
import VueI18n, {LocaleMessages} from 'vue-i18n';
import {IErrors} from "@/model/IErrors";
import {i18n} from '@/helpers/translations';
import LocaleMessageObject = VueI18n.LocaleMessageObject;
import { COMMUNICATION_STATUS } from '@/model/model/UserCommunications/IUserCommunications';

/**
 * @param errors
 * @param prefixes
 * @return IErrors
 *
 * eg.: errors = {
 *   name: [
 *     'required',
 *   ]
 * }
 *
 * precedence (first one which has a translation will be used):
 * - customTranslationPrefix.name.required (1)
 * - errors.mutationName.name.required (2)
 * - errors.general.name.required (3)
 * - errors.general.general.required (4)
 * - errors.general.unknown (5)
 * NOTE errors.mutationName shall come from ResponseHandler in prefixes param automatically
 *
 * example locale file:
 * const messages = {
 *   (...),
 *   errors: {this will
 *     userRegistered: {
 *       name: {
 *         // (2) will be used only for registration, if name is missing (but not for password, not for login etc)
 *         required: 'Necesitas un nombre para registrar',
 *       },
 *       _: {
 *         // will be used when register returns a 401 por algun razon as general error comes without ref from API, then mapped to '_' ref in ResponseHandler.transformErrors)
 *         401: 'No puedes registrarte'
 *       }
 *     },
 *     general: {
 *       // (3) will be used when the 'required' error happens on a field called 'name' (but not for password field, or for general)
 *       name: {
 *         required: 'Necesitamos el nombre',
 *       },
 *       general: {
 *         // (4) translation for laravel 'required' validation rule which will be used for all fields
 *         required: 'Necesitamos este campo',
 *       },
 *       // (5) used when everything else fails
 *       unknown: 'Se ha producido un error inesperado',
 *       // ResponseHandler.transformErrors will produce a {'_': ['????']} when errors cannot be transformed (should never happen indeed)
 *       '????': 'Ni podemos imaginar que paso'
 *     }
 *   }
 * }
 */
export const translateErrors: IErrorTranslator = (
  errors: IErrors,
  prefixes: string[] = []
): IErrors => {

  const translated: IErrors = {};

  // we prepare some slices of the locale file called 'context' and later we only look up in these slices. Faster than
  //  trying to do up to 4 full translation lookups.
  // using "as LocaleMessages" and @ts-ignores, anything nicer welcome (unless needs ramda or changes behavior of code)
  const contexts: LocaleMessageObject|LocaleMessages[] = prefixes.map(
    eachPrefix => i18n.t(eachPrefix) as LocaleMessages
  );
  contexts.push(i18n.t('errors.general') as LocaleMessages);
  const generalContext: LocaleMessageObject = i18n.t('errors.general') as LocaleMessageObject;

  const translateOneError = (ref: string, error: string): string => {

    // look for:
    // - customTranslationPrefix.name.min
    // - errors.mutationName.name.min
    // - errors.general.name.min
    for (const i in contexts) {
      const context = contexts[i];
      if (context[ref] && context[ref][error]) {
        return '' + context[ref][error];
      }
    }

    // look for:
    // - errors.general.general.min
    if (generalContext[error]) {
      return generalContext[error] as string;
    }

    // return i18n.t('errors.general.unknown') as string;
    return error + ' (???)';

  };

  Object.keys(errors).forEach(eachRef => {
    const eachErrors: string[] = errors[eachRef];
    translated[eachRef] = [];
    eachErrors.forEach(eachError => {
      translated[eachRef].push(translateOneError(eachRef, eachError));
    });
  });

  return translated;
};

export const flattenErrors = (errors: IErrors): string[] => {
  let ret: string[] = [];
  Object.keys(errors).forEach(eachKey => {
    ret = ret.concat(errors[eachKey]);
  });
  return ret;
};

export const messageStatusIsError = (status: COMMUNICATION_STATUS): boolean => status !== COMMUNICATION_STATUS.MESSAGE_STATUS_SENT;
