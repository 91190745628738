

































import Vue from 'vue';
import Component from "vue-class-component";
import {namespace} from "vuex-class";
import {IHub} from "@/model/model/Hub/IHub";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {BoListPanel} from "bonomo";
import {IUserSearchFilter} from "@/model/model/User/IUserSearchFilter";

const hubModule = namespace("hubModule");

@Component({
  components: {
    BoListPanel,
  }
})
export default class HubsIndex extends Vue {

  @hubModule.Getter('hubs')
  private hubs!: IHub[];
  @hubModule.Getter('hub')
  private hub!: IHub;

  @hubModule.Action('fetchHubs')
  private fetchHubs!: (filters: IUserSearchFilter) => Promise<any>

  @hubModule.Action('clearHubs')
  private clearHubs!: (filters: IUserSearchFilter) => Promise<any>

  private headers: IDataTableHeader[] = [
    { text: this.$t('hubs.hub.id.label') as string, sortable: true, value: "id", },
    { text: this.$t('hubs.hub.name.label') as string, sortable: true, value: "name", },
    { text: "", sortable: true, value: "status", align: "end"},
    { text: "", sortable: false, value: "controls", align: "center"},
  ];

  private testHubs: boolean = false;

  private get hubList() {
    const showTestHubs = this.testHubs;
    return this.hubs.filter((hub: IHub) => showTestHubs || !this.isTestHub(hub));
  }

  private isTestHub(hub: IHub) {
    return hub.status != 20;
  }

  private goBack(): void {
    this.$router.push({name: "home"});
  }
}
