import {IHub} from "@/model/model/Hub/IHub";
import {i18n} from "@/helpers/translations";


export class HubHelper {
  static getUserDefaultHubInfo (hub: IHub|null): string {
    if (hub) {
      return `${ hub.name }, ${ hub.address }`
    }

    return i18n.t("users.user.no_default_hub") as string;
  }
}
