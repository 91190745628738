










import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import {namespace} from "vuex-class";

import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";
import {IHub} from "@/model/model/Hub/IHub";

import DialogVerifyOpen from "@/views/Courier/DialogVerifyOpen.vue";

const hubModule = namespace("hubModule");
const courierModule = namespace("courierModule");

@Component({
  name: 'DeliverLogic',
  components: {
    DialogVerifyOpen,
  }
})
export default class DeliverLogic extends Vue {
  @courierModule.Getter('shipmentsInRoute')
  private shipments!: IShipmentDetails[];

  @hubModule.Getter('hubs')
  private hubsPath!: IHub[]; // show the path of hubs the courier has to go

  protected ons!: IOnOffHandlers;

  private showVerifyDialog = false;
  private shipmentId: string = '';

  created() {
    this.ons = this.$ons({
      'atHub.courierLogic.deliver': this.start,
      'backend.call.begin.deliverShipment': this.onApiBegin,
      'backend.call.begin.verifyDeliverShipment': this.onApiBegin,
      'backend.call.OK.deliverShipment': this.onDeliverOK,
      'backend.call.OK.verifyDeliverShipment': this.onVerifyDeliverOK,
      'backend.call.KO.deliverShipment': this.onApiKO,
      'backend.call.KO.verifyDeliverShipment': this.onApiKO,
    }, this.ons);
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  private start(shipmentId: string) {
    this.shipmentId = shipmentId;
    this.dispatchDeliverShipment()
  }

  /**
   * after we dispatch the deliver, we need to validate the proper cell has been opened
   */
  private onDeliverOK() {
    this.openVerifyDialog();
  }

  private onVerifyDeliverOK() {
    this.$store.dispatch('courierModule/removeShipmentInRoute', this.shipmentId);
    this.resetLogicAndClosePopUps();
    this.onOK();
  }

  private resetLogicAndClosePopUps() {
    this.closeVerifyDialog();
    this.shipmentId = '';
  }

  private openVerifyDialog() {
    this.showVerifyDialog = true;
  }

  private closeVerifyDialog() {
    this.showVerifyDialog = false;
  }

  private dispatchDeliverShipment() {
    this.$store.dispatch('courierModule/deliverShipment', this.shipmentId);
  }

  private verifyDeliverShipment() {
    this.$store.dispatch('courierModule/verifyDeliverShipment', this.shipmentId);
  }

  private onOK() {
    this.$emit('api:OK');
  }

  private onApiKO() {
    this.$emit('api:KO');
  }

  private onApiBegin() {
    this.$emit('api:begin');
  }
}


