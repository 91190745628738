






























































import Component, {mixins} from "vue-class-component";

import {namespace} from "vuex-class";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {LoadingMixin} from "@/plugins/mixins";
import { icons } from '@/plugins/icons.ts';
import { BoListPanel } from "bonomo";
import {IManifestDetails} from "@/model/model/Shipment/IManifestDetails";

const manifestModule = namespace("manifestModule");

@Component({
  components: {
    BoListPanel
  }
})
export default class AlmoManifests extends mixins(LoadingMixin) {
  @manifestModule.Action("fetchManifests")
  private fetchManifests!: () => Promise<any>
  @manifestModule.Getter('manifests')
  private manifests!: IManifestDetails[];
  private icons = icons;

  private headers: IDataTableHeader[] = [
    { text: this.$t('shipments.manifest.fields.created_at.label') as string, value: "created_at", },
    { text: this.$t('shipments.manifest.fields.total_shipments.label') as string, value: "total_shipments", },
    { text: this.$t('shipments.manifest.fields.total_in_deliver.label') as string, value: "total_in_deliver", },
    { text: this.$t('shipments.manifest.fields.total_completed.label') as string, value: "total_completed", },
    { text: "", sortable: false, value: "controls", align: "center"},
    { text: "", sortable: false, value: "print", align: "center"},
  ];

  private goToCurrentManifest(): void {
    this.$router.push({ name: 'almoManifestShipments' });
  }

  private manifestUrl(item: IManifestDetails) {
    return {name: 'almoPastManifestShipments', params: { entityId: item.id, paged: true }};
  }
  private printUrl(item: IManifestDetails) {
    return {name: 'almoPastManifestShipments', params: { entityId: item.id, paged: false }};
  }
  private goBack(): void {
    this.$router.push({name: "home"});
  }
}
