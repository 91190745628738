import Vue from 'vue';
import {IOnOffHandlers} from "@/model/IOnOffHandlers";
import Component from "vue-class-component";

// @todo would be nicer in its own file somewhere in components?
@Component
export class LoadingMixin extends Vue {

  protected loading: boolean = false;

  protected ons: IOnOffHandlers = {};

  created() {
    this.ons = this.$ons({
      'backend.call.begin': this.onLoadBegin,
      'backend.call.OK': this.onLoadOK,
      'backend.call.KO': this.onLoadKO,
    }, this.ons);
  }

  beforeDestroy(): void {
    this.$offs(this.ons);
  }

  onLoadBegin() {
    this.loading = true;
  }

  onLoadOK() {
    this.loading = false;
  }

  onLoadKO() {
    this.loading = false;
  }

}
