





















import Vue from 'vue';
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IErrors} from "@/model/IErrors";
import {EShipmentType} from "@/model/model/Shipment/EShipment";

@Component({})
export default class StatusSlider extends Vue {

  @Prop()
  status!: number;
  @Prop()
  type!: number;
  @Prop()
  errors!: IErrors;

  get statusLabels() {
    const status = this.statusX;
    const labels = Object.values(this.$t('shipments.shipment.status.values'));
    for (let i in labels) {
      if (parseInt(i) !== status) {
        labels[i] = '';
      }
    }
    return labels;
  }
  get statusCnt() {
    return Object.keys(this.$t('shipments.shipment.status.values')).length;
  }
  get statusMin() {
    return this.type == EShipmentType.EXTERNAL ? 4 : 0;
  }
  get statusX() {
    const status = this.status;
    const keys = Object.keys(this.$t('shipments.shipment.status.values'));
    return keys.indexOf(''+status);
  }
  get statusY() {
    const status = this.status;
    const keys = Object.keys(this.$t('shipments.shipment.status.values'));
    const max = keys.indexOf(''+status);
    return [this.statusMin, max];
  }
  set statusX(value) {}
  set statusY(value) {}

}
