














































import Component, {mixins} from "vue-class-component";
import {namespace} from "vuex-class";
import {LoadingMixin} from "@/plugins/mixins";
import {IUserCommunication} from "@/model/model/UserCommunications/IUserCommunications";
import {COMMUNICATION_TYPES} from '@/model/model/UserCommunications/IUserCommunications';
import { COMMUNICATION_STATUS } from '@/model/model/UserCommunications/IUserCommunications';
import UserForm from "@/components/User/UserForm.vue";
import {DateHelper} from "@/helpers/DateHelper";
import CommunicationsDialog from "@/components/Communications/Dialog/CommunicationsDialog.vue";
import {IDataTableHeader} from "@/model/vuetify/IDataTableHeader";
import {BoListPanel} from "bonomo";
import {Prop} from "vue-property-decorator";
import {messageStatusIsError} from "@/helpers/errorsHelper";

const usersModule = namespace("usersModule");

@Component({
  name: 'UserCommunications',
  components: {
    UserForm,
    CommunicationsDialog,
    BoListPanel,
  }
})
export default class UserCommunications extends mixins(LoadingMixin) {

  @Prop()
  private userId!: number;

  @usersModule.Getter('communications')
  private communications!: IUserCommunication[];

  @usersModule.Getter('canLoadComNextPage')
  private canLoadComNextPage!: boolean;

  @usersModule.Action('fetchUserCommunications')
  private fetchUserCommunications!: (params: any) => void

  @usersModule.Action('clearUserCommunications')
  private clearUserCommunications!: () => void

  private formatDate: (date: string) => string = DateHelper.formatDate;

  private isError: ((x: COMMUNICATION_STATUS) => boolean ) = messageStatusIsError;

  get headers(): IDataTableHeader[] {
    return [
      { text: this.$t('shipments.shipment.id.label') as string, value: "id", },
    ];
  }

  private showDialogWithCommunication(dialogCommunication: IUserCommunication) {
    this.$uiBus.emit('communicationsDialog:show', dialogCommunication);
  }

  private fetchMoreCommunications(filters: any) {
    this.fetchUserCommunications({userId: this.userId, ...filters})
  }

  private isEmail(message: IUserCommunication) {
    return message.type === COMMUNICATION_TYPES.EMAIL;
  }

}
