<template>
  <notifications
      position="bottom center"
  >
    <template slot="body"
              slot-scope="{ item, close }">

      <div :class="[
                'custom-notification',
                item.type
            ]">

        <div class="title">
          <div class="text">{{ item.title }}</div>
          <button class="close" @click="close">
            <v-icon color="white">mdi-close-thick</v-icon>
          </button>
        </div>

        <div class="content">
          <div class="text">{{ item.text }}</div>
          <router-link
              v-if="item.data.to"
              :to="item.data.to">
            <v-icon color="white">mdi-arrow-right</v-icon>
          </router-link>

        </div>

      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: "NotificationTemplate"
}
</script>

<style lang="scss">

.custom-notification{
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
  background-color: green;
  color: white;

  .content, .title{
    display: flex;
    align-items: flex-start;

    .text {
      flex: 1;
    }

    a {
      text-decoration: none !important;
    }
  }

  .title {
    .text {
      font-size: 18px;
    }
  }

  &.error {
    background-color: red;
    color: white;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 7px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

  }

}
</style>
