

















import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import BoDialog from "@/components/Bo/BoDialog.vue";
import {ICell} from "@/model/model/Hub/ICell";
import Vue from "vue";

const hubModule = namespace("hubModule");

@Component({
  name: 'DisableCellDialog',
  components: {
    BoDialog,
  }
})
export default class DisableCellDialog extends Vue {

  @hubModule.Action('toggleDisableCell')
  private toggleDisableCell!: (cellId: number) => void;

  @Prop()
  private cell!: ICell;

  @Prop()
  private value!: boolean;

  private get title() {
    return this.cell.active
        ? 'hubs.cell.dialogDisableCell.title'
        : 'hubs.cell.dialogEnableCell.title';
  }

  private get submitLabel() {
    return this.cell.active
        ? 'hubs.cell.disable'
        : 'hubs.cell.enable';
  }

  private onSubmit() {
    this.toggleDisableCell(this.cell.id);
  }

}

