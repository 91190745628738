var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bo-list-panel',{attrs:{"title":"users.common.shipments","fetch-mutation-name":"usersShipmentsLoaded","fetch-store-action":_vm.fetchShipments,"clear-store-action":_vm.clearUserShipments,"data-table-headers":_vm.headers,"data-table-items":_vm.shipments,"item-count":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.type.values.' + item.type)}},[_vm._v(" "+_vm._s(_vm.icons['shipmentType' + item.type])+" ")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.type.values.' + item.type))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status <= 30)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.20'),"color":"orange"}},[_vm._v(_vm._s(_vm.icons.awaitingDropoff))]):(item.status <= 40)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.30'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.collect))]):(item.status < 70)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.50'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.processing))]):(item.status < 80)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.70'),"color":"blue"}},[_vm._v(_vm._s(_vm.icons.deliver))]):(item.status < 90)?_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.80'),"color":"green"}},[_vm._v(_vm._s(_vm.icons.awaitingPickup))]):_c('v-icon',{attrs:{"title":_vm.$t('shipments.shipment.status.values.90'),"color":"green"}},[_vm._v("mdi-check-circle")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(" "+_vm._s(_vm.$t('shipments.shipment.status.values.' + item.status))+" ")])]}},{key:"item.hub",fn:function(ref){
var item = ref.item;
return [(_vm.shipmentIsPublicDelivery(item))?_c('span',[_vm._v(" "+_vm._s(item.to_cell ? _vm.hubName(item.to_cell) : '-')+" ")]):(item.from_cell && (item.status<=30))?_c('span',{attrs:{"title":'From: ' + _vm.hubName(item.from_cell) + '\nTo: ' + (item.to_cell?_vm.hubName(item.to_cell):'-')}},[_vm._v(" "+_vm._s(_vm.hubName(item.from_cell))+" ")]):(item.status<=30)?_c('span',[_c('strong',{staticStyle:{"color":"white","background-color":"red","padding":"0 10px"}},[_vm._v("!!!ERR!!!")])]):(item.to_cell)?_c('span',{attrs:{"title":'From: ' + _vm.hubName(item.to_cell) + '\nTo: ' + (item.to_cell?_vm.hubName(item.to_cell):'-')}},[_vm._v(" "+_vm._s(_vm.hubName(item.to_cell))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.from_user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("userNameOrPhone")(item.from_user))+" ")]}},{key:"item.to_user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("userNameOrPhone")(item.to_user))+" ")]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{name: 'shipmentDetails', params: { shipmentId: item.id }},"x-small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }