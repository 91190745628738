
































































































































































































































import Component, {mixins} from "vue-class-component";
import {LoadingMixin} from "@/plugins/mixins";
import VueBarcode from 'vue-barcode';
import {namespace} from "vuex-class";
import {IShipmentDetails} from "@/model/model/Shipment/IShipmentDetails";

const manifestModule = namespace("manifestModule");

@Component({
  components: {VueBarcode}
})
export default class AlmoManifestShipmentsPrinter extends mixins(LoadingMixin) {

  @manifestModule.Action('fetchPastManifestShipments')
  private fetchPastManifestShipments!: ({manifestId}:any) => Promise<any>

  @manifestModule.Getter('pastManifestShipments')
  private pastManifestShipments!: IShipmentDetails[];

  private manifestId = '';

  public created() {
    this.onRouteChanged();
  }

  onBack() {
    this.$router.push({name: 'almoPastManifestShipments', params: { entityId: this.manifestId, paged: 'true' }});
  }

  onRouteChanged() {
    console.log(this.$route);
    this.manifestId = this.$route.params.entityId;
    this.fetchPastManifestShipments({manifestId: this.manifestId});
  }

}
